import React from 'react'
import { ripples } from 'ldrs'
import { lineSpinner } from 'ldrs'

lineSpinner.register()

ripples.register()

export default function Loader() {
    return (
        <div className="loader">
            <l-line-spinner
                size="70"
                speed="2"
                stroke={5}
                color="#FFA200"
            ></l-line-spinner>
        </div>
    )
}
