const EnglishWords = {
    profitPerHour: "Profit per hour",
    dailyReward: "Daily reward",
    dailyCipher: "Daily Cipher",
    dailyCombo: "Daily Combo",
    Boost: "Boost",
    boostYourProfit: "Boost your profit",
    tapTheMiningMenu: "Tap the Mining menu to buy upgrades for your exchange",
    EarnEvenWhenOffline: "Earn even when offline up to 3 hours",
    startMining: "Start mining",
    theExchangeHasStarted: "The exchange has started working for you",
    Settings: "Settings",
    chooseExchange: "Choose Exchange",
    chooseLanguage: "Choose Language",
    deleteAcc: "Delete Account",
    HapticFeedback: "Haptic Feedback",
    coinsAnimation: "Coins animation",

    areYouSureDelete: "Are you sure you want to delete your account?",
    allYourDataDelete: "All your data, including game progress, achivement, and purchases, will be permanantly deleted. This action cannot be undone.",
    cancel: "Cancel",
    yourBalance: "Your balance",
    freeDailyBooster: "Free daily boosters",
    fullEnergy: "Full energy",
    available: "available",
    Turbo: "Turbo",
    commingSoon: "Comming Soon",
    Boosters: "Boosters",
    multiTap: "Multitap",
    energyLimit: "Energy limit",
    increaseTheAmountOfCoins: "Increase the amount of coins you can earn per tap",
    plusOneCoinFor: "+1 coin for tap for level",
    increaseAmountOfEnergy: "Increase the amount of energy",
    plus500energy: "+500 energy points for level",
    rechargeYourEnergy: "Recharge your energy to the maximum and do another round of mining",
    free: "Free",
    goAhead: "Go Ahead",
    theCodeIsCrocked: "The code is crocked You are a real detective!",
    home1: "Earn points every hour by increasing your profit per hour. Unlock and upgrade mines to maximize your gains!",
    homet1: "Hamster Kombat: Profit Per Hour System",
    home2: "Log in daily to claim exciting rewards in Hamster Kombat! Build a routine to earn rewards and progress faster!",
    homet2: "Daily Rewards",
    home3: "Create Morse code by clicking available words to earn rewards in Hamster Kombat.",
    homet3: "Daily Cipher",
    home4: "Unlock three random mines for the Daily Combo and earn rewards in Hamster Kombat! Enjoy exciting bonuses to enhance your gameplay!",
    homet4: "Daily Combo",
    home5: "Click the circle to boost your points in Hamster Kombat! Each click raises your points.",
    homet5: "Clicking Portion",
    home6: "Use the Boost Option to upgrade your energy limit and increase points per click in Hamster Kombat.",
    homet6: "Boost Option",
    minet1: "Combo Mines",
    mine1: "Unlock or upgrade 3 combo mines to add them to your combo section, and archive all for 5,000,000 points and 0.33% of your active package.",
    mine2: "Purchase to unlock or upgrade your mine to increase your points profit per hour and maximize your rewards.",
    minet2: "Unlock and Upgrade Mines for More Profit",
    mine3: "Unlock special mines by fulfilling specific conditions, like reaching level 3 on another mine to unlock new opportunities for profit.",
    minet3: "Unlock Mines by Meeting Conditions",
    prNTeam: "PR&Team",
    markets: "Markets",
    legal: "Legal",
    web3: "Web3",
    yayCombo: "Yay, the combo is complete. You're doing great!",
    takeThePrize: "Take the prize",
    Exchange: "Exchange",
    mine: "Mine",
    friends: "Friends",
    earn: "Earn",
    airdrop: "Wallet",
    friendt1: "Invite Friends and Earn More",
    friend1: "Click the invite button to share your referral link with friends and expand your rewards.",
    friendt2: "Copy and Share Referral Link",
    friend2: "Use the copy button to instantly copy your referral link and share it with friends for rewards.",
    inviteFriends: "Invite Friends!",
    youAndYourFriend: "You and Your friend will receive bonuses.",
    PlusAsSpecialBonus: "Plus, as a special bonus, every new referred user will receive a $10 package for free! Don't miss out on these exciting rewards!",
    specialBonusAlert: "Special Bonus Alert!",
    specialBonusDescription: "<strong>Special Bonus Alert! Every new user you refer will receive a free $10 package in Hamster Kombat! This is a fantastic opportunity to enhance your gameplay and enjoy exciting rewards. Don’t miss out start referring friends today to unlock even more benefits and boost your gaming experience!</strong>",
    inviteAFriend: "Invite a friend",
    forYouAndYour: "for you and your friend",
    inviteWithPremium: "Invite a friend with Telegram Premium",
    moreBonuses: "More bonuses",
    bonusForLevelingUP: "Bonus for leveling up",
    level: "level",
    forFriend: "For friend",
    premium: "Premium",
    listOfFriends: "List of your friends",
    earnt1: "Watch Videos and Earn Rewards",
    earn1: "Complete YouTube tasks by watching videos and receive rewards for your participation.",
    earnt2: "Daily Rewards",
    earn2: "Log in daily to claim exciting rewards in Hamster Kombat! Build a routine to earn rewards and progress faster!",
    earnt3: "Complete Simple Tasks for Rewards",
    earn3: "Earn rewards by completing tasks like joining Telegram, following on X, selecting an exchange, and inviting 3 friends.",
    earnMoreCoin: "Earn more coin",
    hamsterYoutube: "Hamster Youtube",
    dailyTasks: "Daily tasks",
    tasksList: "Tasks list",
    accureCoinsForLogin: "Accure coins for login into the game daily without skipping",
    day: "Day",
    check: "Check",
    comeBackTomorrow: "Come back tomorrow",
    wait1hour: "wait 1 hour for moderation check to claim the prize",
    airt1: "Copy USDT Contract with One Click",
    air1: "Click to copy the contract for USDT and easily share it with others.",
    airt2: "USDT price",
    air2: " Stay updated with the current price of USDT in this section for informed trading decisions.",
    airt3: "Connect Your Wallet",
    air3: "Use this button to easily connect your wallet for seamless transactions and interactions.",
    airt4: "Deposit Amount",
    air4: "Check the current amount of your deposit in USDT for effective management of your funds.",
    airt5: "Withdrawable Amount",
    air5: "View your withdrawable amount, with a minimum withdrawal limit of 10 USDT.",
    airt6: "Current Packages Overview",
    air6: "Explore the table displaying all current packages available for users.",
    airt7: "Completed Packages Overview",
    air7: "View the table showcasing all completed packages to track your achievements.",
    connectYourTonWallet: "Connect your TON wallet",
    currentPackage: "Current package",
    date: "Date",
    investedAmount: "Invested Amount",
    totalReward: "Total Reward",
    todayEarning: "Today Earning",
    totalRecived: "Total Received",
    completedPackage: "Completed package",
    connectYourCryptoWallet: "Connect your crypto wallet. if you don't have one, create one in your Telegram account",
    dipt1: "USDT Deposit",
    dip1: "Enter the amount of USDT for deposit, ensuring the value is equal to or greater than 10 USDT.",
    dipt2: "USDT Deposit Button",
    dip2: "Click this button to add a package by sending a transaction, ensuring you have at least 0.50 TON in your wallet for an active package.",
    depositWith: "Deposit with",
    youCanDeposit: "You can deposit through Telegram Bots. Choose one of the bots shown below and deposit without delay.",
    deposit: "Deposit",
    depositHistory: "Deposit History",
    witt1: "USDT Withdraw Amount",
    wit1: "Enter the amount to withdraw, ensuring it meets the minimum requirement of 10 USDT.",
    witt2: "USDT Withdraw",
    wit2: "Click the withdraw button to receive USDT.",
    withdrawWith: "Withdraw with",
    directWithdraw: "Direct withdrawal through blockchain can take up to 10 minutes.",
    pleaseBePatient: "Please be patient!",
    withdrawAmount: "Withdraw amount",
    minimumWithdraw: "Minimum withdrawal",
    withdrawHistory: "Withdraw History",
    level2Friends: "Level 2 Friends",
    level3Friends: "Level 3 Friends",
    level4Friends: "Level 4 Friends",
    level5Friends: "Level 5 Friends",
    level6Friends: "Level 6 Friends",
    level7Friends: "Level 7 Friends",
    level8Friends: "Level 8 Friends",
    level9Friends: "Level 9 Friends",
    level10Friends: "Level 10 Friends",
    level11Friends: "Level 11 Friends",
    level12Friends: "Level 12 Friends",
    level13Friends: "Level 13 Friends",
    level14Friends: "Level 14 Friends",
    level15Friends: "Level 15 Friends",
}

const HindiWords = {
    profitPerHour: "प्रति घंटे लाभ",
    dailyReward: "दैनिक पुरस्कार",
    dailyCipher: "दैनिक सिफर",
    dailyCombo: "दैनिक कॉम्बो",
    Boost: "बूस्ट",
    boostYourProfit: "अपने लाभ को बढ़ाएं",
    tapTheMiningMenu: "अपग्रेड खरीदने के लिए माइनिंग मेनू पर टैप करें",
    EarnEvenWhenOffline: "ऑफ़लाइन रहते हुए भी 3 घंटे तक कमाई करें",
    startMining: "खनन शुरू करें",
    theExchangeHasStarted: "एक्सचेंज आपके लिए काम करना शुरू कर चुका है",
    Settings: "सेटिंग्स",
    chooseExchange: "एक्सचेंज चुनें",
    chooseLanguage: "भाषा चुनें",
    deleteAcc: "खाता हटाएं",
    HapticFeedback: "हैप्टिक फीडबैक",
    coinsAnimation: "कॉइन्स एनिमेशन",

    areYouSureDelete: "क्या आप वाकई अपना खाता हटाना चाहते हैं?",
    allYourDataDelete: "आपका सारा डेटा, खेल प्रगति, उपलब्धियां और ख़रीदारी स्थायी रूप से हटा दी जाएगी। यह क्रिया वापस नहीं की जा सकती।",
    cancel: "रद्द करें",
    yourBalance: "आपका शेष",
    freeDailyBooster: "मुफ्त दैनिक बूस्टर",
    fullEnergy: "पूर्ण ऊर्जा",
    available: "उपलब्ध",
    Turbo: "टर्बो",
    commingSoon: "जल्द आ रहा है",
    Boosters: "बूस्टर्स",
    multiTap: "मल्टीटैप",
    energyLimit: "ऊर्जा सीमा",
    increaseTheAmountOfCoins: "प्रति टैप आप जितने सिक्के कमा सकते हैं उसे बढ़ाएं",
    plusOneCoinFor: "+1 सिक्का प्रति टैप प्रति स्तर",
    increaseAmountOfEnergy: "ऊर्जा की मात्रा बढ़ाएं",
    plus500energy: "+500 ऊर्जा अंक प्रति स्तर",
    rechargeYourEnergy: "अपनी ऊर्जा को अधिकतम करें और फिर से खनन करें",
    free: "मुफ़्त",
    goAhead: "आगे बढ़ें",
    theCodeIsCrocked: "कोड सही है, आप एक असली जासूस हैं!",
    home1: "प्रति घंटे लाभ बढ़ाने के लिए प्रत्येक घंटे अंक कमाएं। अपने लाभ को अधिकतम करने के लिए खदानों को अनलॉक और अपग्रेड करें!",
    homet1: "हैम्स्टर कोम्बैट: प्रति घंटे लाभ प्रणाली",
    home2: "हैम्स्टर कोम्बैट में रोमांचक दैनिक पुरस्कार का दावा करने के लिए रोज़ाना लॉग इन करें! तेजी से प्रगति करने के लिए एक दिनचर्या बनाएं!",
    homet2: "दैनिक पुरस्कार",
    home3: "हैम्स्टर कोम्बैट में पुरस्कार कमाने के लिए उपलब्ध शब्दों को क्लिक करके मोर्स कोड बनाएं।",
    homet3: "दैनिक सिफर",
    home4: "हैम्स्टर कोम्बैट में दैनिक कॉम्बो के लिए 3 रैंडम खदानें अनलॉक करें और पुरस्कार प्राप्त करें! अपने खेल को बढ़ाने के लिए रोमांचक बोनस का आनंद लें!",
    homet4: "दैनिक कॉम्बो",
    home5: "हैम्स्टर कोम्बैट में अपने अंक बढ़ाने के लिए सर्कल पर क्लिक करें! प्रत्येक क्लिक से आपके अंक बढ़ते हैं।",
    homet5: "क्लिकिंग पोर्शन",
    home6: "हैम्स्टर कोम्बैट में अपनी ऊर्जा सीमा बढ़ाने और प्रति क्लिक अंकों को बढ़ाने के लिए बूस्ट विकल्प का उपयोग करें।",
    homet6: "बूस्ट विकल्प",
    minet1: "कॉम्बो खदानें",
    mine1: "3 कॉम्बो खदानें अनलॉक या अपग्रेड करें और उन्हें अपने कॉम्बो अनुभाग में जोड़ें, और सभी को आर्काइव करने पर 50,00,000 अंक और आपकी सक्रिय पैकेज का 0.33% प्राप्त करें।",
    mine2: "अपनी प्रति घंटे लाभ बढ़ाने और अधिकतम पुरस्कार के लिए खदान को अनलॉक या अपग्रेड करें।",
    minet2: "अधिक लाभ के लिए खदानों को अनलॉक और अपग्रेड करें",
    mine3: "विशिष्ट शर्तों को पूरा करके विशेष खदानें अनलॉक करें, जैसे कि किसी अन्य खदान को स्तर 3 तक पहुंचाने पर नई लाभ के अवसर प्राप्त करें।",
    minet3: "शर्तें पूरी करके खदानें अनलॉक करें",
    prNTeam: "पीआर और टीम",
    markets: "बाज़ार",
    legal: "कानूनी",
    web3: "वेब 3",
    yayCombo: "याय, कॉम्बो पूरा हो गया। आप बहुत अच्छा कर रहे हैं!",
    takeThePrize: "पुरस्कार लें",
    Exchange: "एक्सचेंज",
    mine: "खदान",
    friends: "दोस्त",
    earn: "कमाएं",
    airdrop: "वॉलेट",
    friendt1: "दोस्तों को आमंत्रित करें और अधिक कमाएं",
    friend1: "अपने दोस्तों के साथ अपने रेफरल लिंक को साझा करने के लिए आमंत्रण बटन पर क्लिक करें और अपने पुरस्कारों का विस्तार करें।",
    friendt2: "रेफरल लिंक को कॉपी करें और साझा करें",
    friend2: "कॉपी बटन का उपयोग करके अपने रेफरल लिंक को तुरंत कॉपी करें और दोस्तों के साथ साझा करें और पुरस्कार प्राप्त करें।",
    inviteFriends: "दोस्तों को आमंत्रित करें!",
    youAndYourFriend: "आप और आपका दोस्त बोनस प्राप्त करेंगे।",
    PlusAsSpecialBonus: "एक विशेष बोनस के रूप में, प्रत्येक नया रेफर किए गए उपयोगकर्ता को एक मुफ़्त $10 पैकेज प्राप्त होगा! इन रोमांचक पुरस्कारों को मत छोड़ें!",
    specialBonusAlert: "विशेष बोनस चेतावनी!",
    specialBonusDescription: "<strong>विशेष बोनस चेतावनी! आपके द्वारा रेफर किए गए प्रत्येक नए उपयोगकर्ता को हैम्स्टर कोम्बैट में एक मुफ़्त $10 पैकेज प्राप्त होगा! यह आपके खेल को बढ़ाने और रोमांचक पुरस्कार प्राप्त करने का एक शानदार अवसर है। आज ही दोस्तों को रेफर करना शुरू करें और अधिक लाभ प्राप्त करें!</strong>",
    inviteAFriend: "दोस्त को आमंत्रित करें",
    forYouAndYour: "आप और आपके दोस्त के लिए",
    inviteWithPremium: "टेलीग्राम प्रीमियम के साथ एक दोस्त को आमंत्रित करें",
    moreBonuses: "अधिक बोनस",
    bonusForLevelingUP: "लेवलिंग अप का बोनस",
    level: "स्तर",
    forFriend: "दोस्त के लिए",
    premium: "प्रीमियम",
    listOfFriends: "आपके दोस्तों की सूची",
    earnt1: "वीडियो देखें और पुरस्कार प्राप्त करें",
    earn1: "वीडियो देखने के लिए यूट्यूब कार्यों को पूरा करें और भागीदारी के लिए पुरस्कार प्राप्त करें।",
    earnt2: "दैनिक पुरस्कार",
    earn2: "हैम्स्टर कोम्बैट में रोमांचक दैनिक पुरस्कार का दावा करने के लिए रोज़ाना लॉग इन करें! तेजी से प्रगति करने के लिए एक दिनचर्या बनाएं!",
    earnt3: "सरल कार्यों को पूरा करके पुरस्कार प्राप्त करें",
    earn3: "टेलीग्राम में शामिल होने, एक्स पर अनुसरण करने, एक एक्सचेंज चुनने और 3 दोस्तों को आमंत्रित करने जैसे कार्यों को पूरा करके पुरस्कार प्राप्त करें।",
    earnMoreCoin: "अधिक सिक्के कमाएं",
    hamsterYoutube: "हैम्स्टर यूट्यूब",
    dailyTasks: "दैनिक कार्य",
    tasksList: "कार्य सूची",
    accureCoinsForLogin: "खेल में दैनिक लॉगिन करके सिक्के प्राप्त करें",
    day: "दिन",
    check: "जाँच करें",
    comeBackTomorrow: "कल फिर आएं",
    wait1hour: "पुरस्कार का दावा करने के लिए 1 घंटे की जांच के लिए प्रतीक्षा करें",
    airt1: "एक क्लिक में USDT कॉन्ट्रैक्ट कॉपी करें",
    air1: "USDT कॉन्ट्रैक्ट कॉपी करने के लिए क्लिक करें और इसे दूसरों के साथ आसानी से साझा करें।",
    airt2: "USDT की कीमत",
    air2: "जानकारीपूर्ण व्यापार निर्णयों के लिए इस सेक्शन में USDT की मौजूदा कीमत देखें।",
    airt3: "अपना वॉलेट कनेक्ट करें",
    air3: "लेनदेन और इंटरैक्शन को आसान बनाने के लिए इस बटन का उपयोग करके अपना वॉलेट कनेक्ट करें।",
    airt4: "जमा राशि",
    air4: "अपनी जमा राशि की मौजूदा स्थिति USDT में देखें।",
    airt5: "निकासी राशि",
    air5: "अपनी निकासी राशि देखें, जिसकी न्यूनतम सीमा 10 USDT है।",
    airt6: "वर्तमान पैकेजों की समीक्षा",
    air6: "इस तालिका में मौजूदा पैकेज देखें और उपलब्धियों को ट्रैक करें।",
    airt7: "पैकेजों को पूरा किया",
    air7: "पूर्ण किए गए पैकेजों की सूची को यहां देखें।",
    connectYourTonWallet: "अपने TON वॉलेट को कनेक्ट करें",
    currentPackage: "वर्तमान पैकेज",
    date: "तारीख",
    investedAmount: "निवेशित राशि",
    totalReward: "कुल इनाम",
    todayEarning: "आज की कमाई",
    totalRecived: "कुल प्राप्त",
    completedPackage: "पूर्ण पैकेज",
    connectYourCryptoWallet: "अपना क्रिप्टो वॉलेट कनेक्ट करें। यदि आपके पास एक नहीं है, तो अपने टेलीग्राम खाते में एक वॉलेट बनाएं",
    dipt1: "USDT जमा",
    dip1: "जमा के लिए USDT की राशि दर्ज करें, सुनिश्चित करें कि मूल्य 10 USDT के बराबर या उससे अधिक हो।",
    dipt2: "USDT जमा बटन",
    dip2: "एक पैकेज जोड़ने के लिए इस बटन पर क्लिक करें और लेन-देन भेजें, सुनिश्चित करें कि आपके वॉलेट में सक्रिय पैकेज के लिए कम से कम 0.50 TON हो।",
    depositWith: "इसके साथ जमा करें",
    youCanDeposit: "आप टेलीग्राम बॉट्स के माध्यम से जमा कर सकते हैं। नीचे दिखाए गए बॉट्स में से एक चुनें और बिना किसी देरी के जमा करें।",
    deposit: "जमा करें",
    depositHistory: "जमा इतिहास",
    witt1: "USDT निकासी राशि",
    wit1: "निकासी राशि दर्ज करें, सुनिश्चित करें कि यह न्यूनतम आवश्यकता 10 USDT को पूरा करती हो।",
    witt2: "USDT निकासी",
    wit2: "निकासी बटन पर क्लिक करें और दर्ज की गई USDT राशि के आधार पर USDT प्राप्त करें।",
    withdrawWith: "इसके साथ निकासी करें",
    directWithdraw: "ब्लॉकचेन के माध्यम से सीधी निकासी में 10 मिनट तक का समय लग सकता है।",
    pleaseBePatient: "कृपया धैर्य रखें!",
    withdrawAmount: "निकासी राशि",
    minimumWithdraw: "न्यूनतम निकासी",
    withdrawHistory: "निकासी इतिहास",
    "level2Friends": "स्तर 2 मित्र",
    "level3Friends": "स्तर 3 मित्र",
    "level4Friends": "स्तर 4 मित्र",
    "level5Friends": "स्तर 5 मित्र",
    "level6Friends": "स्तर 6 मित्र",
    "level7Friends": "स्तर 7 मित्र",
    "level8Friends": "स्तर 8 मित्र",
    "level9Friends": "स्तर 9 मित्र",
    "level10Friends": "स्तर 10 मित्र",
    "level11Friends": "स्तर 11 मित्र",
    "level12Friends": "स्तर 12 मित्र",
    "level13Friends": "स्तर 13 मित्र",
    "level14Friends": "स्तर 14 मित्र",
    "level15Friends": "स्तर 15 मित्र"
}

const gujaratiWords = {
    profitPerHour: "પ્રતિ કલાક નફો",
    dailyReward: "દૈનિક ઇનામ",
    dailyCipher: "દૈનિક સિફર",
    dailyCombo: "દૈનિક કોમ્બો",
    Boost: "બુસ્ટ",
    boostYourProfit: "તમારો નફો વધારો",
    tapTheMiningMenu: "તમારા એક્સચેન્જ માટે અપગ્રેડ ખરીદવા માટે માઈનિંગ મેનુ પર ક્લિક કરો",
    EarnEvenWhenOffline: "ઓફલાઇન હોવા છતાં 3 કલાક સુધી કમાવો",
    startMining: "માઈનિંગ શરુ કરો",
    theExchangeHasStarted: "એક્સચેન્જ તમારા માટે કામ કરવાનું શરૂ કર્યું છે",
    Settings: "સેટિંગ્સ",
    chooseExchange: "એક્સચેન્જ પસંદ કરો",
    chooseLanguage: "ભાષા પસંદ કરો",
    deleteAcc: "એકાઉન્ટ ડિલીટ કરો",
    HapticFeedback: "હેપ્ટિક ફીડબેક",
    coinsAnimation: "કોઈન્સ એનિમેશન",

    areYouSureDelete: "શું તમે ખાતરીપૂર્વક તમારું એકાઉન્ટ ડિલીટ કરવા માંગો છો?",
    allYourDataDelete: "તમારા બધા ડેટા, જેમાં રમતની પ્રગતિ, સિદ્ધિ અને ખરીદીનો સમાવેશ થાય છે, કાયમ માટે ડિલીટ થઈ જશે. આ ક્રિયા પાછી ફરવી શક્ય નથી.",
    cancel: "રદ્દ કરો",
    yourBalance: "તમારો બેલેન્સ",
    freeDailyBooster: "મફત દૈનિક બૂસ્ટર્સ",
    fullEnergy: "પૂર્ણ ઊર્જા",
    available: "ઉપલબ્ધ",
    Turbo: "ટર્બો",
    commingSoon: "ટૂંક સમયમાં આવી રહ્યું છે",
    Boosters: "બૂસ્ટર્સ",
    multiTap: "મલ્ટીટેપ",
    energyLimit: "ઊર્જા મર્યાદા",
    increaseTheAmountOfCoins: "પ્રતિ ક્લિક પર કમાવાનાં સિક્કાની માત્રા વધારો",
    plusOneCoinFor: "+1 સિક્કો પ્રતિ લેવલ માટે",
    increaseAmountOfEnergy: "ઊર્જાની માત્રા વધારવી",
    plus500energy: "+500 ઊર્જા પોઈન્ટ પ્રતિ લેવલ માટે",
    rechargeYourEnergy: "તમારી ઊર્જા મર્યાદા ફરીથી ભરો અને માઈનિંગનું બીજું રાઉન્ડ શરૂ કરો",
    free: "મફત",
    goAhead: "આગળ વધો",
    theCodeIsCrocked: "કોડ ક્રેક થઈ ગયો છે! તમે ખરેખર એક ડિટેક્ટિવ છો!",
    home1: "પ્રતિ કલાક નફો વધારવાની સાથે દરેક કલાકે પોઈન્ટ કમાઓ. તમારા નફામાં મક્સીમમ વધારો કરવા માટે માઈન ખોલો અને અપગ્રેડ કરો!",
    homet1: "હેમસ્ટર કોમ્બાટ: પ્રતિ કલાક નફો સિસ્ટમ",
    home2: "હેમસ્ટર કોમ્બાટમાં રોજની વિલક્ષણ ઇનામો મેળવવા માટે દરરોજ લૉગિન કરો! ઇનામો જીતવા અને ઝડપી પ્રગતિ માટે રુટિન બનાવો!",
    homet2: "દૈનિક ઇનામો",
    home3: "હેમસ્ટર કોમ્બાટમાં ઇનામ મેળવવા માટે ઉપલબ્ધ શબ્દો ક્લિક કરીને મોર્સ કોડ બનાવો.",
    homet3: "દૈનિક સિફર",
    home4: "હેમસ્ટર કોમ્બાટમાં દૈનિક કોમ્બો માટે ત્રણ રેન્ડમ માઈન ખોલો અને ઇનામ મેળવો! તમારો રમવાનો આનંદ વધારવા માટે આકર્ષક બોનસો માણો!",
    homet4: "દૈનિક કોમ્બો",
    home5: "હેમસ્ટર કોમ્બાટમાં તમારા પોઈન્ટ વધારવા માટે સર્કલ ક્લિક કરો! દરેક ક્લિક સાથે તમારા પોઈન્ટ વધે છે.",
    homet5: "ક્લિકિંગ પોર્શન",
    home6: "હેમસ્ટર કોમ્બાટમાં પોઈન્ટ પ્રતિ ક્લિક વધારવા અને ઊર્જાની મર્યાદા અપગ્રેડ કરવા માટે બૂસ્ટ વિકલ્પનો ઉપયોગ કરો.",
    homet6: "બુસ્ટ વિકલ્પ",
    minet1: "કોમ્બો માઈન્સ",
    mine1: "તમારા કોમ્બો વિભાગમાં 3 કોમ્બો માઈન ખોલો અથવા અપગ્રેડ કરો અને બધા માટે 5,000,000 પોઈન્ટ્સ અને તમારી સક્રિય પેકેજનો 0.33% મેળવો.",
    mine2: "તમારા પોઈન્ટ નફો પ્રતિ કલાક વધારવા માટે તમારું માઈન ખોલો અથવા અપગ્રેડ કરો અને વધુ ઇનામ મેળવો.",
    minet2: "વધુ નફા માટે માઈન્સ ખોલો અને અપગ્રેડ કરો",
    mine3: "ખાસ શરતો પૂર્ણ કરીને વિશિષ્ટ માઈન્સ ખોલો, જેમ કે અન્ય માઈનને લેવલ 3 પર પહોંચાડવું.",
    minet3: "શરતો પૂર્ણ કરીને માઈન્સ ખોલો",
    prNTeam: "પી.આર. અને ટીમ",
    markets: "માર્કેટ્સ",
    legal: "કાનૂની",
    web3: "વેબ 3",
    yayCombo: "યે! કોમ્બો પૂર્ણ થયું છે. તમે સારું કરી રહ્યાં છો!",
    takeThePrize: "ઈનામ મેળવો",
    Exchange: "એક્સચેન્જ",
    mine: "માઇન",
    friends: "મિત્રો",
    earn: "કમાવો",
    airdrop: "વૉલેટ",
    friendt1: "મિત્રોને આમંત્રિત કરો અને વધુ કમાવો",
    friend1: "આમંત્રણ બટન ક્લિક કરીને તમારા મિત્રોને તમારો રેફરલ લિંક શેર કરો અને તમારું ઇનામ વધારાવો.",
    friendt2: "રેફરલ લિંક કૉપિ કરો અને શેર કરો",
    friend2: "કૉપિ બટનનો ઉપયોગ કરીને તમારો રેફરલ લિંક તરત જ કૉપિ કરો અને વધુ ઇનામ માટે મિત્રો સાથે શેર કરો.",
    inviteFriends: "મિત્રોને આમંત્રિત કરો!",
    youAndYourFriend: "તમને અને તમારા મિત્રને બોનસ મળશે.",
    PlusAsSpecialBonus: "વધુમાં, ખાસ બોનસ તરીકે, દરેક નવા_REFERRED યુઝરને મફતમાં $10 પેકેજ મળશે! આ આકર્ષક ઇનામો ચૂકી ન જાવ!",
    specialBonusAlert: "ખાસ બોનસ ચેતવણી!",
    specialBonusDescription: "<strong>ખાસ બોનસ ચેતવણી! દરેક નવા_REFERRED યુઝરને હેમસ્ટર કોમ્બાટમાં મફત $10 પેકેજ મળશે! તમારું રમત રમીને આનંદ વધારવા માટે આ આકર્ષક તક છે. આજે મિત્રોનું REFER કરવા શરૂ કરો અને વધુ લાભ મેળવો!</strong>",
    inviteAFriend: "મિત્રને આમંત્રિત કરો",
    forYouAndYour: "તમારા અને તમારા મિત્ર માટે",
    inviteWithPremium: "ટેલિગ્રામ પ્રીમિયમ સાથે મિત્રને આમંત્રિત કરો",
    moreBonuses: "વધુ બોનસ",
    bonusForLevelingUP: "લેવલ અપ માટે બોનસ",
    level: "લેવલ",
    forFriend: "મિત્ર માટે",
    premium: "પ્રીમિયમ",
    listOfFriends: "તમારા મિત્રોની યાદી",
    earnt1: "વિડીયો જુઓ અને ઇનામ મેળવો",
    earn1: "યુટ્યુબ ટાસ્ક પૂર્ણ કરો અને ઇનામ મેળવો.",
    earnt2: "દૈનિક ઇનામો",
    earn2: "હેમસ્ટર કોમ્બાટમાં દૈનિક ઇનામ મેળવો! ઝડપી પ્રગતિ માટે રોજ લૉગિન કરો!",
    earnt3: "સરળ ટાસ્ક પૂર્ણ કરો અને ઇનામ મેળવો",
    earn3: "ટાસ્ક પૂર્ણ કરીને ઇનામ મેળવો જેમ કે ટેલિગ્રામમાં જોડાવા, X પર ફોલો કરવું, એક્સચેન્જ પસંદ કરવી, અને 3 મિત્રો આમંત્રિત કરવા.",
    earnMoreCoin: "વધુ સિક્કા કમાવો",
    hamsterYoutube: "હેમસ્ટર યુટ્યુબ",
    dailyTasks: "દૈનિક ટાસ્ક",
    tasksList: "ટાસ્કની યાદી",
    accureCoinsForLogin: "રમતમાં દરરોજ લૉગિન કરીને સિક્કા મેળવો",
    day: "દિવસ",
    check: "ચેક કરો",
    comeBackTomorrow: "આવતી કાલે પાછા આવો",
    wait1hour: "ઈનામ મેળવવા માટે 1 કલાક રાહ જોવાઓ",
    airt1: "એક ક્લિકમાં USDT કોન્ટ્રાક્ટ કૉપિ કરો",
    air1: "USDT માટે કોન્ટ્રાક્ટ કૉપિ કરવા માટે ક્લિક કરો અને સરળતાથી અન્ય સાથે શેર કરો.",
    airt2: "USDT કિંમત",
    air2: "ખાતરી કરો કે USDT ની વર્તમાન કિંમત અપડેટ છે.",
    airt3: "તમારું વૉલેટ કનેક્ટ કરો",
    air3: "ટ્રાન્ઝેક્શન માટે તમારું વૉલેટ કનેક્ટ કરવા માટે આ બટનનો ઉપયોગ કરો.",
    airt4: "જમા રકમ",
    air4: "તમારી ફંડ્સની અસરકારક વ્યવસ્થા માટે USDT માં તમારાં જમા થેલાં રકમની ચકાસણી કરો.",
    airt5: "કાઢી શકાતી રકમ",
    air5: "તમારી કાઢી શકાતી રકમ જુઓ, જેમાં ઓછામાં ઓછો 10 USDT નો વિથડ્રૉલ મર્યાદા છે.",
    airt6: "વર્તમાન પેકેજોની ઝાંખી",
    air6: "વપરાશકર્તાઓ માટે ઉપલબ્ધ તમામ વર્તમાન પેકેજો દર્શાવતી ટેબલને શોધો.",
    airt7: "પૂરા થયેલ પેકેજોની ઝાંખી",
    air7: "તમારી સિદ્ધિઓને ટ્રૅક કરવા માટે તમામ પૂર્ણ થયેલ પેકેજો દર્શાવતી ટેબલ જુઓ.",
    connectYourTonWallet: "તમારું TON વૉલેટ કનેક્ટ કરો",
    currentPackage: "વર્તમાન પેકેજ",
    date: "તારીખ",
    investedAmount: "નિવેશિત રકમ",
    totalReward: "કુલ ઇનામ",
    todayEarning: "આજેની કમાણી",
    totalRecived: "કુલ પ્રાપ્ત",
    completedPackage: "પૂર્ણ પેકેજ",
    connectYourCryptoWallet: "તમારું ક્રિપ્ટો વૉલેટ કનેક્ટ કરો. જો તમારું એક નથી, તો તમારા ટેલિગ્રામ એકાઉન્ટમાં વૉલેટ બનાવો",
    dipt1: "USDT જમા",
    dip1: "જમા માટે USDT ની રકમ દાખલ કરો, ખાતરી કરો કે મૂલ્ય 10 USDT જેટલું કે તેનાથી વધુ છે.",
    dipt2: "USDT જમા બટન",
    dip2: "ટ્રાન્ઝેક્શન મોકલીને પેકેજ ઉમેરવા માટે આ બટન પર ક્લિક કરો, ખાતરી કરો કે તમારી વૉલેટમાં સક્રિય પેકેજ માટે ઓછામાં ઓછું 0.50 TON છે.",
    depositWith: "સાથે જમા કરો",
    youCanDeposit: "તમે ટેલિગ્રામ બોટ્સ મારફતે જમા કરી શકો છો. નીચે બતાવવામાં આવેલા બોટ્સમાંથી એક પસંદ કરો અને વિલંબ વગર જમા કરો.",
    deposit: "જમા કરો",
    depositHistory: "જમા ઈતિહાસ",
    witt1: "USDT ઉપાડની રકમ",
    wit1: "ઉપાડની રકમ દાખલ કરો, ખાતરી કરો કે તે 10 USDT ની ન્યूनતમ જરૂરિયાતને પૂરી કરે છે.",
    witt2: "USDT ઉપાડ",
    wit2: "ઉપાડ બટન પર ક્લિક કરો અને દાખલ કરેલી USDT રકમના આધારે USDT મેળવો.",
    withdrawWith: "સાથે ઉપાડ કરો",
    directWithdraw: "બ્લોકચેઇન મારફતે સીધો ઉપાડ કરવામાં 10 મિનિટ સુધીનો સમય લાગી શકે છે.",
    pleaseBePatient: "કૃપા કરીને ધીરજ રાખો!",
    withdrawAmount: "ઉપાડની રકમ",
    minimumWithdraw: "ન્યૂનતમ ઉપાડ",
    withdrawHistory: "ઉપાડ ઇતિહાસ",
    "level2Friends": "સ્તર 2 મિત્રો",
    "level3Friends": "સ્તર 3 મિત્રો",
    "level4Friends": "સ્તર 4 મિત્રો",
    "level5Friends": "સ્તર 5 મિત્રો",
    "level6Friends": "સ્તર 6 મિત્રો",
    "level7Friends": "સ્તર 7 મિત્રો",
    "level8Friends": "સ્તર 8 મિત્રો",
    "level9Friends": "સ્તર 9 મિત્રો",
    "level10Friends": "સ્તર 10 મિત્રો",
    "level11Friends": "સ્તર 11 મિત્રો",
    "level12Friends": "સ્તર 12 મિત્રો",
    "level13Friends": "સ્તર 13 મિત્રો",
    "level14Friends": "સ્તર 14 મિત્રો",
    "level15Friends": "સ્તર 15 મિત્રો"
}

const russianWords = {
    profitPerHour: "Прибыль в час",
    dailyReward: "Ежедневная награда",
    dailyCipher: "Ежедневный шифр",
    dailyCombo: "Ежедневный комбо",
    Boost: "Усиление",
    boostYourProfit: "Увеличьте свою прибыль",
    tapTheMiningMenu: "Нажмите на меню добычи, чтобы купить улучшения для вашей биржи",
    EarnEvenWhenOffline: "Зарабатывайте даже в оффлайне до 3 часов",
    startMining: "Начать добычу",
    theExchangeHasStarted: "Биржа начала работать на вас",
    Settings: "Настройки",
    chooseExchange: "Выберите биржу",
    chooseLanguage: "Выберите язык",
    deleteAcc: "Удалить аккаунт",
    HapticFeedback: "Гаптическая обратная связь",
    coinsAnimation: "Анимация монет",

    areYouSureDelete: "Вы уверены, что хотите удалить свой аккаунт?",
    allYourDataDelete: "Все ваши данные, включая прогресс в игре, достижения и покупки, будут безвозвратно удалены. Это действие нельзя отменить.",
    cancel: "Отмена",
    yourBalance: "Ваш баланс",
    freeDailyBooster: "Бесплатные ежедневные усилители",
    fullEnergy: "Полная энергия",
    available: "доступно",
    Turbo: "Турбо",
    commingSoon: "Скоро",
    Boosters: "Усилители",
    multiTap: "Мульти-тап",
    energyLimit: "Лимит энергии",
    increaseTheAmountOfCoins: "Увеличьте количество монет, которые вы можете заработать за одно нажатие",
    plusOneCoinFor: "+1 монета за нажатие за уровень",
    increaseAmountOfEnergy: "Увеличьте количество энергии",
    plus500energy: "+500 очков энергии за уровень",
    rechargeYourEnergy: "Зарядите свою энергию до максимума и проведите еще один раунд добычи",
    free: "Бесплатно",
    goAhead: "Далее",
    theCodeIsCrocked: "Код взломан. Вы настоящий детектив!",
    home1: "Зарабатывайте очки каждый час, увеличивая свою прибыль в час. Разблокируйте и улучшайте шахты, чтобы максимизировать свои доходы!",
    homet1: "Hamster Kombat: Система прибыли в час",
    home2: "Входите ежедневно, чтобы получать захватывающие награды в Hamster Kombat! Создайте рутину, чтобы зарабатывать награды и быстрее продвигаться!",
    homet2: "Ежедневные награды",
    home3: "Создайте код Морзе, нажимая доступные слова, чтобы заработать награды в Hamster Kombat.",
    homet3: "Ежедневный шифр",
    home4: "Разблокируйте три случайные шахты для ежедневного комбо и заработайте награды в Hamster Kombat! Наслаждайтесь захватывающими бонусами для улучшения игрового процесса!",
    homet4: "Ежедневный комбо",
    home5: "Нажмите на круг, чтобы увеличить свои очки в Hamster Kombat! Каждое нажатие увеличивает ваши очки.",
    homet5: "Секция нажатий",
    home6: "Используйте опцию усиления, чтобы увеличить лимит энергии и количество очков за нажатие в Hamster Kombat.",
    homet6: "Опция усиления",
    minet1: "Комбо шахты",
    mine1: "Разблокируйте или улучшите 3 комбо шахты, чтобы добавить их в ваш раздел комбо и архивировать их для получения 5,000,000 очков и 0.33% вашего активного пакета.",
    mine2: "Покупайте, чтобы разблокировать или улучшить свою шахту и увеличить прибыль в час, максимизируя свои награды.",
    minet2: "Разблокируйте и улучшайте шахты для большей прибыли",
    mine3: "Разблокируйте особые шахты, выполнив определенные условия, например, достигнув 3 уровня на другой шахте, чтобы открыть новые возможности для прибыли.",
    minet3: "Разблокируйте шахты, выполняя условия",
    prNTeam: "PR и команда",
    markets: "Рынки",
    legal: "Юридический",
    web3: "Web3",
    yayCombo: "Ура, комбо завершено. Отличная работа!",
    takeThePrize: "Заберите приз",
    Exchange: "Биржа",
    mine: "Шахта",
    friends: "Друзья",
    earn: "Заработать",
    airdrop: "кошелек",
    friendt1: "Приглашайте друзей и зарабатывайте больше",
    friend1: "Нажмите на кнопку приглашения, чтобы поделиться своей реферальной ссылкой с друзьями и увеличить свои награды.",
    friendt2: "Скопируйте и поделитесь реферальной ссылкой",
    friend2: "Используйте кнопку копирования, чтобы мгновенно скопировать свою реферальную ссылку и поделиться ею с друзьями для получения наград.",
    inviteFriends: "Пригласите друзей!",
    youAndYourFriend: "Вы и ваш друг получите бонусы.",
    PlusAsSpecialBonus: "Кроме того, в качестве специального бонуса каждый новый приглашенный пользователь получит пакет на $10 бесплатно! Не пропустите эти захватывающие награды!",
    specialBonusAlert: "Предупреждение о специальном бонусе!",
    specialBonusDescription: "<strong>Предупреждение о специальном бонусе! Каждый новый пользователь, которого вы пригласите, получит бесплатный пакет на $10 в Hamster Kombat! Это отличная возможность улучшить свою игру и насладиться захватывающими наградами. Не упустите шанс — начните приглашать друзей уже сегодня, чтобы получить еще больше преимуществ и повысить качество игрового процесса!</strong>",
    inviteAFriend: "Пригласите друга",
    forYouAndYour: "для вас и вашего друга",
    inviteWithPremium: "Пригласите друга с Telegram Premium",
    moreBonuses: "Больше бонусов",
    bonusForLevelingUP: "Бонус за повышение уровня",
    level: "уровень",
    forFriend: "Для друга",
    premium: "Премиум",
    listOfFriends: "Список ваших друзей",
    earnt1: "Смотрите видео и зарабатывайте награды",
    earn1: "Выполняйте задания на YouTube, смотрите видео и получайте награды за участие.",
    earnt2: "Ежедневные награды",
    earn2: "Входите ежедневно, чтобы получать захватывающие награды в Hamster Kombat! Создайте рутину для более быстрого прогресса!",
    earnt3: "Выполняйте простые задания для наград",
    earn3: "Зарабатывайте награды, выполняя задания, такие как вступление в Telegram, подписка на X, выбор биржи и приглашение 3 друзей.",
    earnMoreCoin: "Заработать больше монет",
    hamsterYoutube: "Hamster YouTube",
    dailyTasks: "Ежедневные задания",
    tasksList: "Список заданий",
    accureCoinsForLogin: "Зарабатывайте монеты за ежедневный вход в игру без пропусков",
    day: "День",
    check: "Проверить",
    comeBackTomorrow: "Возвращайтесь завтра",
    wait1hour: "Подождите 1 час для проверки модерацией, чтобы получить приз",
    airt1: "Скопируйте контракт USDT одним нажатием",
    air1: "Нажмите, чтобы скопировать контракт для USDT и легко поделиться им с другими.",
    airt2: "Цена USDT",
    air2: "Следите за актуальной ценой USDT в этом разделе для информированных торговых решений.",
    airt3: "Подключите свой кошелек",
    air3: "Используйте эту кнопку для простого подключения кошелька для беспроблемных транзакций и взаимодействий.",
    airt4: "Сумма депозита",
    air4: "Проверьте текущую сумму вашего депозита в USDT для эффективного управления средствами.",
    airt5: "Доступная для вывода сумма",
    air5: "Посмотрите сумму, доступную для вывода, с минимальной суммой вывода в 10 USDT.",
    airt6: "Обзор текущих пакетов",
    air6: "Изучите таблицу, отображающую все доступные пакеты для пользователей.",
    airt7: "Обзор завершенных пакетов",
    air7: "Посмотрите таблицу с завершенными пакетами, чтобы отслеживать свои достижения.",
    connectYourTonWallet: "Подключите свой TON-кошелек",
    currentPackage: "Текущий пакет",
    date: "Дата",
    investedAmount: "Инвестированная сумма",
    totalReward: "Общая награда",
    todayEarning: "Сегодняшний доход",
    totalRecived: "Всего получено",
    completedPackage: "Завершенный пакет",
    connectYourCryptoWallet: "Подключите свой криптокошелек. Если у вас его нет, создайте один в своем аккаунте Telegram.",
    dipt1: "Депозит USDT",
    dip1: "Введите сумму USDT для депозита, убедившись, что значение равно или больше 10 USDT.",
    dipt2: "Кнопка депозита USDT",
    dip2: "Нажмите эту кнопку, чтобы добавить пакет, отправив транзакцию, убедившись, что у вас есть хотя бы 0.50 TON в вашем кошельке для активного пакета.",
    depositWith: "Депозит через",
    youCanDeposit: "Вы можете внести депозит через Telegram-ботов. Выберите одного из показанных ниже ботов и вносите без задержек.",
    deposit: "Депозит",
    depositHistory: "История депозитов",
    witt1: "Сумма вывода USDT",
    wit1: "Введите сумму для вывода, убедившись, что она соответствует минимальному требованию в 10 USDT.",
    witt2: "Вывод USDT",
    wit2: "Нажмите кнопку вывода, чтобы получить USDT в зависимости от суммы USDT, которую вы ввели.",
    withdrawWith: "Вывод через",
    directWithdraw: "Прямой вывод через блокчейн может занять до 10 минут.",
    pleaseBePatient: "Пожалуйста, подождите!",
    withdrawAmount: "Сумма вывода",
    minimumWithdraw: "Минимальный вывод",
    withdrawHistory: "История выводов",
    "level2Friends": "Уровень 2 Друзья",
    "level3Friends": "Уровень 3 Друзья",
    "level4Friends": "Уровень 4 Друзья",
    "level5Friends": "Уровень 5 Друзья",
    "level6Friends": "Уровень 6 Друзья",
    "level7Friends": "Уровень 7 Друзья",
    "level8Friends": "Уровень 8 Друзья",
    "level9Friends": "Уровень 9 Друзья",
    "level10Friends": "Уровень 10 Друзья",
    "level11Friends": "Уровень 11 Друзья",
    "level12Friends": "Уровень 12 Друзья",
    "level13Friends": "Уровень 13 Друзья",
    "level14Friends": "Уровень 14 Друзья",
    "level15Friends": "Уровень 15 Друзья"
}

const urduWords = {
    profitPerHour: "فی گھنٹہ منافع",
    dailyReward: "روزانہ انعام",
    dailyCipher: "روزانہ سائفر",
    dailyCombo: "روزانہ کومبو",
    Boost: "بوسٹ",
    boostYourProfit: "اپنا منافع بڑھائیں",
    tapTheMiningMenu: "اپنی ایکسچینج کے لیے اپ گریڈ خریدنے کے لیے مائننگ مینو پر ٹیپ کریں",
    EarnEvenWhenOffline: "آف لائن ہونے پر بھی 3 گھنٹے تک کمائیں",
    startMining: "مائننگ شروع کریں",
    theExchangeHasStarted: "ایکسچینج آپ کے لیے کام کرنا شروع ہو گیا ہے",
    Settings: "ترتیبات",
    chooseExchange: "ایکسچینج منتخب کریں",
    chooseLanguage: "زبان منتخب کریں",
    deleteAcc: "اکاؤنٹ حذف کریں",
    HapticFeedback: "ہپٹک فیڈبیک",
    coinsAnimation: "سکوں کی اینیمیشن",

    areYouSureDelete: "کیا آپ واقعی اپنا اکاؤنٹ حذف کرنا چاہتے ہیں؟",
    allYourDataDelete: "آپ کے تمام ڈیٹا، بشمول گیم کی ترقی، کامیابی، اور خریداری، مستقل طور پر حذف کر دیے جائیں گے۔ یہ عمل واپس نہیں لیا جا سکتا۔",
    cancel: "کینسل کریں",
    yourBalance: "آپ کا بیلنس",
    freeDailyBooster: "مفت روزانہ بووسٹر",
    fullEnergy: "مکمل توانائی",
    available: "دستیاب",
    Turbo: "ٹربو",
    commingSoon: "جلد آرہا ہے",
    Boosters: "بوسٹر",
    multiTap: "ملٹی ٹیپ",
    energyLimit: "توانائی کی حد",
    increaseTheAmountOfCoins: "ہر ٹیپ پر حاصل کردہ سکوں کی مقدار بڑھائیں",
    plusOneCoinFor: "لیول کے لیے +1 سکّہ فی ٹیپ",
    increaseAmountOfEnergy: "توانائی کی مقدار بڑھائیں",
    plus500energy: "لیول کے لیے +500 توانائی پوائنٹس",
    rechargeYourEnergy: "اپنی توانائی کو زیادہ سے زیادہ بھرپور کریں اور مائننگ کا ایک اور دور کریں",
    free: "مفت",
    goAhead: "آگے بڑھیں",
    theCodeIsCrocked: "کوڈ کی کوئی چالاکی ہے، آپ واقعی ایک جاسوس ہیں!",
    home1: "اپنا فی گھنٹہ منافع بڑھا کر ہر گھنٹے میں پوائنٹس کمائیں۔ اپنی آمدنی کو زیادہ سے زیادہ کرنے کے لیے مائنز کو انلاک اور اپ گریڈ کریں!",
    homet1: "ہمسٹر کومبات: فی گھنٹہ منافع کا نظام",
    home2: "ہمسٹر کومبات میں روزانہ کے دلچسپ انعامات حاصل کرنے کے لیے روزانہ لاگ ان کریں! انعامات حاصل کرنے کے لیے ایک روٹین بنائیں اور تیزی سے ترقی کریں!",
    homet2: "روزانہ انعامات",
    home3: "ہمسٹر کومبات میں انعامات حاصل کرنے کے لیے دستیاب الفاظ پر کلک کرکے مورس کوڈ بنائیں۔",
    homet3: "روزانہ سائفر",
    home4: "روزانہ کومبو کے لیے تین تصادفی مائنز انلاک کریں اور ہمسٹر کومبات میں انعامات حاصل کریں! اپنے گیم پلے کو بڑھانے کے لیے دلچسپ بونس کا لطف اٹھائیں!",
    homet4: "روزانہ کومبو",
    home5: "ہمسٹر کومبات میں اپنے پوائنٹس بڑھانے کے لیے دائرے پر کلک کریں! ہر کلک آپ کے پوائنٹس بڑھاتا ہے۔",
    homet5: "کلک کرنے کا حصہ",
    home6: "ہمسٹر کومبات میں اپنے پوائنٹس فی کلک بڑھانے کے لیے اپنی توانائی کی حد کو اپ گریڈ کرنے کے لیے بوسٹ آپشن کا استعمال کریں۔",
    homet6: "بوسٹ آپشن",
    minet1: "کومبو مائنز",
    mine1: "اپنے کومبو سیکشن میں شامل کرنے کے لیے 3 کومبو مائنز کو انلاک یا اپ گریڈ کریں، اور 5,000,000 پوائنٹس اور آپ کے فعال پیکیج کا 0.33% آرکائیو کریں۔",
    mine2: "اپنی مائن کو انلاک یا اپ گریڈ کرنے کے لیے خریداری کریں تاکہ آپ کے پوائنٹس کا فی گھنٹہ منافع بڑھ سکے اور انعامات کو زیادہ سے زیادہ کیا جا سکے۔",
    minet2: "زیادہ منافع کے لیے مائنز کو انلاک اور اپ گریڈ کریں",
    mine3: "خاص مائنز کو مخصوص شرائط پوری کرنے کے ذریعے انلاک کریں، جیسے کسی دوسرے مائن پر سطح 3 تک پہنچنا تاکہ منافع کے نئے مواقع کھل سکیں۔",
    minet3: "شرائط پوری کرکے مائنز کو انلاک کریں",
    prNTeam: "پی آر اور ٹیم",
    markets: "مارکیٹس",
    legal: "قانونی",
    web3: "ویب 3",
    yayCombo: "یہاں! کومبو مکمل ہو گیا۔ آپ زبردست ہیں!",
    takeThePrize: "انعام حاصل کریں",
    Exchange: "ایکسچینج",
    mine: "مائن",
    friends: "دوست",
    earn: "کمائیں",
    airdrop: "ایئر ڈراپ",
    friendt1: "دوستوں کو مدعو کریں اور مزید کمائیں",
    friend1: "اپنے ریفرل لنک کو دوستوں کے ساتھ شیئر کرنے کے لیے دعوت کے بٹن پر کلک کریں اور اپنے انعامات کو بڑھائیں۔",
    friendt2: "ریفرل لنک کو کاپی اور شیئر کریں",
    friend2: "اپنا ریفرل لنک فوری طور پر کاپی کرنے کے لیے کاپی بٹن کا استعمال کریں اور انعامات کے لیے دوستوں کے ساتھ شیئر کریں۔",
    inviteFriends: "دوستوں کو مدعو کریں!",
    youAndYourFriend: "آپ اور آپ کا دوست انعامات حاصل کریں گے۔",
    PlusAsSpecialBonus: "اس کے علاوہ، بطور خاص بونس، ہر نئے ریفر کردہ صارف کو مفت $10 کا پیکیج ملے گا! ان دلچسپ انعامات کا موقع ہاتھ سے نہ جانے دیں!",
    specialBonusAlert: "خاص بونس کی اطلاع!",
    specialBonusDescription: "<strong>خاص بونس کی اطلاع! آپ کے ریفر کردہ ہر نئے صارف کو ہمسٹر کومبات میں مفت $10 کا پیکیج ملے گا! یہ آپ کے گیم پلے کو بڑھانے اور دلچسپ انعامات سے لطف اندوز ہونے کا شاندار موقع ہے۔ انعامات کے مزید فوائد کو کھولنے کے لیے آج ہی دوستوں کو ریفر کرنا شروع کریں!</strong>",
    inviteAFriend: "ایک دوست کو مدعو کریں",
    forYouAndYour: "آپ اور آپ کے دوست کے لیے",
    inviteWithPremium: "ٹیلی گرام پریمیم کے ساتھ دوست کو مدعو کریں",
    moreBonuses: "مزید بونس",
    bonusForLevelingUP: "لیول بڑھانے کے لیے بونس",
    level: "لیول",
    forFriend: "دوست کے لیے",
    premium: "پریمیم",
    listOfFriends: "آپ کے دوستوں کی فہرست",
    earnt1: "ویڈیوز دیکھیں اور انعامات حاصل کریں",
    earn1: "ویڈیوز دیکھ کر یوٹیوب کے کام مکمل کریں اور اپنی شرکت کے لیے انعامات حاصل کریں۔",
    earnt2: "روزانہ کے انعامات",
    earn2: "روزانہ لاگ ان کریں تاکہ ہمسٹر کومبات میں دلچسپ انعامات حاصل کر سکیں! انعامات حاصل کرنے کے لیے ایک روٹین بنائیں اور تیزی سے ترقی کریں!",
    earnt3: "انعامات کے لیے سادہ کام مکمل کریں",
    earn3: "ٹیلی گرام میں شامل ہونے، ایکس پر فالو کرنے، ایکسچینج منتخب کرنے، اور 3 دوستوں کو مدعو کرکے انعامات حاصل کریں۔",
    earnMoreCoin: "زیادہ سکّے کمائیں",
    hamsterYoutube: "ہمسٹر یوٹیوب",
    dailyTasks: "روزانہ کے کام",
    tasksList: "کاموں کی فہرست",
    accureCoinsForLogin: "روزانہ بغیر چھوڑے گیم میں لاگ ان کرنے پر سکّے جمع کریں",
    day: "دن",
    check: "چیک کریں",
    comeBackTomorrow: "کل واپس آئیں",
    wait1hour: "انعام کے دعوے کے لیے موڈریشن چیک کے لیے 1 گھنٹہ انتظار کریں",
    airt1: "ایک کلک میں USDT کا معاہدہ کاپی کریں",
    air1: "USDT کے معاہدے کو کاپی کرنے کے لیے کلک کریں اور دوسروں کے ساتھ آسانی سے شیئر کریں۔",
    airt2: "USDT کی قیمت",
    air2: "تجارتی فیصلوں کے لیے USDT کی موجودہ قیمت سے باخبر رہیں۔",
    airt3: "اپنا والٹ جوڑیں",
    air3: "ہموار لین دین اور تعامل کے لیے اپنے والٹ کو آسانی سے جوڑنے کے لیے اس بٹن کا استعمال کریں۔",
    airt4: "جمع کرنے کی رقم",
    air4: "اپنے فنڈز کے مؤثر انتظام کے لیے USDT میں اپنی جمع کی موجودہ رقم چیک کریں۔",
    airt5: "نکالنے کے قابل رقم",
    air5: "اپنی نکالی جانے والی رقم دیکھیں، جس کی کم از کم نکاسی کی حد 10 USDT ہے۔",
    airt6: "موجودہ پیکیجوں کا جائزہ",
    air6: "صارفین کے لیے دستیاب تمام موجودہ پیکیجوں کی جدول کی کھوج کریں۔",
    airt7: "مکمل پیکیجوں کا جائزہ",
    air7: "اپنی کامیابیوں کو ٹریک کرنے کے لیے تمام مکمل پیکیجوں کی جدول دیکھیں۔",
    connectYourTonWallet: "اپنی TON والٹ کو جوڑیں",
    currentPackage: "موجودہ پیکیج",
    date: "تاریخ",
    investedAmount: "سرمایہ کاری کی رقم",
    totalReward: "کل انعام",
    todayEarning: "آج کی کمائی",
    totalRecived: "کل موصول",
    completedPackage: "مکمل پیکیج",
    connectYourCryptoWallet: "اپنی کریپٹو والٹ کو جوڑیں۔ اگر آپ کے پاس ایک نہیں ہے تو اپنے ٹیلی گرام اکاؤنٹ میں ایک بنائیں۔",
    dipt1: "USDT جمع",
    dip1: "جمع کرنے کے لیے USDT کی رقم درج کریں، اس بات کو یقینی بناتے ہوئے کہ یہ قیمت 10 USDT یا اس سے زیادہ ہو۔",
    dipt2: "USDT جمع کرنے کا بٹن",
    dip2: "اس بٹن پر کلک کریں تاکہ ٹرانزیکشن بھیج کر ایک پیکیج شامل کریں، اس بات کو یقینی بناتے ہوئے کہ آپ کے والٹ میں ایکٹیو پیکیج کے لیے کم از کم 0.50 TON موجود ہو۔",
    depositWith: "ذریعہ جمع",
    youCanDeposit: "آپ ٹیلی گرام بوٹس کے ذریعے جمع کر سکتے ہیں۔ نیچے دکھائے گئے بوٹس میں سے ایک کا انتخاب کریں اور بغیر کسی تاخیر کے جمع کریں۔",
    deposit: "جمع",
    depositHistory: "جمع کرنے کی تاریخ",
    witt1: "USDT نکالنے کی رقم",
    wit1: "نکالنے کے لیے رقم درج کریں، اس بات کو یقینی بناتے ہوئے کہ یہ کم از کم 10 USDT کی شرط پر پورا اترتا ہو۔",
    witt2: "USDT نکاسی",
    wit2: "نکالنے کے بٹن پر کلک کریں تاکہ آپ کے درج کردہ USDT کی مقدار کی بنیاد پر USDT حاصل کریں۔",
    withdrawWith: "نکالنے کا ذریعہ",
    directWithdraw: "بلاک چین کے ذریعے براہ راست نکاسی میں 10 منٹ لگ سکتے ہیں۔",
    pleaseBePatient: "براہ کرم صبر کریں!",
    withdrawAmount: "نکالی جانے والی رقم",
    minimumWithdraw: "کم از کم نکاسی",
    withdrawHistory: "نکاسی کی تاریخ",
    "level2Friends": "سطح 2 دوست",
    "level3Friends": "سطح 3 دوست",
    "level4Friends": "سطح 4 دوست",
    "level5Friends": "سطح 5 دوست",
    "level6Friends": "سطح 6 دوست",
    "level7Friends": "سطح 7 دوست",
    "level8Friends": "سطح 8 دوست",
    "level9Friends": "سطح 9 دوست",
    "level10Friends": "سطح 10 دوست",
    "level11Friends": "سطح 11 دوست",
    "level12Friends": "سطح 12 دوست",
    "level13Friends": "سطح 13 دوست",
    "level14Friends": "سطح 14 دوست",
    "level15Friends": "سطح 15 دوست"
}

const chineseWords = {
    profitPerHour: "每小时利润",
    dailyReward: "每日奖励",
    dailyCipher: "每日密码",
    dailyCombo: "每日组合",
    Boost: "提升",
    boostYourProfit: "提升你的利润",
    tapTheMiningMenu: "点击挖矿菜单以购买交换的升级",
    EarnEvenWhenOffline: "即使离线也能赚取奖励，最长可达3小时",
    startMining: "开始挖矿",
    theExchangeHasStarted: "交换已开始为您工作",
    Settings: "设置",
    chooseExchange: "选择交换",
    chooseLanguage: "选择语言",
    deleteAcc: "删除账户",
    HapticFeedback: "触觉反馈",
    coinsAnimation: "硬币动画",

    areYouSureDelete: "您确定要删除您的账户吗？",
    allYourDataDelete: "您所有的数据，包括游戏进度、成就和购买，将被永久删除。此操作无法撤消。",
    cancel: "取消",
    yourBalance: "您的余额",
    freeDailyBooster: "免费每日助推器",
    fullEnergy: "满能量",
    available: "可用",
    Turbo: "涡轮",
    commingSoon: "即将到来",
    Boosters: "助推器",
    multiTap: "多次点击",
    energyLimit: "能量限制",
    increaseTheAmountOfCoins: "增加每次点击可以赚取的硬币数量",
    plusOneCoinFor: "每级+1硬币",
    increaseAmountOfEnergy: "增加能量数量",
    plus500energy: "每级+500能量点",
    rechargeYourEnergy: "将能量充满并进行另一轮挖矿",
    free: "免费",
    goAhead: "继续",
    theCodeIsCrocked: "密码被破解了，您真是一名侦探！",
    home1: "通过增加每小时的利润每小时赚取积分。解锁并升级矿井以最大化收益！",
    homet1: "Hamster Kombat: 每小时利润系统",
    home2: "每天登录以在Hamster Kombat中获得令人兴奋的奖励！建立一个例行程序以赚取奖励并加速进步！",
    homet2: "每日奖励",
    home3: "通过点击可用词语创建摩尔斯密码，以在Hamster Kombat中获得奖励。",
    homet3: "每日密码",
    home4: "解锁三座随机矿井以获得每日组合奖励，并在Hamster Kombat中赚取奖励！享受令人兴奋的奖金以增强您的游戏体验！",
    homet4: "每日组合",
    home5: "点击圆圈以提高您在Hamster Kombat中的积分！每次点击都会增加您的积分。",
    homet5: "点击部分",
    home6: "使用提升选项来升级您的能量限制并增加每次点击的积分。",
    homet6: "提升选项",
    minet1: "组合矿井",
    mine1: "解锁或升级3个组合矿井以将其添加到您的组合部分，所有矿井归档后可获得5,000,000积分和您活跃包的0.33%。",
    mine2: "购买以解锁或升级您的矿井，以增加每小时的积分收益并最大化奖励。",
    minet2: "解锁和升级矿井以获得更多利润",
    mine3: "通过满足特定条件解锁特殊矿井，例如将另一矿井提升到3级以解锁新的利润机会。",
    minet3: "通过满足条件解锁矿井",
    prNTeam: "公关与团队",
    markets: "市场",
    legal: "法律",
    web3: "Web3",
    yayCombo: "耶，组合完成了。你做得很好！",
    takeThePrize: "领取奖励",
    Exchange: "交换",
    mine: "矿井",
    friends: "朋友",
    earn: "赚取",
    airdrop: "空投",
    friendt1: "邀请朋友赚取更多",
    friend1: "点击邀请按钮与朋友分享您的推荐链接，扩大您的奖励。",
    friendt2: "复制并分享推荐链接",
    friend2: "使用复制按钮立即复制您的推荐链接，并与朋友分享以获得奖励。",
    inviteFriends: "邀请朋友！",
    youAndYourFriend: "您和您的朋友将获得奖金。",
    PlusAsSpecialBonus: "此外，作为特别奖励，每个新推荐用户将免费获得一个价值10美元的套餐！不要错过这些令人兴奋的奖励！",
    specialBonusAlert: "特别奖励提醒！",
    specialBonusDescription: "<strong>特别奖励提醒！每个您推荐的新用户将在Hamster Kombat中获得一个免费的10美元套餐！这是提升您的游戏体验并享受令人兴奋的奖励的绝佳机会。不要错过，今天就开始邀请朋友以解锁更多好处，提升您的游戏体验！</strong>",
    inviteAFriend: "邀请一个朋友",
    forYouAndYour: "为您和您的朋友",
    inviteWithPremium: "邀请一个使用Telegram Premium的朋友",
    moreBonuses: "更多奖励",
    bonusForLevelingUP: "升级奖励",
    level: "等级",
    forFriend: "给朋友",
    premium: "高级",
    listOfFriends: "您的朋友列表",
    earnt1: "观看视频赚取奖励",
    earn1: "通过观看视频完成YouTube任务，并获得参与奖励。",
    earnt2: "每日奖励",
    earn2: "每天登录以在Hamster Kombat中获得令人兴奋的奖励！建立一个例行程序以赚取奖励并加快进度！",
    earnt3: "完成简单任务以获取奖励",
    earn3: "通过完成任务赚取奖励，如加入Telegram、在X上关注、选择一个交易所和邀请3个朋友。",
    earnMoreCoin: "赚取更多硬币",
    hamsterYoutube: "哈姆斯特YouTube",
    dailyTasks: "每日任务",
    tasksList: "任务列表",
    accureCoinsForLogin: "每天登录游戏积累硬币，切勿跳过",
    day: "天",
    check: "检查",
    comeBackTomorrow: "明天再来",
    wait1hour: "等待1小时进行审核检查以领取奖励",
    airt1: "一键复制USDT合约",
    air1: "点击复制USDT的合约，轻松与他人分享。",
    airt2: "USDT价格",
    air2: "在此部分保持更新USDT的当前价格，以便做出明智的交易决策。",
    airt3: "连接您的钱包",
    air3: "使用此按钮轻松连接您的钱包，以实现无缝交易和互动。",
    airt4: "存款金额",
    air4: "查看您当前存款的美元金额，以有效管理您的资金。",
    airt5: "可提取金额",
    air5: "查看您的可提取金额，最低提款限额为10美元。",
    airt6: "当前套餐概览",
    air6: "查看展示所有当前可用用户套餐的表格。",
    airt7: "已完成套餐概览",
    air7: "查看展示所有已完成套餐的表格，以跟踪您的成就。",
    connectYourTonWallet: "连接您的TON钱包",
    currentPackage: "当前套餐",
    date: "日期",
    investedAmount: "投资金额",
    totalReward: "总奖励",
    todayEarning: "今天的收益",
    totalRecived: "总接收",
    completedPackage: "已完成套餐",
    connectYourCryptoWallet: "连接您的加密钱包。如果您没有，请在您的Telegram帐户中创建一个。",
    dipt1: "USDT存款",
    dip1: "输入要存入的USDT金额，确保金额等于或大于10美元。",
    dipt2: "USDT存款按钮",
    dip2: "点击此按钮通过发送交易添加一个套餐，确保您的钱包中至少有0.50 TON以获得有效套餐。",
    depositWith: "使用存款",
    youCanDeposit: "您可以通过Telegram机器人进行存款。选择下面显示的机器人进行无延迟存款。",
    deposit: "存款",
    depositHistory: "存款历史",
    witt1: "USDT提取金额",
    wit1: "输入要提取的金额，确保满足最低要求10美元。",
    witt2: "USDT提取",
    wit2: "点击提取按钮，根据您输入的USDT金额接收USDT。",
    withdrawWith: "使用提取",
    directWithdraw: "通过区块链直接提取可能需要最多10分钟。",
    pleaseBePatient: "请耐心等待！",
    withdrawAmount: "提取金额",
    minimumWithdraw: "最低提取",
    withdrawHistory: "提取历史",
    "level2Friends": "等级 2 朋友",
    "level3Friends": "等级 3 朋友",
    "level4Friends": "等级 4 朋友",
    "level5Friends": "等级 5 朋友",
    "level6Friends": "等级 6 朋友",
    "level7Friends": "等级 7 朋友",
    "level8Friends": "等级 8 朋友",
    "level9Friends": "等级 9 朋友",
    "level10Friends": "等级 10 朋友",
    "level11Friends": "等级 11 朋友",
    "level12Friends": "等级 12 朋友",
    "level13Friends": "等级 13 朋友",
    "level14Friends": "等级 14 朋友",
    "level15Friends": "等级 15 朋友"
}

export { EnglishWords, HindiWords, gujaratiWords, russianWords, urduWords, chineseWords }