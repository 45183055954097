import React, { useContext, useEffect, useState } from 'react'
import { levelMinPoints, levelNames } from '../Helper/Units'
import BinanceLogo from '../Images/binance-logo.png'
import dollarCoin from '../Images/dollar-coin.png'
import { IoMdInformationCircle, IoMdLock } from 'react-icons/io'
import { IoSettingsSharp } from 'react-icons/io5'
import BottomNavigation from '../Components/BottomNavigation'
import firebaseApp from '../Firebase/firebase'
import { FaLock } from 'react-icons/fa'
import { context } from '../Context/UserContext'
import { RxCross1 } from 'react-icons/rx'
import { makeid } from '../Helper/helper'
import { GoDot, GoDotFill } from 'react-icons/go'
import questionMark from '../Images/question-mark.png'
import moment from 'moment'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { BiTimer } from 'react-icons/bi'
import millify from 'millify'
import BtnLoader from '../Components/BtnLoader'
import CoinAnimation from '../Components/CoinAnimation'
import { useNavigate } from 'react-router-dom'
import Loader from '../Components/Loader'
import bybit from '../Images/bybit-logo.png'
import BingX from '../Images/bingX.png'
import htx from '../Images/htx-removebg-preview.png'
import kucoin from '../Images/Kucoin.png'
import okx from '../Images/okx-logo.png'
import mexc from '../Images/mexc-logo.png'
import crypto from '../Images/crypto-logo.png'
import gate from '../Images/gate.png'
import bitget from '../Images/bitget.png'
import mainCh from '../Images/main-character.png'
import boostprofit from '../Images/boost-profit.png'
import Countdown from 'react-countdown'
import toast from 'react-hot-toast'
import comboComplete from '../Images/comboComplete.png'
import { MdOutlineTimer } from 'react-icons/md'
import axios from 'axios'
import { encryptData } from '../encrypt'
import { driver } from 'driver.js'

const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
        return <div className="timer">Completed</div>;
    }
    // const returnRemainingTime = (mineId) => {
    //     const obj = userData.waitingList.find((obj) => obj.mineId == mineId)
    //     if (obj) {
    //         let completeAt = obj.time + (Number(obj.completionTime) * 60 * 1000)
    //         let remainTime = completeAt - Date.now()
    //         const duration = moment.duration(remainTime);

    //         // Format the duration as hh:mm:ss
    //         const formattedTime = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
    //         return duration.asSeconds()
    //         // return Number(obj.completionTime) * 60
    //     }
    // }

    const returnInMinuteFormat = () => {
        const duration = moment.duration(remainingTime * 1000)
        const formatedTime = moment.utc(duration.asMilliseconds()).format('HH:mm:ss')
        return formatedTime
    }
    return (
        <div className="timer">
            <div className="text-center"><BiTimer style={{ fontSize: 15 }} /> </div>
            <div className="value">{returnInMinuteFormat()}</div>
        </div>
    );
};

export default function Mine() {

    const [showButtonLoader, setShowButtonLoader] = useState(false)
    const [TimeCompleted, setTimeCompleted] = useState(true)
    const [levelIndex, setLevelIndex] = useState(0)
    const [currentTab, setCurrentTab] = useState(0)
    const [minordata, setMinorData] = useState([])
    const [MineModal, setMineModal] = useState(false)
    const [specialModal, setSpecialModal] = useState(false)
    const [ProfitModal, setProfitModal] = useState(false)
    const [currentMineData, setCurrentMineData] = useState({})
    const [currentSpecialData, setCurrentSpecialData] = useState({})
    const [dailyCombo, setDailyCombo] = useState([])
    // const [points, setPoints] = useState(0)
    const { userData, setUserData, getUserData, userName, showLoaderContext, offlineReward, points, setPoints, powerObj, setPowerObj, remainingPower, setRemainingPower, langJson } = useContext(context)
    const [achivedDailyCombo, setAchivedDailyCombo] = useState([])
    const [coinAnimation, setcoinAnimation] = useState(false)
    const [comboModel, setComboModel] = useState(false)
    const navigate = useNavigate()
    const [showLoader, setShowLoader] = useState(true)
    const [specialData, setSpecialData] = useState([])
    const [specialNav, setSpecialNav] = useState(0)
    const [comboStartTime, setComboStartTime] = useState('')
    // const [points, setPoints] = useState(0)

    const showComboModal = () => {
        if (!userData?.comboAchive) {
            setComboModel(true)
        }
    }

    const closeComboModal = () => {
        if (!showButtonLoader) {
            setShowButtonLoader(true)
            closeMineModal()
            plusPointsToDatabase()
        }
    }

    const PlusDollarInUser = () => {
        console.log("Plus Dollar In User Function")
        const db = firebaseApp.firestore();
        db.collection('Users').where("id", "==", localStorage.getItem('userid')).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                let investAmount = Number(doc.data().myDeposit)
                let dollarToPlus = investAmount / 100

                var updateCollection = db.collection("Users").doc(doc.ref.id);
                return updateCollection.update({
                    myBalance: Number(doc.data().myBalance) + Number(dollarToPlus)
                })
                    .then(() => {
                        console.log("then of plus dollar in user and dollar TO Plus", dollarToPlus)
                        if (doc.data().refId !== "") {
                            console.log("inside if userRefId")
                            UpdateDollarInReferal(investAmount, doc.data().refId)
                        } else {
                            console.log("Inside Else userRefId")
                            UpdatePackageAndTotalGet("from Else of plus dollar in user")
                        }
                    })
                    .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                    });
            })
        }).catch(err => {
            console.error(err)
        });
    }

    const UpdateDollarInReferal = (investAmount, id) => {
        console.log("Update Dollar In Referal fucnction invest Amount :", investAmount)
        const dollarToPlus = (Number(investAmount) * 0.33) / 100
        const db = firebaseApp.firestore();
        db.collection('Users').where("id", "==", id).get().then((querySnapshot) => {
            if (querySnapshot.empty) {
                UpdatePackageAndTotalGet("Ref Not Found")
            } else {
                querySnapshot.forEach((doc) => {
                    var updateCollection = db.collection("Users").doc(doc.ref.id);
                    let friends = doc.data().myFriends
                    let currentUser = friends?.find((obj) => obj.friendId == localStorage.getItem('userid'))
                    let index = friends?.find((obj) => obj.friendId == localStorage.getItem('userid'))
                    currentUser.friendReward = Number(currentUser.friendReward ? currentUser.friendReward : 0) + Number(dollarToPlus)
                    friends[index] = currentUser
                    if (doc.data().myDeposit > 0) {
                        return updateCollection.update({
                            myBalance: Number(doc.data().myBalance) + Number(dollarToPlus),
                            myFriends: friends
                        })
                            .then(() => {
                                console.log("inside then of update dollar in referal", dollarToPlus)
                                UpdateRefPackage(dollarToPlus, id)
                            })
                            .catch((error) => {
                                // The document probably doesn't exist.
                                console.error("Error updating document: ", error);
                            });
                    } else {
                        UpdatePackageAndTotalGet("else")
                    }
                })
            }
        }).catch(err => {
            console.error(err)
        });
    }

    const UpdateRefPackage = (dollarToPlus, id) => {
        let dollar = dollarToPlus
        console.log("inside updateRefPackage", dollarToPlus);
        const db = firebaseApp.firestore();
        const userRefId = localStorage.getItem('userRefId');
        const userId = localStorage.getItem('userid');

        db.collection('Package')
            .where("userid", "==", id)
            .where('isPackageCompleted', '==', false)
            .orderBy('investAmount')
            .get()
            .then((querySnapshot) => {
                let isCompleted = false;
                let arr = [];
                let docRefId = [];

                querySnapshot.forEach((doc) => {
                    arr.push(doc.data());
                    docRefId.push(doc.ref.id);
                });

                console.log("after querysnapshot", arr, docRefId);

                for (let i = 0; i < arr.length; i++) {
                    if (!isCompleted) {
                        let PendingAmount = Number(arr[i].totalReward) - Number(arr[i].totalGet);
                        console.log("Pending Amount", PendingAmount);

                        let earning = arr[i].Earning;
                        let earningObj = {
                            id: makeid(10),
                            date: Date.now(),
                            amount: PendingAmount > dollar ? dollar : PendingAmount,
                            earningType: "Combo Refer",
                            earningFrom: userId
                        };

                        earning.push(earningObj);
                        var updateCollection = db.collection("Package").doc(docRefId[i]);

                        if (PendingAmount > dollar) {
                            console.log("Pending amount is less than dollar to plus");
                            isCompleted = true;

                            updateCollection.update({
                                totalGet: Number(arr[i].totalGet) + dollar,
                                Earning: earning
                            })
                                .then(() => {
                                    console.log("total get and earning is updated", earning, Number(arr[i].totalGet) + dollar);
                                    UpdatePackageAndTotalGet("From referal function");
                                })
                                .catch((error) => {
                                    console.error("Error updating document: ", error);
                                });
                        } else {
                            console.log("inside else");
                            if (i == arr.length - 1) {
                                let otherBalance = Number(dollarToPlus) - Number(PendingAmount)
                                updateCollection.update({
                                    totalGet: arr[i].totalReward,
                                    Earning: earning,
                                    isPackageCompleted: true
                                }).then(() => {
                                    MinusBalanceFromUserAccount(otherBalance, id, arr[i].investAmount)
                                })
                                    .catch((error) => {
                                        console.error("Error updating document: ", error);
                                    });
                                console.log(otherBalance)
                            } else {
                                updateCollection.update({
                                    totalGet: arr[i].totalReward,
                                    Earning: earning,
                                    isPackageCompleted: true
                                })
                                    .then(() => {
                                        console.log("after complete small package");
                                        removeDepositFromUser(arr[i].investAmount, id);
                                        dollar = dollar - PendingAmount
                                    })
                                    .catch((error) => {
                                        console.error("Error updating document: ", error);
                                    });
                            }
                        }
                    } else {
                        return;  // Break the loop after the first update
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const MinusBalanceFromUserAccount = (balance, id, investAmount) => {
        const db = firebaseApp.firestore();
        db.collection('Users').where("id", "==", id).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                var updateCollection = db.collection("Users").doc(doc.ref.id);
                let friends = doc.data().myFriends
                let index = friends.findIndex((obj) => obj.friendId == localStorage.getItem('userid'))
                friends[index].friendReward = Number(friends[index].friendReward) - Number(balance)
                return updateCollection.update({
                    myBalance: Number(doc.data().myBalance) - Number(balance),
                    myFriends: friends
                })
                    .then(() => {
                        removeDepositFromUser(investAmount, id);

                        UpdatePackageAndTotalGet("From referal function");
                    })
                    .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                    });
            })
        }).catch(err => {
            console.error(err)
        });
    }

    const removeDepositFromUser = (amount, id) => {
        console.log("remove deposit from user")
        const db = firebaseApp.firestore();
        db.collection('Users').where("id", "==", id).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                var updateCollection = db.collection("Users").doc(doc.ref.id);

                return updateCollection.update({
                    myDeposit: Number(doc.data().myDeposit) - Number(amount)
                })
                    .then(() => {
                        console.log("Pending Deposit", Number(doc.data().myDeposit) - Number(amount))
                    })
                    .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                    });
            })
        }).catch(err => {
            console.error(err)
        });
    }

    useEffect(() => {
        if (userData?.achivedCombo?.length >= 3 && !userData?.comboAchive) {
            showComboModal()
        }
    }, [userData])


    const UpdatePackageAndTotalGet = (log) => {
        console.log(log)
        let totalDollar = 0
        const db = firebaseApp.firestore();
        db.collection('Package').where("userid", "==", localStorage.getItem('userid')).where('isPackageCompleted', "==", false).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                var updateCollection = db.collection("Package").doc(doc.ref.id);
                let PendingAmount = Number(doc.data().totalReward) - Number(doc.data().totalGet)
                let dollarToPlus = 0
                let investAmount = doc.data().investAmount
                dollarToPlus = investAmount / 100
                totalDollar = totalDollar + Number(dollarToPlus)
                let earning = doc.data().Earning
                console.log("earning,pendingAmount,dollarToPlus,investAmount", earning, PendingAmount, dollarToPlus, investAmount)
                if (PendingAmount > dollarToPlus) {
                    console.log("pending amount is greater then dollar to plus")
                    let obj = {
                        id: makeid(10),
                        date: Date.now(),
                        amount: dollarToPlus,
                        earningType: "Combo task",
                    }
                    earning.push(obj)
                    return updateCollection.update({
                        totalGet: Number(doc.data().totalGet) + Number(dollarToPlus),
                        Earning: earning
                    })
                        .then(() => {
                            console.log(earning, Number(doc.data().totalGet) + Number(dollarToPlus))
                            getUserData()
                            setComboModel(false)
                            setShowButtonLoader(false)
                        })
                        .catch((error) => {
                            // The document probably doesn't exist.
                            console.error("Error updating document: ", error);
                        });
                } else {
                    let obj = {
                        id: makeid(10),
                        date: Date.now(),
                        amount: Number(doc.data().totalReward),
                        earningType: "task",
                    }
                    earning.push(obj)
                    return updateCollection.update({
                        totalGet: Number(doc.data().totalReward),
                        Earning: earning,
                        isPackageCompleted: true
                    })
                        .then(() => {
                            console.log("small package is completed")
                            removeDepositFromUser(doc.data().investAmount, localStorage.getItem('userid'))
                            getUserData()
                            setComboModel(false)
                            setShowButtonLoader(false)
                        })
                        .catch((error) => {
                            // The document probably doesn't exist.
                            console.error("Error updating document: ", error);
                        });
                }
            })
        }).catch(err => {
            console.error(err)
        });
    }


    const img = userData?.ExchangeName == 'Binance' ? BinanceLogo : userData?.ExchangeName == 'OKX' ? okx : userData?.ExchangeName == 'Crypto.com' ? crypto : userData?.ExchangeName == 'Bybit' ? bybit : userData?.ExchangeName == 'BingX' ? BingX : userData?.ExchangeName == 'HTX' ? htx : userData?.ExchangeName == 'Kucoin' ? kucoin : userData?.ExchangeName == 'Gate.io' ? gate : userData?.ExchangeName == 'MEXC' ? mexc : userData?.ExchangeName == 'Bitget' ? bitget : mainCh

    const [lastTime, setLastTime] = useState('')

    // useEffect(() => {
    //     if (lastTime != '') {
    //         if ((Number(lastTime) + 60000) <= Date.now()) {
    //             setLastTime("")
    //             handleClickInDB()
    //         }
    //     } else {
    //         setLastTime(Date.now())
    //     }
    // }, [points]);

    useEffect(() => {
        let intervalId;
        let accumulatedPoints = 0;

        intervalId = setInterval(() => {
            const pointsPerSecond = userData?.profitPerHour / 3600; // 3600 seconds in an hour
            accumulatedPoints += pointsPerSecond;

            if (accumulatedPoints >= 1) {
                setPoints(points + Math.floor(accumulatedPoints));
                accumulatedPoints -= Math.floor(accumulatedPoints);
            }
        }, 1000); // 1000 milliseconds in a second
        return () => clearInterval(intervalId);
    }, [points]);

    useEffect(() => {
        getMinorData()
        getDailyCombo()
        // getSpecialData()
        if (window.Telegram.WebApp) {
            const tg = window.Telegram.WebApp;

            // Initialize the backButton
            tg.BackButton.show();
            tg.BackButton.onClick(() => {
                // Handle back button click
                navigate('/')
                tg.BackButton.hide();
            });

            // Cleanup on component unmount
            return () => {
                tg.BackButton.offClick();
                tg.BackButton.hide();
            };
        }

    }, [])

    const showCoinAnimation = () => {
        if (JSON.parse(localStorage.getItem('coinAnimation'))) {
            setcoinAnimation(true)
        }
        setTimeout(() => {
            setcoinAnimation(false)
        }, 900);
    }


    useEffect(() => {
        setAchivedDailyCombo(userData?.achivedCombo)
        setLevelIndex(Number(userData?.lvl))
    }, [userData])

    const driverObj = driver({
        showProgress: true,
        steps: [
            {
                element: "#dailyCombo",
                popover: {
                    title: langJson.minet1,
                    description: langJson.mine1,
                }
            },
            {
                element: "#mine1",
                popover: {
                    title: langJson.minet2,
                    description: langJson.mine2,
                }
            },
            {
                element: "#lockmine1",
                popover: {
                    title: langJson.minet3,
                    description: langJson.mine3,
                }
            },
        ]
    })

    useEffect(() => {
        if (!localStorage.getItem('mineGuide')) {
            driverObj.drive()
            localStorage.setItem('mineGuide', 'true')
        }
    }, [minordata])




    const getDailyCombo = () => {
        axios.get(`${process.env.REACT_APP_API_URL}` + '/task/get-today-combo')
            .then((res) => {
                if (res.status == 200) {
                    setDailyCombo(res.data.task)
                    setComboStartTime(res.data.date)
                }
            })
    }

    const showMineModal = () => {
        setMineModal(true)
    }

    const closeMineModal = () => {
        setMineModal(false)
    }

    const showSpecialModal = () => {
        setSpecialModal(true)
    }

    const closeSpecialModal = () => {
        setSpecialModal(false)
    }


    const showProfitModal = () => {
        setProfitModal(true)
    }

    const closeProfitModal = () => {
        setProfitModal(false)
    }

    const getMinorData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}` + '/miner/get-miner')
            .then((res) => {
                if (res.status == 200) {
                    setMinorData(res.data)
                    setShowLoader(false)
                }
            })
    }

    // const getSpecialData = () => {
    //     let x = [];
    //     const db = firebaseApp.firestore();
    //     setShowLoader(true); // Start loader before fetching data

    //     db.collection("Specials")
    //         .orderBy('date')
    //         .get()
    //         .then((querySnapshot) => {
    //             if (querySnapshot.size === 0) {
    //                 setShowLoader(false); // Stop loader if no documents found
    //                 return;
    //             }

    //             let promises = [];

    //             querySnapshot.forEach((doc) => {
    //                 let obj = doc.data();
    //                 let updateCollection = db.collection("Specials").doc(doc.ref.id);

    //                 if (Number(obj.date) + (Number(obj.timeToStay) + 60 * 60 * 1000) <= Date.now() && !doc.data().isPermanent) {
    //                     obj.isTimeCompleted = true;

    //                     let updatePromise = updateCollection.update({
    //                         isTimeCompleted: true
    //                     }).then(() => {
    //                         x.push(obj);
    //                     }).catch((error) => {
    //                         console.error("Error updating document: ", error);
    //                     });

    //                     promises.push(updatePromise);
    //                 } else {
    //                     x.push(obj);
    //                 }
    //             });

    //             Promise.all(promises).then(() => {
    //                 setSpecialData(x);
    //                 setShowLoader(false);
    //             }).catch((error) => {
    //                 console.log("Error processing documents: ", error);
    //                 setShowLoader(false);
    //             });
    //         })
    //         .catch((error) => {
    //             console.log("Error getting documents: ", error);
    //             setShowLoader(false);
    //         });
    // };

    const findCondition = (lvl, id) => {
        if (id == 'inviteFriend') {
            if (userData?.myFriends?.length >= Number(lvl)) {
                return true
            } else {
                return false
            }
        } else {
            let machineObj = userData.myMiners.find((obj) => obj.mineId == id)
            if (lvl <= machineObj?.level) {
                return true
            } else {
                return false
            }
        }
    }

    const getMinerName = (id) => {
        const minerData = minordata?.find((obj) => obj.id == id)
        return minerData?.name
    }

    const getSpecialName = (id) => {
        const minerData = specialData?.find((obj) => obj.id == id)
        return minerData?.name
    }


    const returnLevel = (mineId) => {
        let obj = userData?.myMiners?.find((obj) => obj.mineId == mineId)
        if (obj) {

            return obj.level
        } else {
            return 0
        }
    }

    const returnSpecialLvl = (specialId) => {
        let obj = userData?.specialCard?.find((obj) => obj.specialId == specialId)
        if (obj) {
            return obj.level
        } else {
            return 0
        }
    }

    const returnHourlyIncome = (id, lvl) => {
        let userMiner = userData.myMiners
        let hourlyIncome = 0

        let obj = userMiner.find((obj) => obj?.mineId == id)
        if (obj) {
            for (let i = 0; i < lvl.length; i++) {
                if (lvl[i].lvlNo < obj.level) {
                    hourlyIncome = hourlyIncome + Number(lvl[i].hourlyIncome)
                }
            }
        } else {
            hourlyIncome = lvl[0]?.hourlyIncome
        }
        return millify(hourlyIncome)
    }

    const returnSpecialHourlyIncome = (id, lvl) => {
        let userMiner = userData.specialCard
        let hourlyIncome = 0

        let obj = userMiner.find((obj) => obj?.specialId == id)
        if (obj) {
            for (let i = 0; i < lvl.length; i++) {
                if (lvl[i].lvlNo < obj.level) {
                    hourlyIncome = hourlyIncome + Number(lvl[i].hourlyIncome)
                }
            }
        } else {
            hourlyIncome = lvl[0]?.hourlyIncome
        }
        return hourlyIncome
    }

    const returnUpCost = (id, lvl, param) => {
        const object = userData.myMiners.find((obj) => obj.mineId == id)
        if (lvl.length - 1 <= (object ? Number(object?.level) : 0)) {
            return "You own this mine"
        } else {
            const userMiner = userData.myMiners
            let obj = userMiner.find((obj) => obj.mineId == id)
            let nextLvl = lvl.find((item) => item.lvlNo == obj.level)
            if (param) {
                return (Number(nextLvl?.upCost))
            } else {
                return millify(Number(nextLvl?.upCost))
            }
        }
    }

    const returnSpecialUpCost = (id, lvl) => {
        const object = userData?.specialCard?.find((obj) => obj.specialId == id)
        if (lvl.length - 1 <= (object ? Number(object?.level) : 0)) {
            return "Max Level"
        } else {
            const userMiner = userData?.specialCard
            let obj = userMiner.find((obj) => obj.specialId == id)
            let nextLvl = lvl.find((item) => item.lvlNo == obj.level)
            return nextLvl?.upCost
        }
    }

    const handleMineShow = (item) => {
        const obj = userData.myMiners.find((obj) => obj.mineId == item.id)
        if ((item?.level.length - 1) <= (obj ? Number(obj?.level) : 0)) {
            console.log("level reached")
        } else {
            showMineModal()
            setCurrentMineData(item)
        }
    }

    const handleSpecialView = (item) => {
        const obj = userData?.specialCard?.find((obj) => obj.specialId == item.id)
        if ((item?.level.length - 1) <= (obj ? Number(obj?.level) : 0)) {
            console.log("level reached")
        } else {
            showSpecialModal()
            setCurrentSpecialData(item)
        }
    }


    const BuyMiner = () => {
        if (!showButtonLoader) {
            setShowButtonLoader(true)
            let insufficentFund = false
            let userMiner = userData.myMiners
            let userHourlyIncome = userData.profitPerHour
            let userPoints = userData.mypoints
            const AdminLvl = currentMineData.level

            const minerObj = userData.myMiners.find((obj) => obj.mineId == currentMineData?.id)
            const minerObjIndex = userData.myMiners.findIndex((obj) => obj.mineId == currentMineData?.id)
            if (minerObj) {
                const currentLevel = AdminLvl.find((obj) => Number(obj.lvlNo) == Number(minerObj.level))
                if (currentLevel.upCost <= userPoints) {
                    if (currentLevel?.timeInMinute <= 0) {
                        userMiner[minerObjIndex].level++
                        userHourlyIncome = Number(userHourlyIncome) + Number(currentLevel.hourlyIncome)
                        userPoints = Number(userPoints) - Number(currentLevel.upCost)
                    } else {
                        userPoints = Number(userPoints) - Number(AdminLvl[0].upCost)
                        let arr = userData.waitingList
                        let obj = {
                            mineId: currentMineData?.id,
                            time: Date.now(),
                            completionTime: currentLevel.timeInMinute,
                            id: makeid(10)
                        }
                        arr.push(obj)
                        addMineToWaitingList(arr)
                    }
                    if (IncludeInDailyCombo(currentMineData?.id)) {

                        let achivedData = userData.achivedCombo
                        achivedData.push(currentMineData?.id)
                        addComboToDB(achivedData)
                    }
                } else {
                    insufficentFund = true
                }
            } else {
                if (userPoints >= Number(AdminLvl[0].upCost)) {
                    if (AdminLvl[0]?.timeInMinute <= 0) {
                        let obj = {
                            id: makeid(10),
                            mineId: currentMineData?.id,
                            level: 1
                        }
                        userMiner.push(obj)
                        userHourlyIncome = userHourlyIncome + Number(AdminLvl[0].hourlyIncome)
                        userPoints = userPoints - Number(AdminLvl[0].upCost)
                        console.log("here", userMiner, userHourlyIncome, userPoints)
                    } else {
                        userPoints = Number(userPoints) - Number(AdminLvl[0].upCost)
                        let arr = userData.waitingList
                        let obj = {
                            mineId: currentMineData?.id,
                            time: Date.now(),
                            completionTime: AdminLvl[0].timeInMinute,
                            id: makeid(10)
                        }
                        arr.push(obj)
                        addMineToWaitingList(arr)
                    }
                    if (IncludeInDailyCombo(currentMineData?.id)) {
                        let achivedData = userData.achivedCombo
                        achivedData.push(currentMineData?.id)
                        addComboToDB(achivedData)
                    }
                } else {
                    insufficentFund = true
                }
            }
            if (insufficentFund) {
                setShowButtonLoader(false)
                closeMineModal()
                toast.error('Insufficent fund', {
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                        marginTop: "10px"
                    },
                })
            } else {
                updateBuyToDatabase(userMiner, userHourlyIncome, userPoints)
            }
        }
    }

    const BuySpecial = () => {
        if (!showButtonLoader) {
            setShowButtonLoader(true)
            let insufficentFund = false
            let special = userData?.specialCard
            let userHourlyIncome = userData?.profitPerHour
            let userPoints = userData.mypoints
            const AdminLvl = currentSpecialData?.level
            const specialObj = userData?.specialCard.find((obj) => obj.specialId == currentSpecialData?.id)
            const specialObjIndex = userData?.specialCard.findIndex((obj) => obj.specialId == currentSpecialData?.id)
            console.log(specialObj)
            if (specialObj) {
                console.log("in Special Obj")
                const currentLevel = AdminLvl.find((obj) => Number(obj.lvlNo) == Number(specialObj.level))
                if (currentLevel.upCost <= userPoints) {
                    console.log("first")
                    if (currentLevel?.timeInMinute <= 0) {
                        console.log("second")
                        special[specialObjIndex].level++
                        userHourlyIncome = Number(userHourlyIncome) + Number(currentLevel.hourlyIncome)
                        userPoints = Number(userPoints) - Number(currentLevel.upCost)
                    } else {
                        userPoints = Number(userPoints) - Number(AdminLvl[0].upCost)
                        let arr = userData.waitingSpecialList
                        let obj = {
                            specialId: currentSpecialData?.id,
                            time: Date.now(),
                            completionTime: currentLevel.timeInMinute,
                            id: makeid(10)
                        }
                        arr.push(obj)
                        addSpecialToWaitingList(arr)
                    }
                    if (IncludeInDailyCombo(currentSpecialData?.id)) {

                        let achivedData = userData.achivedCombo
                        achivedData.push(currentSpecialData?.id)
                        addComboToDB(achivedData)
                    }
                } else {
                    insufficentFund = true
                }
            } else {
                if (userPoints >= Number(AdminLvl[0].upCost)) {
                    if (AdminLvl[0]?.timeInMinute <= 0) {
                        let obj = {
                            id: makeid(10),
                            specialId: currentSpecialData?.id,
                            level: 1
                        }
                        special.push(obj)
                        userHourlyIncome = Number(userHourlyIncome) + Number(AdminLvl[0].hourlyIncome)
                        userPoints = Number(userPoints) - Number(AdminLvl[0].upCost)
                    } else {
                        userPoints = Number(userPoints) - Number(AdminLvl[0].upCost)
                        let arr = userData.waitingSpecialList
                        let obj = {
                            specialId: currentSpecialData?.id,
                            time: Date.now(),
                            completionTime: AdminLvl[0].timeInMinute,
                            id: makeid(10)
                        }
                        arr.push(obj)
                        addSpecialToWaitingList(arr)
                    }
                    if (IncludeInDailyCombo(currentSpecialData?.id)) {
                        let achivedData = userData?.achivedCombo
                        achivedData.push(currentSpecialData?.id)
                        addComboToDB(achivedData)
                    }
                } else {
                    insufficentFund = true
                }
            }
            if (insufficentFund) {
                setShowButtonLoader(false)
                closeSpecialModal()
                toast.error('Insufficent fund', {
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                        marginTop: "10px"
                    },
                })
            } else {
                console.log("first")
                updateSpecialBuyToDatabase(special, userHourlyIncome, userPoints)
            }
        }
    }

    const addSpecialToWaitingList = (arr) => {
        const db = firebaseApp.firestore();
        db.collection('Users').where("id", "==", localStorage.getItem('userid')).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                var updateCollection = db.collection("Users").doc(doc.ref.id);

                return updateCollection.update({
                    waitingSpecialList: arr
                })
                    .then(() => {
                        getUserData()
                        closeSpecialModal()
                    })
                    .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                    });
            })
        }).catch(err => {
            console.error(err)
        });
    }

    const addComboToDB = (arr) => {
        let obj = {
            id: localStorage.getItem('docRef'),
            achived: arr
        }
        axios.post(`${process.env.REACT_APP_API_URL}` + '/miner/add-combo', { data: encryptData(obj) })
            .then((res) => {
                if (res.status == 200) {
                    setUserData(res.data)
                    closeMineModal()
                    if (arr.length >= 3) {
                        showComboModal()
                    }
                }
            })
        // const db = firebaseApp.firestore();
        // db.collection('Users').where("id", "==", localStorage.getItem('userid')).get().then((querySnapshot) => {
        //     querySnapshot.forEach((doc) => {
        //         var updateCollection = db.collection("Users").doc(doc.ref.id);

        //         return updateCollection.update({
        //             achivedCombo: arr
        //         })
        //             .then(() => {
        //                 console.log("added Combo To DB")
        //                 let user = userData
        //                 user.achivedCombo = arr
        //                 setUserData(user)
        //                 getUserData()
        //                 closeMineModal()
        //                 if (arr.length >= 3) {
        //                     showComboModal()
        //                     setShowButtonLoader(true)
        //                 }
        //             })
        //             .catch((error) => {
        //                 // The document probably doesn't exist.
        //                 console.error("Error updating document: ", error);
        //             });
        //     })
        // }).catch(err => {
        //     console.error(err)
        // });
    }

    const plusPointsToDatabase = () => {
        axios.post(`${process.env.REACT_APP_API_URL}` + '/miner/complete-combo', { id: localStorage.getItem('docRef') })
            .then((res) => {
                if (res.status == 200) {
                    if (userData?.myDeposit > 0) {
                        addEarningToPackage()
                    } else {
                        getUserData()
                        setComboModel(false)
                        setShowButtonLoader(false)
                    }
                }
            })
        // let total = Number(userData?.mypoints) + Number(5000000)
        // console.log(total)
        // const db = firebaseApp.firestore();
        // db.collection('Users').where("id", "==", localStorage.getItem('userid')).get().then((querySnapshot) => {
        //     querySnapshot.forEach((doc) => {
        //         console.log(doc.data())
        //         var updateCollection = db.collection("Users").doc(doc.ref.id);

        //         return updateCollection.update({
        //             mypoints: total,
        //             comboAchive: true
        //         })
        //             .then(() => {
        //                 localStorage.setItem('lastComboTime', comboStartTime)
        //                 let user = userData
        //                 user.comboAchive = true
        //                 setUserData(user)
        //                 if (doc.data().myDeposit > 0) {
        //                     PlusDollarInUser()
        //                 } else {
        //                     getUserData()
        //                     setComboModel(false)
        //                     setShowButtonLoader(false)
        //                 }
        //             })
        //             .catch((error) => {
        //                 // The document probably doesn't exist.
        //                 console.error("Error updating document: ", error);
        //             });
        //     })
        // }).catch(err => {
        //     console.error(err)
        // });
    }

    const addEarningToPackage = () => {
        let obj = {
            id: localStorage.getItem('docRef'),
            type: "Combo"
        }
        axios.post(`${process.env.REACT_APP_API_URL}` + '/task/add-package-earning', { data: encryptData(obj) })
            .then((res) => {
                if (res.status == 200) {
                    getUserData()
                    setComboModel(false)
                    setShowButtonLoader(false)
                }
            })
    }


    const handleBuyAfterTime = (id, lvl) => {
        console.log(id, lvl)
        setTimeCompleted(false)
        let userMiner = userData.myMiners
        let userHourlyIncome = userData.profitPerHour
        const minerObj = userMiner.find((obj) => obj.mineId == id)
        const minerObjIndex = userMiner.findIndex((obj) => obj.mineId == id)
        if (minerObj) {
            const currentLvl = lvl.find((obj) => obj.lvlNo == minerObj.level)
            if (currentLvl) {
                console.log("first")
                userMiner[minerObjIndex].level++
                userHourlyIncome = userHourlyIncome + Number(currentLvl.hourlyIncome)
            }
        } else {
            let obj = {
                id: makeid(10),
                mineId: id,
                level: 1
            }
            userMiner.push(obj)
            userHourlyIncome = userHourlyIncome + Number(lvl[0].hourlyIncome)
        }
        removeFromWaitingList(id, userMiner, userHourlyIncome, userData.mypoints)
    }

    const handleBuyAfterSpecialTime = (id, lvl) => {
        setTimeCompleted(false)
        let userMiner = userData.specialCard
        let userHourlyIncome = userData.profitPerHour
        const minerObj = userMiner.find((obj) => obj.specialId == id)
        const minerObjIndex = userMiner.findIndex((obj) => obj.specialId == id)
        if (minerObj) {
            const currentLvl = lvl.find((obj) => obj.lvlNo == minerObj.level)
            if (currentLvl) {
                userMiner[minerObjIndex].level++
                userHourlyIncome = userHourlyIncome + Number(currentLvl.hourlyIncome)
                removeSpecialFromWaitingList(id)
            }
        } else {
            let obj = {
                id: makeid(10),
                specialId: id,
                level: 1
            }
            userMiner.push(obj)
            userHourlyIncome = userHourlyIncome + Number(lvl[0].hourlyIncome)
            removeSpecialFromWaitingList(id)
        }
        updateSpecialBuyToDatabase(userMiner, userHourlyIncome, userData.mypoints)
    }

    const removeSpecialFromWaitingList = (id) => {
        let minerWaiting = userData?.waitingSpecialList.filter((obj) => obj.specialId !== id)
        const db = firebaseApp.firestore();
        db.collection('Users').where("id", "==", localStorage.getItem('userid')).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                var updateCollection = db.collection("Users").doc(doc.ref.id);

                return updateCollection.update({
                    waitingSpecialList: minerWaiting
                })
                    .then(() => {
                        getUserData()
                        closeSpecialModal()
                    })
                    .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                    });
            })
        }).catch(err => {
            console.error(err)
        });
    }

    const removeFromWaitingList = (id, userMiner, userHourlyIncome, mypoints) => {
        let minerWaiting = userData.waitingList.filter((obj) => obj.mineId !== id)
        let obj = {
            id: localStorage.getItem('docRef'),
            minerWaiting: minerWaiting,
            userMiner: userMiner,
            userHourlyIncome: userHourlyIncome,
            userPoints: mypoints
        }
        axios.post(`${process.env.REACT_APP_API_URL}` + '/miner/remove-from-waiting', { data: encryptData(obj) })
            .then((res) => {
                if (res.status == 200) {
                    setUserData(res.data)
                    setPoints(mypoints)
                    closeMineModal()
                    setShowButtonLoader(false)
                    showCoinAnimation()
                }
            })
        // const db = firebaseApp.firestore();
        // db.collection('Users').where("id", "==", localStorage.getItem('userid')).get().then((querySnapshot) => {
        //     querySnapshot.forEach((doc) => {
        //         var updateCollection = db.collection("Users").doc(doc.ref.id);

        //         return updateCollection.update({
        //             waitingList: minerWaiting
        //         })
        //             .then(() => {
        //                 let user = userData
        //                 user.waitingList = minerWaiting
        //                 setUserData(user)
        //                 setTimeCompleted(true)
        //                 updateBuyToDatabase(userMiner, userHourlyIncome, mypoints)
        //                 closeMineModal()
        //             })
        //             .catch((error) => {
        //                 // The document probably doesn't exist.
        //                 console.error("Error updating document: ", error);
        //             });
        //     })
        // }).catch(err => {
        //     console.error(err)
        // });
    }

    const addMineToWaitingList = (arr) => {
        let obj = {
            id: localStorage.getItem('docRef'),
            waiting: arr
        }
        axios.post(`${process.env.REACT_APP_API_URL}` + '/miner/add-to-waiting', { data: encryptData(obj) })
            .then((res) => {
                if (res.status == 200) {
                    setUserData(res.data)
                    closeMineModal()
                }
            })
        // const db = firebaseApp.firestore();
        // db.collection('Users').where("id", "==", localStorage.getItem('userid')).get().then((querySnapshot) => {
        //     querySnapshot.forEach((doc) => {
        //         var updateCollection = db.collection("Users").doc(doc.ref.id);

        //         return updateCollection.update({
        //             waitingList: arr
        //         })
        //             .then(() => {
        //                 getUserData()
        //                 closeMineModal()
        //             })
        //             .catch((error) => {
        //                 // The document probably doesn't exist.
        //                 console.error("Error updating document: ", error);
        //             });
        //     })
        // }).catch(err => {
        //     console.error(err)
        // });
    }

    const updateBuyToDatabase = (userMiner, userHourlyIncome, userPoints) => {
        let obj = {
            id: localStorage.getItem('docRef'),
            userMiner: userMiner,
            userHourlyIncome: userHourlyIncome,
            userPoints: userPoints
        }
        axios.post(`${process.env.REACT_APP_API_URL}` + '/miner/buy-miner', { data: encryptData(obj) })
            .then((res) => {
                if (res.status == 200) {
                    getUserData()
                    setPoints(userPoints)
                    closeMineModal()
                    setShowButtonLoader(false)
                    showCoinAnimation()
                }
            })
        // const db = firebaseApp.firestore();
        // db.collection('Users').where("id", "==", localStorage.getItem('userid')).get().then((querySnapshot) => {
        //     querySnapshot.forEach((doc) => {
        //         var updateCollection = db.collection("Users").doc(doc.ref.id);

        //         return updateCollection.update({
        //             myMiners: userMiner,
        //             profitPerHour: userHourlyIncome,
        //             mypoints: userPoints
        //         })
        //             .then(() => {
        //                 // getUserData()
        //                 let user = userData
        //                 user.myMiners = userMiner
        //                 user.profitPerHour = userHourlyIncome
        //                 user.mypoints = userPoints
        //                 setUserData(user)
        //                 setPoints(userPoints)
        //                 closeMineModal()
        //                 setShowButtonLoader(false)
        //                 showCoinAnimation()
        //             })
        //             .catch((error) => {
        //                 // The document probably doesn't exist.
        //                 console.error("Error updating document: ", error);
        //             });
        //     })
        // }).catch(err => {
        //     console.error(err)
        // });
    }

    const timerCondition = (item) => {
        if (item.isPermanent) {
            return false
        } else {
            if (Number(item.date) + (Number(item.timeToStay) * 60 * 60 * 1000) >= Date.now()) {
                return true
            } else {
                return false
            }
        }
    }

    const updateSpecialBuyToDatabase = (userMiner, userHourlyIncome, userPoints) => {
        const db = firebaseApp.firestore();
        db.collection('Users').where("id", "==", localStorage.getItem('userid')).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                var updateCollection = db.collection("Users").doc(doc.ref.id);

                return updateCollection.update({
                    specialCard: userMiner,
                    profitPerHour: userHourlyIncome,
                    mypoints: userPoints
                })
                    .then(() => {
                        setPoints(userPoints)
                        console.log('then')
                        getUserData()
                        closeSpecialModal()
                        setShowButtonLoader(false)
                        showCoinAnimation()
                    })
                    .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                    });
            })
        }).catch(err => {
            console.error(err)
        });
    }

    const IncludeInMiner = (id) => {
        let obj = userData?.myMiners?.find((obj) => obj.mineId == id)
        if (obj) {
            return true
        } else {
            return false
        }
    }

    const IncludeSpecialCard = (id) => {
        let obj = userData?.specialCard?.find((obj) => obj.specialId == id)
        if (obj) {
            return true
        } else {
            return false
        }
    }

    const returnNextHourlyIncome = (id, lvl) => {
        const object = userData.myMiners.find((obj) => obj.mineId == currentMineData.id)
        if (currentMineData?.level.length - 1 <= (object ? Number(object?.level) : 0)) {

        } else {
            const userMiner = userData.myMiners
            let obj = userMiner.find((obj) => obj.mineId == id)
            let nextLvl = lvl.find((item) => item.lvlNo == obj.level)
            return nextLvl?.hourlyIncome
        }
    }

    const returnSpecialNextHourlyIncome = (id, lvl) => {
        const object = userData.specialCard.find((obj) => obj.specialId == currentSpecialData?.id)
        if (currentSpecialData?.level.length - 1 <= (object ? Number(object?.level) : 0)) {

        } else {
            const userMiner = userData.specialCard
            let obj = userMiner.find((obj) => obj.specialId == id)
            let nextLvl = lvl.find((item) => item.lvlNo == obj.level)
            return nextLvl?.hourlyIncome
        }
    }

    const IncludeInWaitingList = (mineId) => {
        const obj = userData.waitingList?.find((obj) => obj.mineId == mineId)
        if (obj) {
            return true
        } else {
            return false
        }
    }

    const IncludeInSpecialWaitingList = (specialId) => {
        const obj = userData?.waitingSpecialList?.find((obj) => obj.specialId == specialId)
        if (obj) {
            return true
        } else {
            return false
        }
    }


    const returnDuration = (mineId) => {
        const obj = userData.waitingList.find((obj) => obj.mineId == mineId)
        if (obj) {
            let completeAt = obj.time + (Number(obj.completionTime) * 1000)
            let remainTime = completeAt - Date.now()
            const duration = moment.duration(remainTime);

            // Format the duration as hh:mm:ss
            const formattedTime = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
            return duration.asSeconds()
            // return Number(obj.completionTime) * 60
        }
    }

    const returnSpecialDuration = (id) => {
        const obj = userData?.waitingSpecialList?.find((obj) => obj.specialId == id)
        if (obj) {
            let completeAt = obj.time + (Number(obj.completionTime) * 1000)
            let remainTime = completeAt - Date.now()
            const duration = moment.duration(remainTime);

            // Format the duration as hh:mm:ss
            const formattedTime = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
            return duration.asSeconds()
        }
    }

    const IncludeInDailyCombo = (id) => {
        const obj = dailyCombo?.find((obj) => obj.value == id)
        const achived = achivedDailyCombo?.find((item) => item == id)
        console.log(achivedDailyCombo)
        let achiveBool = false
        if (achived) {
            achiveBool = false
        } else {
            achiveBool = true
        }
        if (obj && achiveBool) {
            return true
        } else {
            return false
        }
    }

    const returnImgUrl = (id) => {
        const minerObj = minordata.find((obj) => obj.id == id)
        const specialObj = specialData.find((obj) => obj.id == id)
        if (minerObj) {
            return minerObj?.img
        } else if (specialObj) {
            return specialObj?.img
        }
    }

    const calculateProgress = () => {
        if (levelIndex >= levelNames.length - 1) {
            return 100;
        }
        const currentLevelMin = levelMinPoints[levelIndex];
        const nextLevelMin = levelMinPoints[levelIndex + 1];
        const progress = ((points - currentLevelMin) / (nextLevelMin - currentLevelMin)) * 100;
        if (progress < 0) {
            return 0
        } else {
            return Math.min(progress, 100);
        }
    };

    const getNextUpdateTime = () => {
        const now = new Date();
        const nextUpdate = new Date();

        // Set next update time to 5:30
        nextUpdate.setHours(5, 30, 0, 0);

        // If it's already past 5:30 today, set next update to 5:30 tomorrow
        if (now > nextUpdate) {
            nextUpdate.setDate(nextUpdate.getDate() + 1);
        }

        return nextUpdate;
    }

    const updateCountdown = () => {
        const now = new Date();
        const nextUpdate = getNextUpdateTime();
        const timeRemaining = nextUpdate - now;

        return timeRemaining
        // const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        // const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
        // const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
    }
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
        } else {
            // Render a countdown
            return <span>{hours}:{minutes}:{seconds}</span>;
        }
    };

    const specialRender = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
        } else {
            // Render a countdown
            return <span>{(days * 24) + hours}:{minutes}:{seconds}</span>;
        }
    };

    const isSpecialLocked = (item) => {
        if (item.condition.isRequire) {
            if (item.condition.requiredMiner == 'inviteFriend') {
                if (item.condition.requiredLvl <= userData?.myFriends?.length) {
                    return false
                } else {
                    return true
                }
            } else {
                let specialObj = userData?.specialCard?.find((obj) => obj.specialId == item.condition.requiredMiner)
                if (item.condition.requiredLvl <= specialObj?.level) {
                    return false
                } else {
                    return true
                }
            }
        } else {
            return false
        }
    }

    const isAffordable = (price) => {
        // console.log(price)
        if (points > price) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            {showLoader ? <Loader /> : ""}
            {coinAnimation ? <CoinAnimation /> : ""}
            <div className="bg-black d-flex justify-content-center" style={{ height: "100vh" }}>
                <div className="w-100 bg-black text-white h-screen font-bold d-flex flex-column max-w-xl">
                    <div className="d-flex align-items-center justify-content-between gap-4 mt-1 px-4">
                        <div className="d-flex align-items-center level-section">
                            <div className="w-100">
                                <div className="d-flex justify-content-between">
                                    <p className="text-sm">{levelNames[levelIndex]}</p>
                                    <p className="text-sm">{levelIndex + 1} <span className="dull-text">/ {levelNames.length}</span></p>
                                </div>
                                <div className="d-flex algin-items-center mt-1 progress-border rounded-full">
                                    <div className="w-100 progress-bg rounded-full">
                                        <div className="progress-gradient rounded-full" style={{ width: calculateProgress() }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center pph-section rounded-full px-2">
                            <img src={img} alt="binance-logo" className='binance-logo mx-2' />
                            <div className="pph-devider mx-2"></div>
                            <div className="flex-1 text-center">
                                <div className="pph-text">{langJson.profitPerHour}</div>
                                <div className="d-flex align-items-center justify-content-center pt-1 gap-1">
                                    <img src={dollarCoin} alt="dollarCoin" className='dollar-coin' />
                                    <p className="text-sm">+{millify(userData.profitPerHour)}
                                        <IoMdInformationCircle className='dull-text ms-1' onClick={() => showProfitModal()} style={{ fontSize: 17 }} />
                                    </p>
                                </div>
                            </div>
                            <div className="pph-devider mx-2"></div>
                            <IoSettingsSharp style={{ fontSize: 24 }} onClick={() => navigate('/setting')} className='text-white mx-2' />
                        </div>
                    </div>
                    <div className="flex-grow mt-4 top-glow position-relative">
                        <div className="position-absolute left-0 right-0 bottom-10 main-section w-100 p-2">
                            <div className="mt-3 mx-2" id='dailyCombo'>
                                <p className="text-sm dull-text text-end"><Countdown date={Date.now() + updateCountdown()} renderer={renderer} /> <IoMdInformationCircle style={{ fontSize: 17 }} /></p>
                                <div className="daily-combo d-flex justify-content-between p-2 align-items-center">
                                    <p className='text-white'>{langJson.dailyCombo}</p>
                                    <div className="combo-dot">
                                        <GoDotFill style={{ fontSize: 25, color: achivedDailyCombo?.length >= 1 ? "green" : "#53534c" }} />
                                        <GoDotFill style={{ fontSize: 25, color: achivedDailyCombo?.length >= 2 ? "green" : "#53534c" }} />
                                        <GoDotFill style={{ fontSize: 25, color: achivedDailyCombo?.length >= 3 ? "green" : "#53534c" }} />
                                    </div>
                                    <button className={achivedDailyCombo?.length >= 3 ? "black-btn" : "combo-reward-btn"}>
                                        <img src={dollarCoin} alt="" /> + 5,000,000
                                    </button>
                                </div>
                                <div className="px-2">
                                    <div className="mt-3 row">
                                        <div className="col-4">
                                            <div className="combo-question">
                                                <div className="inner-question">
                                                    <img src={achivedDailyCombo?.length >= 1 ? returnImgUrl(achivedDailyCombo[0]) : questionMark} className='img-fluid' alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="combo-question">
                                                <div className="inner-question">
                                                    <img src={achivedDailyCombo?.length >= 2 ? returnImgUrl(achivedDailyCombo[1]) : questionMark} className='img-fluid' alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="combo-question">
                                                <div className="inner-question">
                                                    <img src={achivedDailyCombo?.length >= 3 ? returnImgUrl(achivedDailyCombo[2]) : questionMark} className='img-fluid' alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="px-2 mt-3 d-flex justify-content-between gap-2">
                                <div className='mine-header'>
                                    <div>
                                        <img src={dollarCoin} className='img-fluid' height={30} width={40} alt="no image found" />
                                    </div>
                                    <div className='total-coin'>
                                        <h3 className='font-weight-700'>{Number(points).toLocaleString()}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="mx-2 mt-3 p-1 options">
                                <div className='d-flex justify-content-between gap-0'>
                                    <div className={currentTab == 0 ? 'tab-mine activated-category' : "tab-mine"} onClick={() => setCurrentTab(0)}>
                                        <b>{langJson.prNTeam}</b>
                                    </div>
                                    <div className={currentTab == 1 ? 'tab-mine activated-category' : "tab-mine"} onClick={() => setCurrentTab(1)}>
                                        <b>{langJson.markets}</b>
                                    </div>
                                    <div className={currentTab == 2 ? 'tab-mine activated-category' : "tab-mine"} onClick={() => setCurrentTab(2)}>
                                        <b>{langJson.legal}</b>
                                    </div>
                                    <div className={currentTab == 3 ? 'tab-mine activated-category' : "tab-mine"} onClick={() => setCurrentTab(3)}>
                                        <b>{langJson.web3}</b>
                                    </div>
                                    {/* <div className={currentTab == 4 ? 'tab-mine activated-category' : "tab-mine"} onClick={() => setCurrentTab(4)}>
                                        <b>Specials</b>
                                    </div> */}
                                </div>
                            </div>
                            {currentTab == 0 ?
                                <>
                                    <div className='row mx-2 mt-3 pb-100'>
                                        {minordata.map((item, i) => {
                                            if (Number(item.category) == 0) {
                                                if (IncludeInWaitingList(item.id) && TimeCompleted) {
                                                    return (
                                                        <div className="col-6 mb-2">
                                                            <div className="mining-card">
                                                                <div className="d-flex justify-conent-between gap-2 mb-1 p-2">
                                                                    <div className="miner-img position-relative">
                                                                        <img src={item.img} alt="" />
                                                                        <div className="countdown">
                                                                            <CountdownCircleTimer
                                                                                size={"100%"}
                                                                                isPlaying
                                                                                duration={returnDuration(item.id)}
                                                                                colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                                                                                colorsTime={[10, 6, 3, 0]}
                                                                                onComplete={() => handleBuyAfterTime(item.id, item.level)}
                                                                            >
                                                                                {renderTime}
                                                                            </CountdownCircleTimer>
                                                                        </div>
                                                                    </div>
                                                                    <div className='miner-detail d-block'>
                                                                        <p className='text-sm'>{item.name}</p>
                                                                        <div className='line-height-1'>
                                                                            <span className="dull-text text-small">Profit per hour</span><br />
                                                                            <span className={userData.myMiners.length > 0 && IncludeInMiner(item.id) ? "miner-hourlyIncome text-sm" : "miner-hourlyIncome text-sm dull-text"}><img src={dollarCoin} width={15} alt="" /> {userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnHourlyIncome(item.id, item.level) : " +" + millify(item?.level[0].hourlyIncome)}</span>
                                                                            {/* <p>{returnRemainingTime(item.id)}</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mining-devider"></div>
                                                                <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                    <span className="text-sm text-center">lvl {returnLevel(item.id)}</span>
                                                                    <div className="pph-devider"></div>
                                                                    <p className={isAffordable(userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level, true) : millify(Number(item?.level[0]?.upCost))) ? "ps-2 text-sm m-0" : "ps-2 text-sm m-0 dull-text"}><img src={dollarCoin} className={'me-1'} width={20} alt="" /> {userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level) : millify(Number(item?.level[0].upCost))}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                } else if (item.condition.isRequire == true && userData?.myMiners?.length > 0 && findCondition(item.condition.requiredLvl, item.condition.requiredMiner)) {
                                                    return (
                                                        <div className="col-6 mb-2" id={'mine1'} onClick={() => handleMineShow(item)}>
                                                            <div className="mining-card">
                                                                <div className="d-flex justify-conent-between gap-2 mb-1 p-2">
                                                                    <div className="miner-img">
                                                                        <img src={item.img} alt="" />
                                                                    </div>
                                                                    <div className='miner-detail d-block'>
                                                                        <p className='text-sm'>{item.name}</p>
                                                                        <div className='line-height-1'>
                                                                            <span className="dull-text text-small">Profit per hour</span><br />
                                                                            <span className={userData?.myMiners.length > 0 && IncludeInMiner(item.id) ? "miner-hourlyIncome text-sm" : "miner-hourlyIncome text-sm dull-text"}><img src={dollarCoin} width={15} alt="" /> {userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnHourlyIncome(item.id, item.level) : " +" + millify(item?.level[0]?.hourlyIncome)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mining-devider"></div>
                                                                <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                    <span className="text-sm text-center">lvl {returnLevel(item.id)}</span>
                                                                    <div className="pph-devider"></div>
                                                                    <p className={isAffordable(userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level, true) : millify(Number(item?.level[0]?.upCost))) ? "ps-2 text-sm m-0" : "ps-2 text-sm m-0 dull-text"}><img src={dollarCoin} className='me-1' width={20} alt="" /> {userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level) : millify(item?.level[0].upCost)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                } else if (item.condition.isRequire == false) {
                                                    return (
                                                        <div className="col-6 mb-2" id='mine1' onClick={() => handleMineShow(item)}>
                                                            <div className="mining-card">
                                                                <div className="d-flex justify-conent-between gap-2 mb-1 p-2">
                                                                    <div className="miner-img">
                                                                        <img src={item.img} alt="" />
                                                                    </div>
                                                                    <div className='miner-detail d-block'>
                                                                        <p className='text-sm'>{item.name}</p>
                                                                        <div className='line-height-1'>
                                                                            <span className="dull-text text-small">Profit per hour</span><br />
                                                                            <span className={userData?.myMiners?.length > 0 && IncludeInMiner(item.id) ? "miner-hourlyIncome text-sm" : "miner-hourlyIncome text-sm dull-text"}><img src={dollarCoin} width={15} alt="" /> {userData?.myMiners?.length > 0 && IncludeInMiner(item.id) ? returnHourlyIncome(item.id, item.level) : " +" + millify(item?.level[0]?.hourlyIncome)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mining-devider"></div>
                                                                <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                    <span className="text-sm text-center">lvl {returnLevel(item.id)}</span>
                                                                    <div className="pph-devider"></div>
                                                                    <p className={isAffordable(userData?.myMiners?.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level, true) : Number(item?.level[0]?.upCost)) ? "ps-2 text-sm m-0" : "ps-2 text-sm m-0 dull-text"}><img src={dollarCoin} className='me-1' width={20} alt="" /> {userData?.myMiners?.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level) : millify(item?.level[0]?.upCost)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div className="col-6 mb-2" id="lockmine1">
                                                            <div className="mining-card">
                                                                <div className="d-flex justify-conent-between gap-3 mb-1 p-2">
                                                                    <div className="miner-img lock-img position-relative">
                                                                        <img src={item.img} alt="" />
                                                                        <div className="lock-div">
                                                                            <IoMdLock />
                                                                        </div>
                                                                    </div>
                                                                    <div className='miner-detail d-block'>
                                                                        <p className='text-sm'>{item.name}</p>
                                                                        <div className='line-height-1'>
                                                                            <span className="dull-text text-small">Profit per hour</span><br />
                                                                            <span className="miner-hourlyIncome text-sm dull-text"><img src={dollarCoin} width={15} alt="" /> +{userData?.myMiners?.length > 0 ? returnHourlyIncome(item.id, item.level) : millify(item?.level[0]?.hourlyIncome)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mining-devider"></div>
                                                                <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                    <span className="text-sm text-center dull-text">lvl 0</span>
                                                                    <div className="pph-devider"></div>
                                                                    <p className='ps-2 text-sm m-0 dull-text'> {item.condition.requiredMiner == 'inviteFriend' ? ("invite " + item.condition.requiredLvl + " friends to unlock") : getMinerName(item.condition.requiredMiner) + " lvl " + item.condition.requiredLvl}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }

                                        })}
                                    </div>
                                </>
                                : currentTab == 1 ?
                                    <>
                                        <div className='row mx-2 mt-3 pb-100'>
                                            {minordata.map((item) => {
                                                if (Number(item.category) == 1) {
                                                    if (IncludeInWaitingList(item.id) && TimeCompleted) {
                                                        return (
                                                            <div className="col-6 mb-2">
                                                                <div className="mining-card">
                                                                    <div className="d-flex justify-conent-between gap-2 mb-1 p-2">
                                                                        <div className="miner-img position-relative">
                                                                            <img src={item.img} alt="" />
                                                                            <div className="countdown">
                                                                                <CountdownCircleTimer
                                                                                    size={"100%"}
                                                                                    isPlaying
                                                                                    duration={returnDuration(item.id)}
                                                                                    colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                                                                                    colorsTime={[10, 6, 3, 0]}
                                                                                    onComplete={() => handleBuyAfterTime(item.id, item.level)}
                                                                                >
                                                                                    {renderTime}
                                                                                </CountdownCircleTimer>
                                                                            </div>
                                                                        </div>
                                                                        <div className='miner-detail d-block'>
                                                                            <p className='text-sm'>{item.name}</p>
                                                                            <div className='line-height-1'>
                                                                                <span className="dull-text text-small">Profit per hour</span><br />
                                                                                <span className={userData.myMiners.length > 0 && IncludeInMiner(item.id) ? "miner-hourlyIncome text-sm" : "miner-hourlyIncome text-sm dull-text"}><img src={dollarCoin} width={15} alt="" /> {userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnHourlyIncome(item.id, item.level) : " +" + millify(item?.level[0].hourlyIncome)}</span>
                                                                                {/* <p>{returnRemainingTime(item.id)}</p> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mining-devider"></div>
                                                                    <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                        <span className="text-sm text-center">lvl {returnLevel(item.id)}</span>
                                                                        <div className="pph-devider"></div>
                                                                        <p className={isAffordable(userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level, true) : millify(Number(item?.level[0]?.upCost))) ? "ps-2 text-sm m-0" : "ps-2 text-sm m-0 dull-text"}><img src={dollarCoin} className={'me-1'} width={20} alt="" /> {userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level) : millify(Number(item?.level[0].upCost))}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    } else if (item.condition.isRequire == true && userData?.myMiners?.length > 0 && findCondition(item.condition.requiredLvl, item.condition.requiredMiner)) {
                                                        return (
                                                            <div className="col-6 mb-2" onClick={() => handleMineShow(item)}>
                                                                <div className="mining-card">
                                                                    <div className="d-flex justify-conent-between gap-2 mb-1 p-2">
                                                                        <div className="miner-img">
                                                                            <img src={item.img} alt="" />
                                                                        </div>
                                                                        <div className='miner-detail d-block'>
                                                                            <p className='text-sm'>{item.name}</p>
                                                                            <div className='line-height-1'>
                                                                                <span className="dull-text text-small">Profit per hour</span><br />
                                                                                <span className={userData?.myMiners.length > 0 && IncludeInMiner(item.id) ? "miner-hourlyIncome text-sm" : "miner-hourlyIncome text-sm dull-text"}><img src={dollarCoin} width={15} alt="" /> {userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnHourlyIncome(item.id, item.level) : " +" + millify(item?.level[0]?.hourlyIncome)}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mining-devider"></div>
                                                                    <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                        <span className="text-sm text-center">lvl {returnLevel(item.id)}</span>
                                                                        <div className="pph-devider"></div>
                                                                        <p className={isAffordable(userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level, true) : millify(Number(item?.level[0]?.upCost))) ? "ps-2 text-sm m-0" : "ps-2 text-sm m-0 dull-text"}><img src={dollarCoin} className='me-1' width={20} alt="" /> {userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level) : millify(item?.level[0].upCost)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    } else if (item.condition.isRequire == false) {
                                                        return (
                                                            <div className="col-6 mb-2" onClick={() => handleMineShow(item)}>
                                                                <div className="mining-card">
                                                                    <div className="d-flex justify-conent-between gap-2 mb-1 p-2">
                                                                        <div className="miner-img">
                                                                            <img src={item.img} alt="" />
                                                                        </div>
                                                                        <div className='miner-detail d-block'>
                                                                            <p className='text-sm'>{item.name}</p>
                                                                            <div className='line-height-1'>
                                                                                <span className="dull-text text-small">Profit per hour</span><br />
                                                                                <span className={userData?.myMiners?.length > 0 && IncludeInMiner(item.id) ? "miner-hourlyIncome text-sm" : "miner-hourlyIncome text-sm dull-text"}><img src={dollarCoin} width={15} alt="" /> {userData?.myMiners?.length > 0 && IncludeInMiner(item.id) ? returnHourlyIncome(item.id, item.level) : " +" + millify(item?.level[0]?.hourlyIncome)}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mining-devider"></div>
                                                                    <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                        <span className="text-sm text-center">lvl {returnLevel(item.id)}</span>
                                                                        <div className="pph-devider"></div>
                                                                        <p className={isAffordable(userData?.myMiners?.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level, true) : Number(item?.level[0]?.upCost)) ? "ps-2 text-sm m-0" : "ps-2 text-sm m-0 dull-text"}><img src={dollarCoin} className='me-1' width={20} alt="" /> {userData?.myMiners?.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level) : millify(item?.level[0]?.upCost)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <div className="col-6 mb-2">
                                                                <div className="mining-card">
                                                                    <div className="d-flex justify-conent-between gap-3 mb-1 p-2">
                                                                        <div className="miner-img lock-img position-relative">
                                                                            <img src={item.img} alt="" />
                                                                            <div className="lock-div">
                                                                                <IoMdLock />
                                                                            </div>
                                                                        </div>
                                                                        <div className='miner-detail d-block'>
                                                                            <p className='text-sm'>{item.name}</p>
                                                                            <div className='line-height-1'>
                                                                                <span className="dull-text text-small">Profit per hour</span><br />
                                                                                <span className="miner-hourlyIncome text-sm dull-text"><img src={dollarCoin} width={15} alt="" /> +{userData?.myMiners?.length > 0 ? returnHourlyIncome(item.id, item.level) : millify(item?.level[0]?.hourlyIncome)}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mining-devider"></div>
                                                                    <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                        <span className="text-sm text-center dull-text">lvl 0</span>
                                                                        <div className="pph-devider"></div>
                                                                        <p className='ps-2 text-sm m-0 dull-text'> {item.condition.requiredMiner == 'inviteFriend' ? ("invite " + item.condition.requiredLvl + " friends to unlock") : getMinerName(item.condition.requiredMiner) + " lvl " + item.condition.requiredLvl}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                }

                                            })}
                                        </div>
                                    </>
                                    : currentTab == 2 ?
                                        <>
                                            <div className='row mx-2 mt-3 pb-100'>
                                                {minordata.map((item) => {
                                                    if (Number(item.category) == 2) {
                                                        if (IncludeInWaitingList(item.id) && TimeCompleted) {
                                                            return (
                                                                <div className="col-6 mb-2">
                                                                    <div className="mining-card">
                                                                        <div className="d-flex justify-conent-between gap-2 mb-1 p-2">
                                                                            <div className="miner-img position-relative">
                                                                                <img src={item.img} alt="" />
                                                                                <div className="countdown">
                                                                                    <CountdownCircleTimer
                                                                                        size={"100%"}
                                                                                        isPlaying
                                                                                        duration={returnDuration(item.id)}
                                                                                        colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                                                                                        colorsTime={[10, 6, 3, 0]}
                                                                                        onComplete={() => handleBuyAfterTime(item.id, item.level)}
                                                                                    >
                                                                                        {renderTime}
                                                                                    </CountdownCircleTimer>
                                                                                </div>
                                                                            </div>
                                                                            <div className='miner-detail d-block'>
                                                                                <p className='text-sm'>{item.name}</p>
                                                                                <div className='line-height-1'>
                                                                                    <span className="dull-text text-small">Profit per hour</span><br />
                                                                                    <span className={userData.myMiners.length > 0 && IncludeInMiner(item.id) ? "miner-hourlyIncome text-sm" : "miner-hourlyIncome text-sm dull-text"}><img src={dollarCoin} width={15} alt="" /> {userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnHourlyIncome(item.id, item.level) : " +" + millify(item?.level[0].hourlyIncome)}</span>
                                                                                    {/* <p>{returnRemainingTime(item.id)}</p> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mining-devider"></div>
                                                                        <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                            <span className="text-sm text-center">lvl {returnLevel(item.id)}</span>
                                                                            <div className="pph-devider"></div>
                                                                            <p className={isAffordable(userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level, true) : millify(Number(item?.level[0]?.upCost))) ? "ps-2 text-sm m-0" : "ps-2 text-sm m-0 dull-text"}><img src={dollarCoin} className={'me-1'} width={20} alt="" /> {userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level) : millify(Number(item?.level[0].upCost))}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        } else if (item.condition.isRequire == true && userData?.myMiners?.length > 0 && findCondition(item.condition.requiredLvl, item.condition.requiredMiner)) {
                                                            return (
                                                                <div className="col-6 mb-2" onClick={() => handleMineShow(item)}>
                                                                    <div className="mining-card">
                                                                        <div className="d-flex justify-conent-between gap-2 mb-1 p-2">
                                                                            <div className="miner-img">
                                                                                <img src={item.img} alt="" />
                                                                            </div>
                                                                            <div className='miner-detail d-block'>
                                                                                <p className='text-sm'>{item.name}</p>
                                                                                <div className='line-height-1'>
                                                                                    <span className="dull-text text-small">Profit per hour</span><br />
                                                                                    <span className={userData?.myMiners.length > 0 && IncludeInMiner(item.id) ? "miner-hourlyIncome text-sm" : "miner-hourlyIncome text-sm dull-text"}><img src={dollarCoin} width={15} alt="" /> {userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnHourlyIncome(item.id, item.level) : " +" + millify(item?.level[0]?.hourlyIncome)}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mining-devider"></div>
                                                                        <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                            <span className="text-sm text-center">lvl {returnLevel(item.id)}</span>
                                                                            <div className="pph-devider"></div>
                                                                            <p className={isAffordable(userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level, true) : millify(Number(item?.level[0]?.upCost))) ? "ps-2 text-sm m-0" : "ps-2 text-sm m-0 dull-text"}><img src={dollarCoin} className='me-1' width={20} alt="" /> {userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level) : millify(item?.level[0].upCost)}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        } else if (item.condition.isRequire == false) {
                                                            return (
                                                                <div className="col-6 mb-2" onClick={() => handleMineShow(item)}>
                                                                    <div className="mining-card">
                                                                        <div className="d-flex justify-conent-between gap-2 mb-1 p-2">
                                                                            <div className="miner-img">
                                                                                <img src={item.img} alt="" />
                                                                            </div>
                                                                            <div className='miner-detail d-block'>
                                                                                <p className='text-sm'>{item.name}</p>
                                                                                <div className='line-height-1'>
                                                                                    <span className="dull-text text-small">Profit per hour</span><br />
                                                                                    <span className={userData?.myMiners?.length > 0 && IncludeInMiner(item.id) ? "miner-hourlyIncome text-sm" : "miner-hourlyIncome text-sm dull-text"}><img src={dollarCoin} width={15} alt="" /> {userData?.myMiners?.length > 0 && IncludeInMiner(item.id) ? returnHourlyIncome(item.id, item.level) : " +" + millify(item?.level[0]?.hourlyIncome)}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mining-devider"></div>
                                                                        <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                            <span className="text-sm text-center">lvl {returnLevel(item.id)}</span>
                                                                            <div className="pph-devider"></div>
                                                                            <p className={isAffordable(userData?.myMiners?.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level, true) : Number(item?.level[0]?.upCost)) ? "ps-2 text-sm m-0" : "ps-2 text-sm m-0 dull-text"}><img src={dollarCoin} className='me-1' width={20} alt="" /> {userData?.myMiners?.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level) : millify(item?.level[0]?.upCost)}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <div className="col-6 mb-2">
                                                                    <div className="mining-card">
                                                                        <div className="d-flex justify-conent-between gap-3 mb-1 p-2">
                                                                            <div className="miner-img lock-img position-relative">
                                                                                <img src={item.img} alt="" />
                                                                                <div className="lock-div">
                                                                                    <IoMdLock />
                                                                                </div>
                                                                            </div>
                                                                            <div className='miner-detail d-block'>
                                                                                <p className='text-sm'>{item.name}</p>
                                                                                <div className='line-height-1'>
                                                                                    <span className="dull-text text-small">Profit per hour</span><br />
                                                                                    <span className="miner-hourlyIncome text-sm dull-text"><img src={dollarCoin} width={15} alt="" /> +{userData?.myMiners?.length > 0 ? returnHourlyIncome(item.id, item.level) : millify(item?.level[0]?.hourlyIncome)}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mining-devider"></div>
                                                                        <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                            <span className="text-sm text-center dull-text">lvl 0</span>
                                                                            <div className="pph-devider"></div>
                                                                            <p className='ps-2 text-sm m-0 dull-text'> {item.condition.requiredMiner == 'inviteFriend' ? ("invite " + item.condition.requiredLvl + " friends to unlock") : getMinerName(item.condition.requiredMiner) + " lvl " + item.condition.requiredLvl}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    }

                                                })}
                                            </div>
                                        </>
                                        : currentTab == 3 ?
                                            <>
                                                <div className='row mx-2 mt-3 pb-100'>
                                                    {minordata.map((item) => {
                                                        if (Number(item.category) == 3) {
                                                            if (IncludeInWaitingList(item.id) && TimeCompleted) {
                                                                return (
                                                                    <div className="col-6 mb-2">
                                                                        <div className="mining-card">
                                                                            <div className="d-flex justify-conent-between gap-2 mb-1 p-2">
                                                                                <div className="miner-img position-relative">
                                                                                    <img src={item.img} alt="" />
                                                                                    <div className="countdown">
                                                                                        <CountdownCircleTimer
                                                                                            size={"100%"}
                                                                                            isPlaying
                                                                                            duration={returnDuration(item.id)}
                                                                                            colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                                                                                            colorsTime={[10, 6, 3, 0]}
                                                                                            onComplete={() => handleBuyAfterTime(item.id, item.level)}
                                                                                        >
                                                                                            {renderTime}
                                                                                        </CountdownCircleTimer>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='miner-detail d-block'>
                                                                                    <p className='text-sm'>{item.name}</p>
                                                                                    <div className='line-height-1'>
                                                                                        <span className="dull-text text-small">Profit per hour</span><br />
                                                                                        <span className={userData.myMiners.length > 0 && IncludeInMiner(item.id) ? "miner-hourlyIncome text-sm" : "miner-hourlyIncome text-sm dull-text"}><img src={dollarCoin} width={15} alt="" /> {userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnHourlyIncome(item.id, item.level) : " +" + millify(item?.level[0].hourlyIncome)}</span>
                                                                                        {/* <p>{returnRemainingTime(item.id)}</p> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mining-devider"></div>
                                                                            <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                                <span className="text-sm text-center">lvl {returnLevel(item.id)}</span>
                                                                                <div className="pph-devider"></div>
                                                                                <p className={isAffordable(userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level, true) : millify(Number(item?.level[0]?.upCost))) ? "ps-2 text-sm m-0" : "ps-2 text-sm m-0 dull-text"}><img src={dollarCoin} className={'me-1'} width={20} alt="" /> {userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level) : millify(Number(item?.level[0].upCost))}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            } else if (item.condition.isRequire == true && userData?.myMiners?.length > 0 && findCondition(item.condition.requiredLvl, item.condition.requiredMiner)) {
                                                                return (
                                                                    <div className="col-6 mb-2" onClick={() => handleMineShow(item)}>
                                                                        <div className="mining-card">
                                                                            <div className="d-flex justify-conent-between gap-2 mb-1 p-2">
                                                                                <div className="miner-img">
                                                                                    <img src={item.img} alt="" />
                                                                                </div>
                                                                                <div className='miner-detail d-block'>
                                                                                    <p className='text-sm'>{item.name}</p>
                                                                                    <div className='line-height-1'>
                                                                                        <span className="dull-text text-small">Profit per hour</span><br />
                                                                                        <span className={userData?.myMiners.length > 0 && IncludeInMiner(item.id) ? "miner-hourlyIncome text-sm" : "miner-hourlyIncome text-sm dull-text"}><img src={dollarCoin} width={15} alt="" /> {userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnHourlyIncome(item.id, item.level) : " +" + millify(item?.level[0]?.hourlyIncome)}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mining-devider"></div>
                                                                            <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                                <span className="text-sm text-center">lvl {returnLevel(item.id)}</span>
                                                                                <div className="pph-devider"></div>
                                                                                <p className={isAffordable(userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level, true) : millify(Number(item?.level[0]?.upCost))) ? "ps-2 text-sm m-0" : "ps-2 text-sm m-0 dull-text"}><img src={dollarCoin} className='me-1' width={20} alt="" /> {userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level) : millify(item?.level[0].upCost)}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            } else if (item.condition.isRequire == false) {
                                                                return (
                                                                    <div className="col-6 mb-2" onClick={() => handleMineShow(item)}>
                                                                        <div className="mining-card">
                                                                            <div className="d-flex justify-conent-between gap-2 mb-1 p-2">
                                                                                <div className="miner-img">
                                                                                    <img src={item.img} alt="" />
                                                                                </div>
                                                                                <div className='miner-detail d-block'>
                                                                                    <p className='text-sm'>{item.name}</p>
                                                                                    <div className='line-height-1'>
                                                                                        <span className="dull-text text-small">Profit per hour</span><br />
                                                                                        <span className={userData?.myMiners?.length > 0 && IncludeInMiner(item.id) ? "miner-hourlyIncome text-sm" : "miner-hourlyIncome text-sm dull-text"}><img src={dollarCoin} width={15} alt="" /> {userData?.myMiners?.length > 0 && IncludeInMiner(item.id) ? returnHourlyIncome(item.id, item.level) : " +" + millify(item?.level[0]?.hourlyIncome)}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mining-devider"></div>
                                                                            <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                                <span className="text-sm text-center">lvl {returnLevel(item.id)}</span>
                                                                                <div className="pph-devider"></div>
                                                                                <p className={isAffordable(userData?.myMiners?.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level, true) : Number(item?.level[0]?.upCost)) ? "ps-2 text-sm m-0" : "ps-2 text-sm m-0 dull-text"}><img src={dollarCoin} className='me-1' width={20} alt="" /> {userData?.myMiners?.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level) : millify(item?.level[0]?.upCost)}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div className="col-6 mb-2">
                                                                        <div className="mining-card">
                                                                            <div className="d-flex justify-conent-between gap-3 mb-1 p-2">
                                                                                <div className="miner-img lock-img position-relative">
                                                                                    <img src={item.img} alt="" />
                                                                                    <div className="lock-div">
                                                                                        <IoMdLock />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='miner-detail d-block'>
                                                                                    <p className='text-sm'>{item.name}</p>
                                                                                    <div className='line-height-1'>
                                                                                        <span className="dull-text text-small">Profit per hour</span><br />
                                                                                        <span className="miner-hourlyIncome text-sm dull-text"><img src={dollarCoin} width={15} alt="" /> +{userData?.myMiners?.length > 0 ? returnHourlyIncome(item.id, item.level) : millify(item?.level[0]?.hourlyIncome)}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mining-devider"></div>
                                                                            <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                                <span className="text-sm text-center dull-text">lvl 0</span>
                                                                                <div className="pph-devider"></div>
                                                                                <p className='ps-2 text-sm m-0 dull-text'> {item.condition.requiredMiner == 'inviteFriend' ? ("invite " + item.condition.requiredLvl + " friends to unlock") : getMinerName(item.condition.requiredMiner) + " lvl " + item.condition.requiredLvl}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        }

                                                    })}
                                                </div>
                                            </>
                                            : <>
                                                <div className="special-mine-section">
                                                    <div className="special-nav">
                                                        <div className={specialNav != 0 ? "special-nav-item" : "special-nav-item special-activate"} onClick={() => setSpecialNav(0)}>
                                                            <p>My cards</p>
                                                        </div>
                                                        <div className={specialNav != 1 ? "special-nav-item" : "special-nav-item special-activate"} onClick={() => setSpecialNav(1)}>
                                                            <p>New cards</p>
                                                        </div>
                                                        <div className={specialNav != 2 ? "special-nav-item" : "special-nav-item special-activate"} onClick={() => setSpecialNav(2)}>
                                                            <p>Missed opportunities</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-section pb-100">
                                                    <div className="container-fluid">
                                                        {specialNav == 0 ?
                                                            <div className="row">
                                                                {specialData.map((item) => {
                                                                    if (IncludeSpecialCard(item.id)) {
                                                                        if (IncludeInSpecialWaitingList(item.id) && TimeCompleted) {
                                                                            return (
                                                                                <div className="col-6 mb-2">
                                                                                    <div className="mining-card pt-3" style={{ background: `linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, ${item.bgImg} 100%)` }}>
                                                                                        <div className={timerCondition(item) ? "special-timer" : "opacity-0"}>
                                                                                            <p className='text-sm text-center'>
                                                                                                <MdOutlineTimer style={{ color: "#e4b11e" }} /> {<Countdown date={Number(item.date) + (Number(item.timeToStay) * 60 * 60 * 1000)} renderer={specialRender} />}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="d-flex justify-conent-center align-items-center text-center flex-column gap-2 mb-1 p-2">
                                                                                            <div className="miner-img lock-img">
                                                                                                <img src={item.img} alt="" />
                                                                                                <div className="countdown">
                                                                                                    <CountdownCircleTimer
                                                                                                        size={"100%"}
                                                                                                        isPlaying
                                                                                                        duration={returnSpecialDuration(item.id)}
                                                                                                        colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                                                                                                        colorsTime={[10, 6, 3, 0]}
                                                                                                        onComplete={() => handleBuyAfterSpecialTime(item.id, item.level)}
                                                                                                    >
                                                                                                        {renderTime}
                                                                                                    </CountdownCircleTimer>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='miner-detail d-block'>
                                                                                                <p className='special-name'>{item.name}</p>
                                                                                                <p className="special-name dull-text mt-2">{item.discription}</p>
                                                                                                <div>
                                                                                                    <span className="dull-text special-name">Profit per hour</span>
                                                                                                    <span className={"miner-hourlyIncome special-name"}> <img src={dollarCoin} width={12} className='ms-2' alt="" /> {userData?.specialCard?.length > 0 && IncludeSpecialCard(item.id) ? returnSpecialHourlyIncome(item.id, item.level) : " +" + millify(item?.level[0]?.hourlyIncome)}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="mining-devider"></div>
                                                                                        <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                                            <span className="text-sm text-center">lvl 0</span>
                                                                                            <div className="pph-devider"></div>
                                                                                            <p className={isAffordable(userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level) : (Number(item?.level[0].upCost))) ? "ps-2 text-sm m-0" : "ps-2 text-sm m-0 dull-text"}><img src={dollarCoin} className='me-1' width={20} alt="" /> {userData?.specialCard?.length > 0 && IncludeSpecialCard(item.id) ? returnSpecialUpCost(item.id, item.level) : item?.level[0]?.upCost}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <div className="col-6 mb-2">
                                                                                    <div className="mining-card pt-3" style={{ background: `linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, ${item.bgImg} 100%)` }} onClick={() => handleSpecialView(item)}>
                                                                                        <div className={timerCondition(item) ? "special-timer" : "opacity-0"}>
                                                                                            <p className='text-sm text-center'>
                                                                                                <MdOutlineTimer style={{ color: "#e4b11e" }} /> {<Countdown date={Number(item.date) + (Number(item.timeToStay) * 60 * 60 * 1000)} renderer={specialRender} />}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="d-flex justify-conent-center align-items-center text-center flex-column gap-2 mb-1 p-2">
                                                                                            <div className="miner-img">
                                                                                                <img src={item.img} alt="" />
                                                                                            </div>
                                                                                            <div className='miner-detail d-block'>
                                                                                                <p className='special-name'>{item.name}</p>
                                                                                                <p className="special-name dull-text mt-2">{item.discription}</p>
                                                                                                <div>
                                                                                                    <span className="dull-text special-name">Profit per hour</span>
                                                                                                    <span className={"miner-hourlyIncome special-name"}> <img src={dollarCoin} width={12} className='ms-2' alt="" /> {userData?.specialCard?.length > 0 && IncludeSpecialCard(item.id) ? returnSpecialHourlyIncome(item.id, item.level) : " +" + millify(item?.level[0]?.hourlyIncome)}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="mining-devider"></div>
                                                                                        <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                                            <span className="text-sm text-center">lvl {returnSpecialLvl(item.id)}</span>
                                                                                            <div className="pph-devider"></div>
                                                                                            <p className={isAffordable(userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level) : (Number(item?.level[0].upCost))) ? "ps-2 text-sm m-0" : "ps-2 text-sm m-0 dull-text"}><img src={dollarCoin} className='me-1' width={20} alt="" /> {userData?.specialCard?.length > 0 && IncludeSpecialCard(item.id) ? returnSpecialUpCost(item.id, item.level) : millify(Number(item?.level[0].upCost))}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    }
                                                                }
                                                                )}
                                                            </div>
                                                            : specialNav == 1 ?
                                                                <div className="row">
                                                                    {specialData.map((item) => {
                                                                        if (!IncludeSpecialCard(item.id) && !item.isTimeCompleted) {
                                                                            if (IncludeInSpecialWaitingList(item.id) && TimeCompleted) {
                                                                                return (
                                                                                    <div className="col-6 mb-2">
                                                                                        <div className="mining-card pt-3" style={{ background: `linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, ${item.bgImg} 100%)` }}>
                                                                                            <div className={timerCondition(item) ? "special-timer" : "opacity-0"}>
                                                                                                <p className='text-sm text-center'>
                                                                                                    <MdOutlineTimer style={{ color: "#e4b11e" }} /> {<Countdown date={Number(item.date) + (Number(item.timeToStay) * 60 * 60 * 1000)} renderer={specialRender} />}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="d-flex justify-conent-center align-items-center text-center flex-column gap-2 mb-1 p-2">
                                                                                                <div className="miner-img lock-img">
                                                                                                    <img src={item.img} alt="" />
                                                                                                    <div className="countdown">
                                                                                                        <CountdownCircleTimer
                                                                                                            size={"100%"}
                                                                                                            isPlaying
                                                                                                            duration={returnSpecialDuration(item.id)}
                                                                                                            colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                                                                                                            colorsTime={[10, 6, 3, 0]}
                                                                                                            onComplete={() => handleBuyAfterSpecialTime(item.id, item.level)}
                                                                                                        >
                                                                                                            {renderTime}
                                                                                                        </CountdownCircleTimer>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='miner-detail d-block'>
                                                                                                    <p className='special-name'>{item.name}</p>
                                                                                                    <p className="special-name dull-text mt-2">{item.discription}</p>
                                                                                                    <div>
                                                                                                        <span className="dull-text special-name">Profit per hour</span>
                                                                                                        <span className={"miner-hourlyIncome special-name"}> <img src={dollarCoin} width={12} className='ms-2' alt="" /> {userData?.specialCard?.length > 0 && IncludeSpecialCard(item.id) ? returnSpecialHourlyIncome(item.id, item.level) : " +" + millify(item?.level[0]?.hourlyIncome)}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="mining-devider"></div>
                                                                                            <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                                                <span className="text-sm text-center">lvl 0</span>
                                                                                                <div className="pph-devider"></div>
                                                                                                <p className={isAffordable(userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level) : (Number(item?.level[0].upCost))) ? "ps-2 text-sm m-0" : "ps-2 text-sm m-0 dull-text"}><img src={dollarCoin} className='me-1' width={20} alt="" /> {userData?.specialCard?.length > 0 && IncludeSpecialCard(item.id) ? returnSpecialUpCost(item.id, item.level) : item?.level[0]?.upCost}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            } else if (isSpecialLocked(item)) {
                                                                                return (
                                                                                    <div className="col-6 mb-2">
                                                                                        <div className="mining-card pt-3" style={{ background: `linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, ${item.bgImg} 100%)` }}>
                                                                                            <div className={timerCondition(item) ? "special-timer" : "opacity-0"}>
                                                                                                <p className='text-sm text-center'>
                                                                                                    <MdOutlineTimer style={{ color: "#e4b11e" }} /> {<Countdown date={Number(item.date) + (Number(item.timeToStay) * 60 * 60 * 1000)} renderer={specialRender} />}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="d-flex justify-conent-center align-items-center text-center flex-column gap-2 mb-1 p-2">
                                                                                                <div className="miner-img lock-img">
                                                                                                    <img src={item.img} alt="" />
                                                                                                    <FaLock className='special-lock' />
                                                                                                </div>
                                                                                                <div className='miner-detail d-block'>
                                                                                                    <p className='special-name'>{item.name}</p>
                                                                                                    <p className="special-name dull-text mt-2">{item.discription}</p>
                                                                                                    <div>
                                                                                                        <span className="dull-text special-name">Profit per hour</span>
                                                                                                        <span className={"miner-hourlyIncome special-name"}> <img src={dollarCoin} width={12} className='ms-2' alt="" /> {userData?.specialCard?.length > 0 && IncludeSpecialCard(item.id) ? returnSpecialHourlyIncome(item.id, item.level) : " +" + millify(item?.level[0]?.hourlyIncome)}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="mining-devider"></div>
                                                                                            <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                                                <span className="text-sm text-center">lvl 0</span>
                                                                                                <div className="pph-devider"></div>
                                                                                                <p className='ps-2 text-sm m-0 dull-text'> {item.condition.requiredMiner == 'inviteFriend' ? ("invite " + item.condition.requiredLvl + " friends to unlock") : getSpecialName(item.condition.requiredMiner) + " lvl " + item.condition.requiredLvl}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div className="col-6 mb-2">
                                                                                        <div className="mining-card pt-3" style={{ background: `linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, ${item.bgImg} 100%)` }} onClick={() => handleSpecialView(item)}>
                                                                                            <div className={!item.isPermanent ? "special-timer" : "opacity-0"}>
                                                                                                <p className='text-sm text-center'>
                                                                                                    <MdOutlineTimer style={{ color: "#e4b11e" }} /> {<Countdown date={Number(item.date) + (Number(item.timeToStay) * 60 * 60 * 1000)} renderer={specialRender} />}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="d-flex justify-conent-center align-items-center text-center flex-column gap-2 mb-1 p-2">
                                                                                                <div className="miner-img">
                                                                                                    <img src={item.img} alt="" />
                                                                                                </div>
                                                                                                <div className='miner-detail d-block'>
                                                                                                    <p className='special-name'>{item.name}</p>
                                                                                                    <p className="special-name dull-text mt-2">{item.discription}</p>
                                                                                                    <div>
                                                                                                        <span className="dull-text special-name">Profit per hour</span>
                                                                                                        <span className={"miner-hourlyIncome special-name"}> <img src={dollarCoin} width={12} className='ms-2' alt="" /> {userData?.specialCard?.length > 0 && IncludeSpecialCard(item.id) ? returnSpecialHourlyIncome(item.id, item.level) : " +" + millify(item?.level[0]?.hourlyIncome)}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="mining-devider"></div>
                                                                                            <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                                                <span className="text-sm text-center">lvl 0</span>
                                                                                                <div className="pph-devider"></div>
                                                                                                <p className={isAffordable(userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level) : (Number(item?.level[0].upCost))) ? "ps-2 text-sm m-0" : "ps-2 text-sm m-0 dull-text"}><img src={dollarCoin} className='me-1' width={20} alt="" /> {userData?.specialCard?.length > 0 && IncludeSpecialCard(item.id) ? returnSpecialUpCost(item.id, item.level) : item?.level[0]?.upCost}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        }
                                                                    })}
                                                                </div>
                                                                :
                                                                <div className="row">
                                                                    {specialData.map((item) => {
                                                                        if (!IncludeSpecialCard(item.id) && item.isTimeCompleted) {
                                                                            return (
                                                                                <div className="col-6 mb-2">
                                                                                    <div className="mining-card pt-3" style={{ background: `linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, ${item.bgImg} 100%)` }}>
                                                                                        <div className={"opacity-0"}>
                                                                                            <p className='text-sm text-center'>
                                                                                                <MdOutlineTimer style={{ color: "#e4b11e" }} /> {<Countdown date={Number(item.date) + (Number(item.timeToStay) * 60 * 60 * 1000)} renderer={specialRender} />}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="d-flex justify-conent-center align-items-center text-center flex-column gap-2 mb-1 p-2">
                                                                                            <div className="miner-img">
                                                                                                <img src={item.img} alt="" />
                                                                                            </div>
                                                                                            <div className='miner-detail d-block'>
                                                                                                <p className='special-name'>{item.name}</p>
                                                                                                <p className="special-name dull-text mt-2">{item.discription}</p>
                                                                                                <div>
                                                                                                    <span className="dull-text special-name">Profit per hour</span>
                                                                                                    <span className={"miner-hourlyIncome special-name dull-text"}> <img src={dollarCoin} width={12} className='ms-2' alt="" /> {userData?.specialCard?.length > 0 && IncludeSpecialCard(item.id) ? returnSpecialHourlyIncome(item.id, item.level) : " +" + millify(item?.level[0]?.hourlyIncome)}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="mining-devider"></div>
                                                                                        <div className="d-flex p-1 align-items-center miner-lvl-info">
                                                                                            <span className="text-sm text-center">lvl 0</span>
                                                                                            <div className="pph-devider"></div>
                                                                                            <p className={isAffordable(userData.myMiners.length > 0 && IncludeInMiner(item.id) ? returnUpCost(item.id, item.level) : (Number(item?.level[0].upCost))) ? "ps-2 text-sm m-0" : "ps-2 text-sm m-0 dull-text"}> Expired</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })}
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </>

                            }
                        </div>
                    </div>

                </div>
                <BottomNavigation page={1} />
            </div >
            {specialModal ? <div className="modal-overlay" onClick={closeSpecialModal}></div> : null
            }
            {
                specialModal ?
                    <div className="task-modal">
                        <div className="modal-border">
                            <div className="modal-inner-section">
                                <div className="d-flex align-items-center justify-content-center w-100 text-white gap-3 h-100 flex-column position-relative">
                                    <img src={currentSpecialData?.img} width={100} alt="" />
                                    <h1>{currentSpecialData?.name}</h1>
                                    <span className="text-sm text-center w-80">{currentSpecialData?.discription}</span>
                                    <div className='text-center'>
                                        <span className="text-sm">Profit per hour</span><br />
                                        <span className="miner-hourlyIncome text-sm"><img src={dollarCoin} width={15} alt="" /> + {userData?.specialCard?.length > 0 && IncludeSpecialCard(currentSpecialData?.id) ? returnSpecialNextHourlyIncome(currentSpecialData?.id, currentSpecialData?.level) : currentSpecialData?.level[0]?.hourlyIncome}</span>
                                    </div>
                                    <span className="miner-upCost"><img src={dollarCoin} className='me-1' width={30} alt="" />  {userData?.specialCard?.length > 0 && IncludeSpecialCard(currentSpecialData?.id) ? returnSpecialUpCost(currentSpecialData?.id, currentSpecialData?.level) : currentSpecialData?.level[0]?.upCost}</span>
                                    <button className={showButtonLoader ? "dull-check-btn" : "check-btn"} onClick={BuySpecial}>{showButtonLoader ? <BtnLoader /> : "Go Ahead"}</button>
                                    <div className="cross-button" onClick={closeSpecialModal}>
                                        <RxCross1 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {
                MineModal ? <div className="modal-overlay" onClick={closeMineModal}></div> : null
            }
            {
                MineModal ?
                    <div className="task-modal">
                        <div className="modal-border">
                            <div className="modal-inner-section">
                                <div className="d-flex align-items-center justify-content-center w-100 text-white gap-3 h-100 flex-column position-relative">
                                    <img src={currentMineData?.img} width={100} alt="" />
                                    <h1>{currentMineData?.name}</h1>
                                    <span className="text-sm text-center w-80">{currentMineData?.discription}</span>
                                    <div className='text-center'>
                                        <span className="text-sm">{langJson.profitPerHour}</span><br />
                                        <span className="miner-hourlyIncome text-sm"><img src={dollarCoin} width={15} alt="" /> + {userData.myMiners?.length > 0 && IncludeInMiner(currentMineData?.id) ? returnNextHourlyIncome(currentMineData?.id, currentMineData?.level) : currentMineData?.level[0].hourlyIncome}</span>
                                    </div>
                                    <span className="miner-upCost"><img src={dollarCoin} className='me-1' width={30} alt="" />  {userData.myMiners?.length > 0 && IncludeInMiner(currentMineData?.id) ? returnUpCost(currentMineData?.id, currentMineData?.level) : currentMineData?.level[0].upCost}</span>
                                    <button className={showButtonLoader ? "dull-check-btn" : "check-btn"} onClick={BuyMiner}>{showButtonLoader ? <BtnLoader /> : langJson.goAhead}</button>
                                    <div className="cross-button" onClick={closeMineModal}>
                                        <RxCross1 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }

            {comboModel ? <div className="modal-overlay" onClick={closeComboModal}></div> : null}
            {
                comboModel ?
                    <div className="task-modal">
                        <div className="modal-border">
                            <div className="modal-inner-section">
                                <div className="d-flex align-items-center justify-content-center w-100 text-white gap-3 h-100 flex-column position-relative">
                                    <img src={comboComplete} width={100} alt="" />
                                    <p className='mx-5 text-center'>  {langJson.yayCombo}</p>

                                    <span className="miner-upCost"><img src={dollarCoin} className='me-1' width={30} alt="" />  5,000,000</span>
                                    <button className={showButtonLoader ? "dull-check-btn" : "check-btn"} onClick={closeComboModal} >{showButtonLoader ? <BtnLoader /> : langJson.takeThePrize}  </button>
                                    <div className="cross-button" onClick={closeComboModal}>
                                        <RxCross1 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }

            {ProfitModal ? <div className="modal-overlay" onClick={closeProfitModal}></div> : null}
            {
                ProfitModal ?
                    <div className="task-modal">
                        <div className="modal-border">
                            <div className="modal-inner-section">
                                <div className="d-flex align-items-center justify-content-center w-100 text-white gap-3 h-100 flex-column position-relative">
                                    <img src={boostprofit} width={100} alt="" />
                                    <h1>{langJson.boostYourProfit}</h1>
                                    <p className='mx-5 text-center'> {langJson.tapTheMiningMenu} </p>
                                    <h6>{langJson.EarnEvenWhenOffline}</h6>

                                    {/* <span className="miner-upCost"><img src={dollarCoin} className='me-1' width={30} alt="" />  {userData.myMiners?.length > 0 && IncludeInMiner(currentMineData?.id) ? returnUpCost(currentMineData?.id, currentMineData?.level) : currentMineData?.level[0].upCost}</span> */}
                                    <button className={showButtonLoader ? "dull-check-btn" : "check-btn"} onClick={() => navigate('/mine')} >{langJson.startMining} <img src={dollarCoin} className='img-fluid' height={30} width={30} alt="" /> </button>
                                    <div className="cross-button" onClick={closeMineModal}>
                                        <RxCross1 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    )
}
