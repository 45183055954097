import React from 'react'

export default function FalseTime() {
    return (
        <>
            <div className="container text-white joinTg">
                <h1>Time Change Detected</h1>
                <p className='text-center'>You've been redirected because you set the time to a different value.</p>
                <p className='text-center'>Edjust your time to today and come back</p>

            </div>
        </>
    )
}
