import React from 'react'

export default function Suspend() {
    return (
        <div className="container text-white joinTg">
            <h1>Temporary Suspended</h1>
            <p className='text-center'>You've been redirected because you are doing some suspicious activity.</p>
        </div>
    )
}
