import React, { useContext, useEffect, useState } from 'react'
import BottomNavigation from '../Components/BottomNavigation'
import airdropcoin from '../Images/hamster-coin.png'
import tonwallet from '../Images/airdrop-wallet.png'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { RxCross1 } from 'react-icons/rx'
import { TonConnect, TonConnectButton, useTonWallet } from '@tonconnect/ui-react'
import { useNavigate } from 'react-router-dom'
import { FaPlus } from 'react-icons/fa'
import usdt from '../Images/USDT.png'
import { Table } from 'react-bootstrap'
import { PiHandWithdraw, PiUsersThreeFill } from 'react-icons/pi'
import firebaseApp from '../Firebase/firebase'
import moment from 'moment'
import { context } from '../Context/UserContext'
import Loader from '../Components/Loader'
import CopyToClipboard from 'react-copy-to-clipboard'
import toast from 'react-hot-toast'
import axios from 'axios'
import { driver } from 'driver.js'

export default function Airdrop() {

    const [tonModal, setTonModal] = useState(false)
    const navigate = useNavigate();
    const [pendingPackage, setPendingPackage] = useState([])
    const [completedPackage, setCompletedPackage] = useState([])
    const [showLoader, setShowLoader] = useState(true)
    const [ramount, setramount] = useState(0)
    const [rachiver, setrachiver] = useState(0)

    const { userData, setUserData, getUserData, userName, showLoaderContext, offlineReward, points, setPoints, powerObj, setPowerObj, remainingPower, setRemainingPower, pph, setPPH, price, langJson } = useContext(context)
    // const storage: IStorage = 0

    // const connector = new TonConnect({ manifestUrl: "https://ton-connect.github.io/demo-dapp-with-wallet/tonconnect-manifest.json", storage });
    useEffect(() => {
        getUserData()
        getPackage()
        if (window.Telegram.WebApp) {
            const tg = window.Telegram.WebApp;

            // Initialize the backButton
            tg.BackButton.show();
            tg.BackButton.onClick(() => {
                // Handle back button click
                navigate('/')
                tg.BackButton.hide();
            });

            // Cleanup on component unmount
            return () => {
                tg.BackButton.offClick();
                tg.BackButton.hide();
            };
        }
    }, [])

    const driverObj = driver({
        showProgress: true,
        steps: [
            // {
            //     element: "#smartContract",
            //     popover: {
            //         title: langJson.airt1,
            //         description: langJson.air1,
            //     }
            // },
            // {
            //     element: "#price",
            //     popover: {
            //         title: langJson.airt2,
            //         description: langJson.air2,
            //     }
            // },
            {
                element: "#conWallet",
                popover: {
                    title: langJson.airt3,
                    description: langJson.air3,
                }
            },
            {
                element: "#dipAmount",
                popover: {
                    title: langJson.airt4,
                    description: langJson.air4,
                }
            },
            {
                element: "#witAmount",
                popover: {
                    title: langJson.airt5,
                    description: langJson.air5,
                }
            },
            {
                element: "#curPackage",
                popover: {
                    title: langJson.airt6,
                    description: langJson.air6,
                }
            },
            {
                element: "#comPackage",
                popover: {
                    title: langJson.airt7,
                    description: langJson.air7,
                }
            },
        ]
    })

    useEffect(() => {
        if (!localStorage.getItem('airdropGuide')) {
            driverObj.drive()
            localStorage.setItem('airdropGuide', 'true')
        }
    }, [userData])


    // if (!connector.connected) {
    //     alert('Please connect wallet to send the transaction!');
    // }

    // const transaction = {
    //     validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
    //     messages: [
    //         {
    //             address: "EQBBJBB3HagsujBqVfqeDUPJ0kXjgTPLWPFFffuNXNiJL0aA",
    //             amount: "20000000",
    //             // stateInit: "base64bocblahblahblah==" // just for instance. Replace with your transaction initState or remove
    //         },
    //         {
    //             address: "EQDmnxDMhId6v1Ofg_h5KR5coWlFG6e86Ro3pc7Tq4CA0-Jn",
    //             amount: "60000000",
    //             // payload: "base64bocblahblahblah==" // just for instance. Replace with your transaction payload or remove
    //         }
    //     ]
    // }

    // const doTransaction = async () => {
    //     try {
    //         const result = await connector.sendTransaction(transaction);

    //         // you can use signed boc to find the transaction 
    //         const someTxData = await myAppExplorerService.getTransaction(result.boc);
    //         alert('Transaction was sent successfully', someTxData);
    //     } catch (e) {
    //         if (e instanceof UserRejectedError) {
    //             alert('You rejected the transaction. Please confirm it to send to the blockchain');
    //         } else {
    //             alert('Unknown error happened', e);
    //         }
    //     }
    // }

    const getPackage = () => {
        axios.post(`${process.env.REACT_APP_API_URL}` + '/package/get-package', { id: localStorage.getItem('userid') })
            .then((res) => {
                if (res.status == 200) {
                    console.log(res.data)
                    const x = []; // Completed packages
                    const y = []; // Pending packages

                    res.data.forEach((item) => {
                        if (item.isPackageCompleted) {
                            x.push(item);
                        } else {
                            y.push(item);
                        }
                    });
                    setShowLoader(false)
                    setCompletedPackage(x);
                    setPendingPackage(y);
                    getRoyaltyAmount()
                }
            })
    }

    const getRoyaltyAmount = () => {
        axios.get(process.env.REACT_APP_API_URL + '/task/get-royalty-amount')
            .then((res) => {
                if (res.status == 200) {
                    console.log(res?.data)
                    setramount(res?.data?.amount)
                    setrachiver(res?.data?.achiver)
                }
            })
    }

    const showTonModal = () => {
        setTonModal(true)
    }

    const closeTonModal = () => {
        setTonModal(false)
    }
    const ton = useTonWallet()

    const returnEarning = (earning) => {
        let todayEarning = 0
        for (let i = 0; i < earning.length; i++) {
            if (moment(earning[i].date).format('DD-MM-YYYY') == moment(Date.now()).format('DD-MM-YYYY')) {
                todayEarning = todayEarning + earning[i].amount
            }
        }
        return todayEarning
    }

    const handleCopy = () => {
        toast.success('Link Copied')
    }



    return (
        <>
            {showLoader ? <Loader /> : ""}
            <div className="bg-black d-flex justify-content-center">
                <div className="w-100 bg-black text-white h-screen pb-100 font-bold">
                    <div className="Big-coin">
                        <div className="outer-earn-coin">
                            <div className="inner-earn-coin">
                                {/* <CopyToClipboard text='EQADj83n6OASbaht6yf_VR8hamNurbkPY4gRoQAm-7Acz3y4' onCopy={handleCopy}> */}
                                <img src={airdropcoin} onClick={() => navigate('/setting')} id='smartContract' className='earn-dollar-coin' alt="" />
                                {/* </CopyToClipboard> */}
                            </div>
                        </div>
                    </div>
                    {/* <h3 className='text-center text-4xl airdrop-title'>Airdrop tasks</h3>
                    <p className='text-center mx-4 mb-4'>
                        Listing is on its way. Tasks will appear below Complete them to participate in the Airdrop
                    </p> */}
                    <h3 className="text-center" id='price'>
                        Royalty Pool = {Number(ramount).toFixed(2)} <img src={usdt} style={{ marginTop: "-6px" }} width={22} alt="" /> {rachiver} <PiUsersThreeFill style={{ fontSize: 22 }} />
                    </h3>
                    <div className='mx-3 mt-3' id='conWallet'>
                        <button className='w-100 ton-wallet text-white text-start position-relative' onClick={showTonModal}> <img src={tonwallet} className='img-fluid' height={50} width={50} alt="no image wallet" /> {langJson.connectYourTonWallet} <MdOutlineKeyboardArrowRight className='arrow' /></button>
                    </div>
                    <div className="justify-content-between align-items-center d-flex package-balance mx-3 mt-3 mb-2">
                        <div className="inner-div-balance" id='dipAmount'>
                            <span>{Number(userData?.myDeposit).toFixed(2)} </span> <img src={usdt} className='ms-1' width={24} height={24} alt="" /> <button className='add-balance-btn ms-1 p-0' onClick={() => navigate('/deposit')}><FaPlus style={{ width: ".7em", height: ".7em" }} /></button>
                        </div>
                        <div className="inner-div-balance" id='witAmount'>
                            <span>{Number(userData?.myBalance).toFixed(2)} </span> <img src={usdt} className='ms-1' width={24} height={24} alt="" /> <button className='withdraw-balance-btn ms-1 p-0' onClick={() => navigate('/withdraw')}><PiHandWithdraw style={{ width: "1em", height: "1em" }} /></button>
                        </div>
                    </div>
                    <b className="mx-3">{langJson.currentPackage}</b>
                    <div className="mx-3 mb-3" id='curPackage'>
                        <Table className='text-sm text-center mt-3 package-table' bordered variant='dark' striped>
                            <thead>
                                <tr>
                                    <th>{langJson.date}</th>
                                    <th>{langJson.investedAmount}</th>
                                    <th>{langJson.totalReward}</th>
                                    <th>{langJson.todayEarning}</th>
                                    <th>{langJson.totalRecived}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {console.log(pendingPackage)}
                                {pendingPackage && pendingPackage.map((item) => (
                                    <tr>
                                        {console.log(item)}
                                        <td>{moment(item.date).format('DD/MM')}</td>
                                        <td>{Number(item.investAmount).toFixed(2)}</td>
                                        <td>{Number(item.totalReward).toFixed(2)}</td>
                                        <td>{Number(returnEarning(item.Earning)).toFixed(2)}</td>
                                        <td>{Number(item.totalGet).toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <b className="mx-3">{langJson.completedPackage}</b>
                    <div className="mx-3" id='comPackage'>
                        <Table className='text-sm text-center mt-3 completed-table' bordered variant='dark' striped>
                            <thead>
                                <tr>
                                    <th>{langJson.date}</th>
                                    <th>{langJson.investedAmount}</th>
                                    <th>{langJson.totalRecived}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {completedPackage && completedPackage.map((item) => (<tr>
                                    <td>{moment(item.date).format('DD/MM')}</td>
                                    <td>{Number(item.investAmount).toFixed(2)}</td>
                                    <td>{Number(item.totalReward).toFixed(2)}</td>
                                </tr>))}
                            </tbody>
                        </Table>
                    </div>
                </div>

                <BottomNavigation page={4} />
                {tonModal ? <div className="modal-overlay" onClick={closeTonModal}></div> : null}
                {
                    tonModal ?
                        <div className="task-modal">
                            <div className="modal-border">
                                <div className="modal-inner-section">
                                    <div className="d-flex align-items-center justify-content-center w-100 text-white gap-3 h-100 flex-column position-relative">
                                        <img src={tonwallet} width={100} alt="" />
                                        <h3>{langJson.connectYourTonWallet}</h3>
                                        <span className="text-sm text-center w-60">
                                            {langJson.connectYourCryptoWallet}
                                        </span>

                                        <TonConnectButton className={ton == null ? 'ton-connect-btn px-4' : "ton-connect-btn px-4 mb-6"} />
                                        {/* {ton == null ? <button className="dull-check-btn mt-4">Check</button> : ""} */}
                                        <div className="cross-button" onClick={closeTonModal}>
                                            <RxCross1 />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                }
            </div >
        </>
    )
}