const levelNames = [
    "Bronze",    // From 0 to 4999 coins
    "Silver",    // From 5000 coins to 24,999 coins
    "Gold",      // From 25,000 coins to 99,999 coins
    "Platinum",  // From 100,000 coins to 999,999 coins
    "Diamond",   // From 1,000,000 coins to 2,000,000 coins
    "Epic",      // From 2,000,000 coins to 10,000,000 coins
    "Legendary", // From 10,000,000 coins to 50,000,000 coins
    "Master",    // From 50,000,000 coins to 100,000,000 coins
    "GrandMaster", // From 100,000,000 coins to 1,000,000,000 coins
    "Lord",
    "Creator"       // From 1,000,000,000 coins to ∞
];

const levelMinPoints = [
    0,        // Bronze
    5000,     // Silver
    25000,    // Gold
    100000,   // Platinum
    1000000,  // Diamond
    2000000,  // Epic
    10000000, // Legendary
    50000000, // Master
    100000000,// GrandMaster
    1000000000// Lord
];



const upgradeLimit = [
    1000,
    2000,
    4000,
    8000,
    16000,
    32000,
    64000,
    128000,
    256000,
    512000,
    1024000,
    2048000,
    4096000,
    8192000,
    16384000,
    32768000,
    65536000,
    131072000,
    262144000,
    524288000,
    1048576000,
    2097152000,
    4194304000,
    8388608000,
    16777216000,
    33554432000,
    67108864000,
    134217728000,
    268435456000,
]

const upgradeMultitap = [
    2000,
    4000,
    8000,
    16000,
    32000,
    64000,
    128000,
    256000,
    512000,
    1024000,
    2048000,
    4096000,
    8192000,
    16384000,
    32768000,
    65536000,
    131072000,
    262144000,
    524288000,
    1048576000
]






const energyCapacity = [
    0,
    500,
    1000,
    1500,
    2000,
    2500,
    3000,
    3500,
    4000,
    4500,
    5000,
    5500,
    6000,
    6500,
    7000,
    7500,
    8000,
    8500,
    9000,
    9500,
    10000,
    10500,
    11000,
    11500,
    12000,
    12500,
    13000,
    13500,
    14000,
    14500,
    15000,
]

export { levelNames, levelMinPoints, upgradeLimit, upgradeMultitap, energyCapacity }