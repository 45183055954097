import React, { useEffect, useState } from 'react'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './Pages/Home'
import 'bootstrap/dist/css/bootstrap.min.css';
import Earn from './Pages/Earn';
import CircleComponent from './Pages/CircleCompnent';
import firebaseApp from './Firebase/firebase'
import Mine from './Pages/Mine';
import Friends from './Pages/Friends';
import { makeid } from './Helper/helper';
import UserContext from './Context/UserContext';
import Airdrop from './Pages/Airdrop';
import Settings from './Pages/Settings';
import Exchange from './Pages/Exchange';
import Boost from './Pages/Boost';
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react';
import DailyCipher from './Pages/DailyCipher';
import Invite from './Pages/Invite';
import 'ldrs/ring'
import { Toaster } from 'react-hot-toast';
import Deposit from './Pages/Deposit';
import Withdraw from './Pages/Withdraw';
import ProtectedRoute from './Pages/ProtectedRoute';
import JoinTg from './Pages/JoinTg';
import FalseTime from './Pages/FalseTime';
import Suspend from './Pages/Suspend';
import "driver.js/dist/driver.css";
import Language from './Pages/Language';

export default function App() {

  // useEffect(() => {
  //   localStorage.removeItem('walletToken');
  // }, [])


  return (
    <>
      <Toaster />
      <div className="App">
        <TonConnectUIProvider
          manifestUrl="https://hamsterkombatcoin.github.io/Manifest/menifest.json"
          uiPreferences={{ theme: THEME.DARK }}
          walletsListConfiguration={{
            includeWallets: [
              {
                appName: "tonwallet",
                name: "TON Wallet",
                imageUrl: "https://wallet.ton.org/assets/ui/qr-logo.png",
                aboutUrl: "https://chrome.google.com/webstore/detail/ton-wallet/nphplpgoakhhjchkkhmiggakijnkhfnd",
                universalLink: "https://wallet.ton.org/ton-connect",
                jsBridgeKey: "tonwallet",
                bridgeUrl: "https://bridge.tonapi.io/bridge",
                platforms: ["chrome", "android"]
              },
              {
                appName: "nicegramWallet",
                name: "Nicegram Wallet",
                imageUrl: "https://static.nicegram.app/icon.png",
                aboutUrl: "https://nicegram.app",
                universalLink: "https://nicegram.app/tc",
                deepLink: "nicegram-tc://",
                jsBridgeKey: "nicegramWallet",
                bridgeUrl: "https://bridge.tonapi.io/bridge",
                platforms: ["ios", "android"]
              },
              {
                appName: "binanceTonWeb3Wallet",
                name: "Binance Web3 Wallet",
                imageUrl: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjMwIiBoZWlnaHQ9IjMwIiBmaWxsPSIjMEIwRTExIi8+CjxwYXRoIGQ9Ik01IDE1TDcuMjU4MDYgMTIuNzQxOUw5LjUxNjEzIDE1TDcuMjU4MDYgMTcuMjU4MUw1IDE1WiIgZmlsbD0iI0YwQjkwQiIvPgo8cGF0aCBkPSJNOC44NzA5NyAxMS4xMjlMMTUgNUwyMS4xMjkgMTEuMTI5TDE4Ljg3MSAxMy4zODcxTDE1IDkuNTE2MTNMMTEuMTI5IDEzLjM4NzFMOC44NzA5NyAxMS4xMjlaIiBmaWxsPSIjRjBCOTBCIi8+CjxwYXRoIGQ9Ik0xMi43NDE5IDE1TDE1IDEyLjc0MTlMMTcuMjU4MSAxNUwxNSAxNy4yNTgxTDEyLjc0MTkgMTVaIiBmaWxsPSIjRjBCOTBCIi8+CjxwYXRoIGQ9Ik0xMS4xMjkgMTYuNjEyOUw4Ljg3MDk3IDE4Ljg3MUwxNSAyNUwyMS4xMjkgMTguODcxTDE4Ljg3MSAxNi42MTI5TDE1IDIwLjQ4MzlMMTEuMTI5IDE2LjYxMjlaIiBmaWxsPSIjRjBCOTBCIi8+CjxwYXRoIGQ9Ik0yMC40ODM5IDE1TDIyLjc0MTkgMTIuNzQxOUwyNSAxNUwyMi43NDE5IDE3LjI1ODFMMjAuNDgzOSAxNVoiIGZpbGw9IiNGMEI5MEIiLz4KPC9zdmc+Cg==",
                aboutUrl: "https://www.binance.com/en/web3wallet",
                deepLink: "bnc://app.binance.com/cedefi/ton-connect",
                bridgeUrl: "https://bridge.tonapi.io/bridge",
                platforms: ["chrome", "safari", "ios", "android"],
                universalLink: "https://app.binance.com/cedefi/ton-connect"
              },
              {
                appName: "okxTonWallet",
                name: "OKX Wallet",
                imageUrl: "https://static.okx.com/cdn/assets/imgs/247/58E63FEA47A2B7D7.png",
                aboutUrl: "https://www.okx.com/web3",
                universalLink: "https://www.ouxyi.link/ul/uYJPB0",
                bridgeUrl: "https://www.okx.com/tonbridge/discover/rpc/bridge",
                jsBridgeKey: "okxTonWallet",
                platforms: ["chrome", "safari", "firefox", "ios", "android"]
              },
              {
                appName: "okxTonWalletTr",
                name: "OKX TR Wallet",
                imageUrl: "https://static.okx.com/cdn/assets/imgs/247/587A8296F0BB640F.png",
                aboutUrl: "https://tr.okx.com/web3",
                universalLink: "https://www.ouxyi.link/ul/uYJPB0?entityId=5",
                bridgeUrl: "https://www.okx.com/tonbridge/discover/rpc/bridge",
                jsBridgeKey: "okxTonWallet",
                platforms: ["chrome", "safari", "firefox", "ios", "android"]
              },
              {
                appName: "fintopio-tg",
                name: "Fintopio Telegram",
                imageUrl: "https://fintopio.com/tonconnect-icon.png",
                aboutUrl: "https://fintopio.com",
                universalLink: "https://t.me/fintopio?attach=wallet",
                bridgeUrl: "https://wallet-bridge.fintopio.com/bridge",
                platforms: ["ios", "android", "macos", "windows", "linux"]
              },
              {
                appName: "GateWallet",
                name: "GateWallet",
                imageUrl: "https://www.gate.io/images/login/qrcode_center_icon.svg",
                aboutUrl: "https://www.gate.io/",
                bridgeUrl: "https://dapp.gateio.services/tonbridge_api/bridge/v1",
                jsBridgeKey: "gatetonwallet",
                platforms: ["ios", "android", "chrome"],
                universalLink: "https://gateio.onelink.me/DmA6/web3"
              },
              {
                appName: "tokenpocket",
                name: "TokenPocket",
                imageUrl: "https://hk.tpstatic.net/logo/tokenpocket.png",
                aboutUrl: "https://tokenpocket.pro",
                jsBridgeKey: "tokenpocket",
                platforms: ["ios", "android"]
              },
              {
                appName: "hot",
                name: "HOT",
                imageUrl: "https://storage.herewallet.app/logo.png",
                aboutUrl: "https://hot-labs.org/",
                universalLink: "https://t.me/herewalletbot?attach=wallet",
                bridgeUrl: "https://sse-bridge.hot-labs.org",
                jsBridgeKey: "hotWallet",
                platforms: ["ios", "android", "macos", "windows", "linux"]
              },
              {
                appName: "bybitTonWallet",
                name: "Bybit Wallet",
                imageUrl: "https://static.bymj.io/bhop/image/Q3Kmzw7qczSZF5eqfo6pW8QuT1MDMmqC80lWxFBhiE0.png",
                aboutUrl: "https://www.bybit.com/web3",
                universalLink: "https://app.bybit.com/ton-connect",
                deepLink: "bybitapp://",
                jsBridgeKey: "bybitTonWallet",
                bridgeUrl: "https://api-node.bybit.com/spot/api/web3/bridge/ton/bridge",
                platforms: ["ios", "android", "chrome"]
              },
              {
                appName: "dewallet",
                name: "DeWallet",
                imageUrl: "https://raw.githubusercontent.com/delab-team/manifests-images/main/WalletAvatar.png",
                aboutUrl: "https://delabwallet.com",
                universalLink: "https://t.me/dewallet?attach=wallet",
                bridgeUrl: "https://bridge.dewallet.pro/bridge",
                platforms: ["ios", "android", "macos", "windows", "linux"]
              },
            ]
          }}
          actionsConfiguration={{
            twaReturnUrl: 'https://t.me/Hamster_kombat_67_bot/HamsterKombat'
          }}
        >
          <UserContext>
            <BrowserRouter>
              <Routes>
                <Route path='/' element={<ProtectedRoute><Home /></ProtectedRoute>} />
                <Route path='/earn' element={<ProtectedRoute><Earn /></ProtectedRoute>} />
                <Route path='/circle' element={<ProtectedRoute><CircleComponent /></ProtectedRoute>} />
                <Route path='/mine' element={<ProtectedRoute><Mine /></ProtectedRoute>} />
                <Route path='/friends' element={<ProtectedRoute><Friends /></ProtectedRoute>} />
                <Route path='/airdrop' element={<ProtectedRoute><Airdrop /></ProtectedRoute>} />
                <Route path='/setting' element={<ProtectedRoute><Settings /></ProtectedRoute>} />
                <Route path='/exchange' element={<ProtectedRoute><Exchange /></ProtectedRoute>} />
                <Route path='/language' element={<ProtectedRoute><Language /></ProtectedRoute>} />
                <Route path='/boost' element={<ProtectedRoute><Boost /></ProtectedRoute>} />
                <Route path='/cipher' element={<ProtectedRoute><DailyCipher /></ProtectedRoute>} />
                <Route path='/invite/:id' element={<ProtectedRoute><Invite /></ProtectedRoute>} />
                <Route path='/deposit' element={<ProtectedRoute><Deposit /></ProtectedRoute>} />
                <Route path='/withdraw' element={<ProtectedRoute><Withdraw /></ProtectedRoute>} />
                <Route path='/false-time' element={<FalseTime />} />
                <Route path='/join-tg' element={<JoinTg />} />
                <Route path='/suspend' element={<Suspend />} />
              </Routes>
            </BrowserRouter>
          </UserContext>
        </TonConnectUIProvider>
      </div>
    </>
  )
}