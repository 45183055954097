import React from 'react'
import coinAnimation from '../Images/coin-animation.gif'

export default function CoinAnimation() {
    return (
        <div className="position-fixed w-100 h-100 z-1000">
            <img src={coinAnimation} style={{ width: "100%", height: "100%" }} alt="" />
        </div>
    )
}
