import React, { createContext, useEffect, useState } from 'react'
import firebaseApp from '../Firebase/firebase';
import { makeid } from '../Helper/helper';
import axios from 'axios';
import moment from 'moment';
import { LocalstorageNotFoundError, useTonAddress } from '@tonconnect/ui-react';
import { encryptData } from '../encrypt';
import { chineseWords, EnglishWords, gujaratiWords, HindiWords, russianWords, urduWords } from '../Helper/language';

export const context = createContext()

export default function UserContext(props) {
    const [userData, setUserData] = useState([])
    const [userName, setUserName] = useState(null)
    const [showLoader, setShowLoader] = useState(true)
    const [profitPerHour, setProfitPerHour] = useState(0)
    const [points, setPoints] = useState(0)
    const [powerObj, setPowerObj] = useState({})
    const [remainingPower, setRemainingPower] = useState(null)
    const [profitPerHourPlus, setProfitPerHourPlus] = useState(true)
    const [price, setPrice] = useState(0)
    const [showSplash, setShowSplash] = useState(true)
    const [lang, setLang] = useState('en')

    const langJson = lang == 'hi' ? HindiWords : lang == 'gu' ? gujaratiWords : lang == 'ru' ? russianWords : lang == 'ur' ? urduWords : lang == 'ch' ? chineseWords : EnglishWords

    useEffect(() => {
        setTimeout(() => {
            setShowSplash(false)
        }, 2000);
    }, [])

    useEffect(() => {
        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'en')
        } else {
            setLang(localStorage.getItem('lang'))
        }
        getTokenPrice()
        const interval = setInterval(() => {
            getTokenPrice()
        }, 10000);
    }, [])

    const getTokenPrice = () => {
        // let payload = {
        //     amount: "1000000000",
        //     from: "jetton:0:038fcde7e8e0126da86deb27ff551f216a636eadb90f638811a10026fbb01ccf",
        //     to: "jetton:0:b113a994b5024a16719f69139328eb759596c38a25f59028b146fecdc3621dfe"
        // }
        axios.get("https://tonapi.io/v2/rates?tokens=ton&currencies=usd")
            .then((res) => {
                let price = Number(res.data.rates.TON.prices.USD)
                setPrice(price)
                // let price = Number(res.data[0][1]?.amountOut)

            })
    }


    const [offlineReward, setOfflineReward] = useState(0)
    useEffect(() => {
        if (!localStorage.getItem('vibration')) {
            localStorage.setItem('vibration', true)
        }
        if (!localStorage.getItem('coinAnimation')) {
            localStorage.setItem('coinAnimation', true)
        }
        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.ready();
            const tg = window.Telegram.WebApp
            tg.ready()
            // tg.disableVerticalSwipes()
            const userDetails = tg.initDataUnsafe?.user;
            if (userDetails) {
                setUserName(userDetails)
            } else {
                setUserName("not defined")
            }
        }


    }, [])

    useEffect(() => {
        if (Number(localStorage.getItem('lastVisit')) + 90000 <= Date.now()) {
            let time = Number(localStorage.getItem('lastVisit'))
            if ((time + 15 * 60 * 1000) <= Date.now()) {
                setEnergyLimit(userData?.power?.capacity)
            } else {
                let pendingMiliSeconds = Date.now() - time
                let energyToAdd = (pendingMiliSeconds / 1000) * 3
                if (energyToAdd >= userData?.power?.capacity) {
                    setEnergyLimit(userData?.power?.capacity)
                } else {
                    setEnergyLimit(energyToAdd)
                }
            }
        }
    }, [])


    const setEnergyLimit = (energy) => {
        let power = userData?.power
        if (power) {
            power.remaining = energy
            let obj = {
                id: userName.id,
                power: power
            }
            const encrypted = encryptData(obj)
            axios.post(process.env.REACT_APP_API_URL + '/user/update-power', { data: encrypted })
                .then((res) => {
                    if (res.status == 200) {
                        getUserData()
                    }
                })
        }
    }

    useEffect(() => {
        const timeout = setInterval(() => {
            localStorage.setItem('lastVisit', Date.now())
        }, 5000);
        return () => {
            clearInterval(timeout)
        }
    }, [])

    useEffect(() => {
        if (!profitPerHourPlus) {
            const timeout = setTimeout(() => {
                handleClickInDB()
                let obj = userData
                obj.mypoints = points
                setUserData(obj)
            }, 1000);
            return () => {
                clearTimeout(timeout)
            }
        }
    }, [points])

    useEffect(() => {
        if (userData?.power?.capacity != Number(remainingPower)) {
            if (userData?.power?.capacity > Number(remainingPower) + 3) {
                let timeout = setTimeout(() => {
                    setRemainingPower(Number(remainingPower) + 3)
                }, 1000);
                return () => {
                    clearTimeout(timeout)
                }
            } else {
                let timeout = setTimeout(() => {
                    if (userData?.power?.capacity > Number(remainingPower)) {
                        // fullCapacity()
                        console.log("first")
                        setRemainingPower(userData?.power?.capacity)
                    }
                    handleClickInDB(userData?.power?.capacity)
                }, 1000);
                return () => {
                    clearTimeout(timeout)
                }

            }
        }

    }, [remainingPower])



    useEffect(() => {
        setProfitPerHourPlus(true)
        let intervalId;
        let accumulatedPoints = 0;

        intervalId = setInterval(() => {
            const pointsPerSecond = userData?.profitPerHour / 3600; // 3600 seconds in an hour
            accumulatedPoints += pointsPerSecond;

            if (accumulatedPoints >= 1) {
                setPoints(points + Math.floor(accumulatedPoints));
                accumulatedPoints -= Math.floor(accumulatedPoints);
            }
        }, 1000); // 1000 milliseconds in a second
        return () => clearInterval(intervalId);
    }, [points]);

    const [lastTime, setLastTime] = useState('')

    useEffect(() => {
        if (lastTime != '') {
            if ((Number(lastTime) + 60000) <= Date.now()) {
                setLastTime("")
                handleClickInDB()
            }
        } else {
            setLastTime(Date.now())
        }
    }, [points]);




    const UpdateFreeEnergy = () => {
        if (userData?.fullEnergy > 0 && (localStorage.getItem('freeEnergyTime') && Number(localStorage.getItem('freeEnergyTime')) + 60 * 60 * 1000) < Date.now()) {
            axios.post(process.env.REACT_APP_API_URL + '/user/use-free-energy', { data: encryptData({ id: userName.id }) })
                .then((res) => {
                    if (res.status == 200) {
                        setUserData(res.data)
                    }
                })
        }
    }

    // const RemoveAchivedCombo = (userData) => {
    //     const db = firebaseApp.firestore();
    //     db.collection('Users').where("tgId", "==", userName.id).get().then((querySnapshot) => {
    //         querySnapshot.forEach((doc) => {
    //             var updateCollection = db.collection("Users").doc(doc.ref.id);
    //             axios.get('https://api.timezonedb.com/v2.1/get-time-zone?key=1V3OSQAQ2QDQ&format=json&by=zone&zone=Asia/Kolkata')
    //                 .then((res) => {
    //                     // console.log(res)
    //                     const realtime = new Date(res.data.formatted)
    //                     let realtimeInFormat = moment(realtime).format('DD-MM-YYYY')
    //                     let systeDateInFormat = moment(Date.now()).format("DD-MM-YYYY")
    //                     console.log(realtime, realtimeInFormat, systeDateInFormat)
    //                     if (realtimeInFormat == systeDateInFormat) {
    //                         return updateCollection.update({
    //                             achivedCombo: [],
    //                             comboAchive: false,
    //                             dailyCipher: false,
    //                             dailyTask: false
    //                         })
    //                             .then(() => {
    //                                 let user = userData
    //                                 user.achivedCombo = []
    //                                 user.comboAchive = false
    //                                 user.dailyCipher = false
    //                                 user.dailyTask = false
    //                                 setUserData(user)
    //                                 getUserData()
    //                                 localStorage.removeItem('lastComboTime')
    //                             })
    //                             .catch((error) => {
    //                                 // The document probably doesn't exist.
    //                                 console.error("Error updating document: ", error);
    //                             });
    //                     }
    //                 }).catch(error => console.error('Error fetching the real-time data:', error));

    //         })
    //     }).catch(err => {
    //         console.error(err)
    //     });
    // }

    // const removeDailyCipher = (userData) => {
    //     const db = firebaseApp.firestore();
    //     db.collection('Users').where("tgId", "==", userName.id).get().then((querySnapshot) => {
    //         querySnapshot.forEach((doc) => {
    //             var updateCollection = db.collection("Users").doc(doc.ref.id);
    //             axios.get('https://api.timezonedb.com/v2.1/get-time-zone?key=1V3OSQAQ2QDQ&format=json&by=zone&zone=Asia/Kolkata')
    //                 .then((res) => {
    //                     // console.log(res)
    //                     const realtime = new Date(res.data.formatted)
    //                     let realtimeInFormat = moment(realtime).format('DD-MM-YYYY')
    //                     let systeDateInFormat = moment(Date.now()).format("DD-MM-YYYY")
    //                     console.log(realtime, realtimeInFormat, systeDateInFormat)
    //                     if (realtimeInFormat == systeDateInFormat) {
    //                         return updateCollection.update({
    //                             dailyCipher: false
    //                         })
    //                             .then(() => {
    //                                 let user = userData
    //                                 user.dailyCipher = false
    //                                 setUserData(user)
    //                                 localStorage.removeItem('lastCipherTime')
    //                             })
    //                             .catch((error) => {
    //                                 // The document probably doesn't exist.
    //                                 console.error("Error updating document: ", error);
    //                             });
    //                         // return true
    //                     }
    //                 }).catch(error => console.error('Error fetching the real-time data:', error));

    //         })
    //     }).catch(err => {
    //         console.error(err)
    //     });
    // }
    const [isAble, setIsAble] = useState(true)

    useEffect(() => {
        if (userName != null) {
            console.log(userName)
            getUserData();
        }
    }, [userName]);// Make sure userName is the correct dependency to trigger this effect




    useEffect(() => {
        // axios.post('https://us-central1-hamster-kombat-clone-dab00.cloudfunctions.net/price/start-fetching', {})
        //     .then((res) => {
        //         console.log(res)
        //     })
        const handleUnload = () => {
            const timestamp = new Date().getTime(); // Get current timestamp
            // localStorage.setItem('lastVisit', timestamp); // Store timestamp in localStorage
        };
        window.Telegram.WebApp.ready();
        window.Telegram.WebApp.onEvent('close', function () {
            localStorage.setItem('lastVisit', Date.now())
        });


        // Add event listener for beforeunload
        window.addEventListener('beforeunload', handleUnload);

        // Clean up the event listener when component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };

    }, []);

    const handleClickInDB = async (capacity) => {
        if (Object.keys(userData).length > 0) {
            const docRef = localStorage.getItem('docRef'); // Assuming this is the ID of the document in Firestore

            setProfitPerHourPlus(true)
            let power = userData?.power
            if (power) {
                if (capacity) {
                    power.remaining = capacity
                    // setRemainingPower(capacity)
                    // getUserData()
                } else {
                    power.remaining = remainingPower
                }
            }
            let obj = {
                id: docRef,
                points: points,
                power: power
            }
            let encrypt = encryptData(obj)
            axios.post(process.env.REACT_APP_API_URL + '/user/circle-click', { data: encrypt })
                .then((res) => {
                    if (res.status == 200) {
                        localStorage.setItem('lastVisit', Date.now())
                    }
                })
            // try {
            //     await firebaseApp.firestore().runTransaction(async (transaction) => {
            //         const userRef = firebaseApp.firestore().collection("Users").doc(docRef);
            //         const doc = await transaction.get(userRef);
            //         // console.log(doc)

            //         if (!doc.exists) {
            //             throw new Error('User document does not exist!');
            //         }
            //         setProfitPerHourPlus(true)
            //         let power = userData?.power
            //         if (power) {
            //             if (capacity) {
            //                 power.remaining = capacity
            //                 // setRemainingPower(capacity)
            //                 // getUserData()
            //             } else {
            //                 power.remaining = remainingPower
            //             }
            //         }
            //         if (doc.data().mypoints != points || doc.data().power.remaing != remainingPower) {

            //             transaction.update(userRef, { mypoints: points, power: power });
            //             localStorage.setItem('lastVisit', Date.now())
            //             console.log('Transaction successfully committed!');
            //         }
            //         // Update 'mypoints' field in the document

            //     });
            // } catch (error) {
            //     console.error('Transaction failed: ', error);
            // }
        }
    };

    const addNewUser = (id, tgData) => {
        console.log("Adding new user with refId:", id);

        if (!localStorage.getItem('userid')) {
            const userid = makeid(10);
            const newUser = {
                date: Date.now(),
                username: makeid(12),
                id: userid,
                telegramId: tgData,
                profitPerHour: 0,
                lvl: 0,
                mypoints: 5000,
                fullEnergy: 6,
                waitingSpecialList: [],
                myBalance: 0,
                myDeposit: 10,
                dailyTask: false,
                status: 1,
                power: {
                    capacity: 500,
                    remaining: 500
                },
                refId: id || "",
                boost: [
                    { multitap: 2, point: 2 },
                    { energy: 1, power: 500 }
                ],
                myMiners: [],
                myFriends: [],
                specialCard: [],
                tonId: "",
                earn: {
                    video: [],
                    Daily: []
                },
                tgId: tgData.id,
                TgTask: false,
                Xtask: false,
                ExchangeTask: false,
                inviteFriend: false,
                waitingList: [],
                achivedCombo: [],
                ExchangeName: "",
                dailyCipher: false,
                comboAchive: false,
            };
            const obj = encryptData(newUser)
            console.log(newUser, "After Obj")
            axios.post(process.env.REACT_APP_API_URL + '/user/add-user', { data: obj })
                .then((res) => {
                    localStorage.setItem("docRef", res._id);
                    localStorage.setItem('userid', newUser.id);
                    setProfitPerHour(newUser.profitPerHour);
                    setPoints(newUser.mypoints);
                    setRemainingPower(newUser.power.remaining);
                    setPowerObj(newUser.power);
                    const packages = {
                        id: makeid(10),
                        date: Date.now(),
                        investAmount: 10,
                        isPackageCompleted: false,
                        totalGet: 0,
                        totalReward: 30,
                        userid: newUser.id,
                        Earning: [],
                        Type: 1
                    }
                    axios.post(`${process.env.REACT_APP_API_URL}` + '/package/add-package', { data: encryptData(packages) })
                        .then((res) => {
                            setShowLoader(false);
                            getUserData()
                            if (id) {
                                addPointsAndFriendToInviter(newUser, id);
                            }
                        })
                    // setUserData(newUser);
                })


            // Reset 'able' status after 15 seconds
            setTimeout(() => {
                setIsAble(true);
                localStorage.setItem('able', 'true');
            }, 15000);
        }
    };

    const addPointsAndFriendToInviter = (newUser, referrerId) => {
        let friendData = {
            id: makeid(10),
            friendId: newUser.id,
            friendReward: 0,
            friendPointsReward: 25000,
        };
        let obj = {
            id: referrerId,
            friendObj: friendData,
            points: 25000
        }
        const decrypted = encryptData(obj)
        axios.post(process.env.REACT_APP_API_URL + '/user/add-friend', { data: decrypted })
            .then((res) => {
                if (res.status == 200) {
                    console.log("refer added successfully")
                }
            })
    };

    const address = useTonAddress()


    const getUserData = () => {
        let tgData;
        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.ready();
            const tg = window.Telegram.WebApp
            tg.ready()
            // tg.disableVerticalSwipes()
            const userDetails = tg.initDataUnsafe?.user;
            if (userDetails) {
                console.log(userDetails)
                tgData = userDetails
                setShowLoader(true);
                const id = userName ? userName.id : "";
                console.log(id)
                if (id) {
                    axios.post(process.env.REACT_APP_API_URL + '/user/get-user', { id: id })
                        .then((res) => {
                            if (res.status == 200) {
                                console.log(res.data)
                                if (res.data.length <= 0) {
                                    let refId = '';
                                    if (window.Telegram.WebApp) {
                                        const tg = window.Telegram.WebApp;
                                        tg.ready();
                                        refId = tg.initDataUnsafe.start_param;
                                    }
                                    if (localStorage.getItem('userid')) {
                                        setShowLoader(false)
                                    }
                                    if (refId && refId !== localStorage.getItem('userRefId')) {
                                        localStorage.setItem('userRefId', refId);
                                        addNewUser(refId, tgData);
                                    } else {
                                        addNewUser("", tgData);
                                    }
                                } else {
                                    let user = res.data;
                                    user.telegramId = tgData;
                                    setProfitPerHour(user.profitPerHour);
                                    setPoints(user.mypoints);
                                    setPowerObj(user.power);

                                    if (localStorage.getItem('lastVisit')) {
                                        if (Number(localStorage.getItem('lastVisit')) + 15 * 60 * 1000 <= Date.now()) {
                                            UpdateFreeEnergy();
                                        }
                                    }

                                    if (user.fullEnergy < 6 && moment().format('DD-MM-YYYY') !== moment(JSON.parse(localStorage.getItem('freeEnergyTime'))).format('DD-MM-YYYY')) {
                                        UpdateFullEnergy();
                                        user.fullEnergy = 6;
                                    }

                                    setUserData(user);
                                    localStorage.setItem('userid', user.id);
                                    localStorage.setItem('docRef', user._id);
                                    if (address) {
                                    }

                                    if (address && user.tonId !== address) {
                                        console.log(address, user.tonId)
                                        updateTonId();
                                    }

                                    if (user.refId === "") {
                                        localStorage.removeItem('userRefId');
                                    }
                                    if (user.refId !== "" && !localStorage.getItem('userRefId')) {
                                        localStorage.setItem('userRefId', user.refId);
                                    }

                                    if (Number(localStorage.getItem('lastVisit'))) {
                                        const ms = Date.now() - Number(localStorage.getItem('lastVisit'));
                                        const sec = ms / 1000;
                                        const profitPerSec = user.profitPerHour / 3600;
                                        const reward = Number(profitPerSec * sec).toFixed(0);
                                        setOfflineReward(reward);

                                        let remaining = Number(sec) * 3;
                                        if (remaining > Number(user.power?.capacity) - Number(user.power?.remaining)) {
                                            setRemainingPower(Number(user.power?.capacity).toFixed(0));
                                        } else {
                                            setRemainingPower(Number(Number(user.power?.remaining) + remaining).toFixed(0));
                                        }
                                    } else {
                                        setRemainingPower(user.power.remaining);
                                    }
                                    setShowLoader(false)
                                }
                            }
                        })
                }
            } else {
                setUserName("not defined")
            }
        }

    }

    // const querySnapshot = await db.collection('Users').where('tgId', '==', id).orderBy('date').limit(1).get();

    // if (querySnapshot.size === 0) {
    //     // Handle user not found case
    //     let refId = '';
    //     if (window.Telegram.WebApp) {
    //         const tg = window.Telegram.WebApp;
    //         tg.ready();
    //         refId = tg.initDataUnsafe.start_param;
    //     }
    //     if (localStorage.getItem('userid')) {
    //         setShowLoader(false)
    //     }
    //     if (refId && refId !== localStorage.getItem('userRefId')) {
    //         localStorage.setItem('userRefId', refId);
    //         addNewUser(refId);
    //     } else {
    //         addNewUser(refId);
    //     }
    // } else {
    //     querySnapshot.forEach(async (doc) => {
    //         const user = doc.data();
    //         user.telegramId = userName;
    //         setProfitPerHour(user.profitPerHour);
    //         setPoints(user.mypoints);
    //         setPowerObj(user.power);

    //         if (localStorage.getItem('lastVisit')) {
    //             if (Number(localStorage.getItem('lastVisit')) + 15 * 60 * 1000 <= Date.now()) {
    //                 UpdateFreeEnergy();
    //             }
    //         }

    //         if (user.fullEnergy < 6 && moment().format('DD-MM-YYYY') !== moment(JSON.parse(localStorage.getItem('freeEnergyTime'))).format('DD-MM-YYYY')) {
    //             UpdateFullEnergy();
    //             user.fullEnergy = 6;
    //         }

    //         setUserData(user);
    //         localStorage.setItem('userid', doc.data().id);
    //         localStorage.setItem('docRef', doc.ref.id);

    //         if (address && user.tonId !== address) {
    //             updateTonId();
    //         }

    //         if (user.refId === "") {
    //             localStorage.removeItem('userRefId');
    //         }
    //         if (user.refId !== "" && !localStorage.getItem('userRefId')) {
    //             localStorage.setItem('userRefId', user.refId);
    //         }

    //         if (Number(localStorage.getItem('lastVisit'))) {
    //             const ms = Date.now() - Number(localStorage.getItem('lastVisit'));
    //             const sec = ms / 1000;
    //             const profitPerSec = user.profitPerHour / 3600;
    //             const reward = Number(profitPerSec * sec).toFixed(0);
    //             setOfflineReward(reward);

    //             let remaining = Number(sec) * 3;
    //             if (remaining > Number(user.power.capacity) - Number(user.power.remaining)) {
    //                 setRemainingPower(Number(user.power.capacity).toFixed(0));
    //             } else {
    //                 setRemainingPower(Number(Number(user.power.remaining) + remaining).toFixed(0));
    //             }
    //         } else {
    //             setRemainingPower(user.power.remaining);
    //         }

    //         // let startOfToday = new Date();
    //         // startOfToday.setHours(5, 30, 0, 0);
    //         // if (!localStorage.getItem('lastComboTime')) {
    //         //     localStorage.setItem('lastComboTime', startOfToday.getTime())
    //         // }

    //         // if (localStorage.getItem('lastComboTime')) {
    //         //     if (localStorage.getItem('lastComboTime') != startOfToday.getTime()) {
    //         //         RemoveAchivedCombo(doc.data());
    //         //     }
    //         // }

    //         // if (localStorage.getItem('lastCipherTime')) {
    //         //     if (localStorage.getItem('lastCipherTime') != startOfToday.getTime()) {
    //         //         removeDailyCipher(doc.data());
    //         //     }
    //         // }
    //         setShowLoader(false);
    //     });
    // }



    const UpdateFullEnergy = () => {
        axios.post(process.env.REACT_APP_API_URL + '/user/full-energy', { data: encryptData({ id: userName.id }) })
    }

    const updateTonId = () => {
        axios.post(process.env.REACT_APP_API_URL + '/user/update-wallet', { id: userName.id, wallet: address })
    }


    return (
        <context.Provider value={{ userData, setUserData, getUserData, showSplash, langJson, setLang, userName, showLoader, offlineReward, points, setPoints, powerObj, setPowerObj, remainingPower, setRemainingPower, profitPerHourPlus, setProfitPerHourPlus, price }}>
            {props.children}
        </context.Provider>
    )
}
