import React, { useContext, useEffect } from 'react'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import binancelogo from '../Images/binance-logo.png'
import bybit from '../Images/bybit-logo.png'
import BingX from '../Images/bingX.png'
import htx from '../Images/htx-removebg-preview.png'
import kucoin from '../Images/Kucoin.png'
import okx from '../Images/okx-logo.png'
import mexc from '../Images/mexc-logo.png'
import crypto from '../Images/crypto-logo.png'
import gate from '../Images/gate.png'
import bitget from '../Images/bitget.png'
import firebaseApp from '../Firebase/firebase'
import { FaCheck } from 'react-icons/fa'
import { context } from '../Context/UserContext'
import { IoMdCheckmark } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'


export default function Exchange() {

    const { userData, setUserData, langJson } = useContext(context)
    const Navigate = useNavigate()
    useEffect(() => {
        if (window.Telegram.WebApp) {
            const tg = window.Telegram.WebApp;

            // Initialize the backButton
            tg.BackButton.show();
            tg.BackButton.onClick(() => {
                // Handle back button click
                Navigate('/')
                tg.BackButton.hide();
            });

            // Cleanup on component unmount
            return () => {
                tg.BackButton.offClick();
                tg.BackButton.hide();
            };
        }
    }, [])

    const setExchangeName = (ExchangeName) => {
        axios.post(`${process.env.REACT_APP_API_URL}` + '/user/change-exchange', { id: localStorage.getItem('docRef'), exName: ExchangeName })
            .then((res) => {
                if (res.status == 200) {
                    setUserData(res.data)
                    Navigate('/')
                }
            })


    }

    return (
        <>
            <div className="bg-black d-flex justify-content-center">
                <div className="w-100 bg-black text-white h-screen font-bold">
                    <h1 className='text-center mt-4 airdrop-title'>{langJson.chooseExchange}</h1>
                    <div className='mx-3 mt-3' onClick={() => setExchangeName("Binance")}>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div>
                                <img src={binancelogo} className='exchange-image' height={40} width={40} alt="no image found" />
                            </div>
                            <div className='ms-2'>
                                <p>Binance</p>
                            </div>
                            <div className='ms-auto me-3'>
                                {userData?.ExchangeName == "Binance" ? <IoMdCheckmark className='setting-arrow' /> : <MdOutlineKeyboardArrowRight className='setting-arrow' />}
                            </div>
                        </div>
                    </div>

                    <div className='mx-3 mt-1' onClick={() => setExchangeName("OKX")}>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div>
                                <img className='exchange-image' src={okx} height={40} width={40} alt="no image found" />
                            </div>
                            <div className='ms-2'>
                                <p>OKX</p>
                            </div>
                            <div className='ms-auto me-3'>
                                {userData?.ExchangeName == "OKX" ? <IoMdCheckmark className='setting-arrow' /> : <MdOutlineKeyboardArrowRight className='setting-arrow' />}
                            </div>
                        </div>
                    </div>

                    <div className='mx-3 mt-1' onClick={() => setExchangeName("Crypto.com")}>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div>
                                <img className='exchange-image' src={crypto} height={40} width={40} alt="no image found" />
                            </div>
                            <div className='ms-2'>
                                <p>Crypto.com</p>
                            </div>
                            <div className='ms-auto me-3'>
                                {userData?.ExchangeName == "Crypto.com" ? <IoMdCheckmark className='setting-arrow' /> : <MdOutlineKeyboardArrowRight className='setting-arrow' />}

                            </div>
                        </div>
                    </div>

                    <div className='mx-3 mt-1' onClick={() => setExchangeName("Bybit")}>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div>
                                <img className='exchange-image' src={bybit} height={40} width={40} alt="no image found" />
                            </div>
                            <div className='ms-2'>
                                <p>Bybit</p>
                            </div>
                            <div className='ms-auto me-3'>
                                {userData?.ExchangeName == "Bybit" ? <IoMdCheckmark className='setting-arrow' /> : <MdOutlineKeyboardArrowRight className='setting-arrow' />}

                            </div>
                        </div>
                    </div>

                    <div className='mx-3 mt-1' onClick={() => setExchangeName("BingX")}>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div>
                                <img className='exchange-image' src={BingX} height={40} width={40} alt="no image found" />
                            </div>
                            <div className='ms-2'>
                                <p>BingX</p>
                            </div>
                            <div className='ms-auto me-3'>
                                {userData?.ExchangeName == "BingX" ? <IoMdCheckmark className='setting-arrow' /> : <MdOutlineKeyboardArrowRight className='setting-arrow' />}

                            </div>
                        </div>
                    </div>

                    <div className='mx-3 mt-1' onClick={() => setExchangeName("HTX")}>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div>
                                <img src={htx} height={40} width={40} alt="no image found" />
                            </div>
                            <div className='ms-2'>
                                <p>HTX</p>
                            </div>
                            <div className='ms-auto me-3'>
                                {userData?.ExchangeName == "HTX" ? <IoMdCheckmark className='setting-arrow' /> : <MdOutlineKeyboardArrowRight className='setting-arrow' />}

                            </div>
                        </div>
                    </div>

                    <div className='mx-3 mt-1' onClick={() => setExchangeName("Kucoin")}>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div>
                                <img className='exchange-image' src={kucoin} height={40} width={40} alt="no image found" />
                            </div>
                            <div className='ms-2'>
                                <p>Kucoin</p>
                            </div>
                            <div className='ms-auto me-3'>
                                {userData?.ExchangeName == "Kucoin" ? <IoMdCheckmark className='setting-arrow' /> : <MdOutlineKeyboardArrowRight className='setting-arrow' />}

                            </div>
                        </div>
                    </div>

                    <div className='mx-3 mt-1' onClick={() => setExchangeName("Gate.io")}>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div>
                                <img className='exchange-image' src={gate} height={40} width={40} alt="no image found" />
                            </div>
                            <div className='ms-2'>
                                <p>Gate.io</p>
                            </div>
                            <div className='ms-auto me-3'>
                                {userData?.ExchangeName == "Gate.io" ? <IoMdCheckmark className='setting-arrow' /> : <MdOutlineKeyboardArrowRight className='setting-arrow' />}

                            </div>
                        </div>
                    </div>

                    <div className='mx-3 mt-1' onClick={() => setExchangeName("MEXC")}>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div>
                                <img className='exchange-image' src={mexc} height={40} width={40} alt="no image found" />
                            </div>
                            <div className='ms-2'>
                                <p>MEXC</p>
                            </div>
                            <div className='ms-auto me-3'>
                                {userData?.ExchangeName == "MEXC" ? <IoMdCheckmark className='setting-arrow' /> : <MdOutlineKeyboardArrowRight className='setting-arrow' />}

                            </div>
                        </div>
                    </div>

                    <div className='mx-3 mt-1' onClick={() => setExchangeName("Bitget")}>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div>
                                <img className='exchange-image' src={bitget} height={40} width={40} alt="no image found" />
                            </div>
                            <div className='ms-2'>
                                <p>Bitget</p>
                            </div>
                            <div className='ms-auto me-3'>
                                {userData?.ExchangeName == "Bitget" ? <IoMdCheckmark className='setting-arrow' /> : <MdOutlineKeyboardArrowRight className='setting-arrow' />}

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
