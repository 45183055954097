import React, { useContext, useEffect, useState } from 'react'
import usdtlogo from '../Images/USDT.png'
import { IoArrowDownCircleOutline, IoArrowUpCircleOutline } from 'react-icons/io5'
import firebaseApp from '../Firebase/firebase'
import { makeid } from '../Helper/helper'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react'
import { context } from '../Context/UserContext'
import Loader from '../Components/Loader'
import hamsterKoin from '../Images/hamster-coin.png'
import millify from 'millify'
import moment from 'moment'
import { encryptData } from '../encrypt'
import { driver } from 'driver.js'
import tonLogo from '../Images/ton-3d.png'

export default function Withdraw() {


    const [amount, setAmount] = useState(10)
    const [transactionarray, setTransactionArray] = useState([])
    const navigate = useNavigate()
    const { userData, setUserData, getUserData, userName, langJson, showLoaderContext, offlineReward, points, setPoints, powerObj, setPowerObj, remainingPower, setRemainingPower, profitPerHourPlus, setProfitPerHourPlus, price } = useContext(context)

    const driverObj = driver({
        showProgress: true,
        steps: [
            {
                element: "#witInput",
                popover: {
                    title: langJson.witt1,
                    description: langJson.wit1,
                }
            },
            {
                element: "#witBtn",
                popover: {
                    title: langJson.witt2,
                    description: langJson.wit2,
                }
            },
        ]
    })

    const [showLoader, setShowLoader] = useState(false)
    useEffect(() => {
        getUserData()
        getTranscation()
        if (!localStorage.getItem('witGuide')) {
            driverObj.drive()
            localStorage.setItem('witGuide', "true")
        }
        if (window.Telegram.WebApp) {
            const tg = window.Telegram.WebApp;

            // Initialize the backButton
            tg.BackButton.show();
            tg.BackButton.onClick(() => {
                // Handle back button click
                navigate('/')
                tg.BackButton.hide();
            });

            // Cleanup on component unmount
            return () => {
                tg.BackButton.offClick();
                tg.BackButton.hide();
            };
        }
    }, [])

    const userAddress = useTonAddress()
    const [tonConnectUI] = useTonConnectUI()
    const [isAble, setIsAble] = useState(true)
    const CheckWithdrawalAmt = async () => {
        // let id = makeid(10)
        // const body = {
        //     apikey: process.env.REACT_APP_API_KEY,
        //     toaddress: userAddress,
        //     amount: (Number(amount / price).toFixed(2)).toString(),
        //     usdAmount: Number(amount).toString(),
        //     userid: localStorage.getItem('userid'),
        //     currentBalance: userData?.myBalance
        // }
        // const encryptedData = encryptData(body)
        // // { data: encryptedData }
        // axios.post(process.env.REACT_APP_API_URL + '/withdraw', { data: encryptedData })
        //     .then((res) => {
        //         if (res.status == '200') {
        //             getTranscation()
        //             getUserData()
        //             setShowLoader(false)
        //             toast.success('Amount withdraw successfully', {
        //                 style: {
        //                     borderRadius: '10px',
        //                     background: '#333',
        //                     color: '#fff',
        //                     marginTop: "10px"
        //                 },
        //             })
        //         } else {
        //             toast.error("Withdraw failed!")
        //         }
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //     })
        if (isAble) {
            if (userAddress) {
                if (amount >= 10 && userData?.myBalance >= amount) {
                    setShowLoader(true)
                    // await AddTransaction(id)

                    const body = {
                        apikey: process.env.REACT_APP_API_KEY,
                        toaddress: userAddress,
                        amount: (Number(amount / price).toFixed(2)).toString(),
                        usdAmount: Number(amount).toString(),
                        userid: localStorage.getItem('userid'),
                        currentBalance: userData?.myBalance
                    }
                    const encryptedData = encryptData(body)
                    // { data: encryptedData }
                    axios.post(process.env.REACT_APP_API_URL + '/withdraw', { data: encryptedData })
                        .then((res) => {
                            if (res.status == '200') {
                                getTranscation()
                                getUserData()
                                setShowLoader(false)
                                toast.success('Amount withdraw successfully', {
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                        marginTop: "10px"
                                    },
                                })
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })

                } else {
                    toast.error('Invalid input', {
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                            marginTop: "10px"
                        },
                    })
                }
            } else {
                tonConnectUI.openModal()
            }
        }
    }

    const removeWithdraw = (id) => {
        axios.post(process.env.REACT_APP_API_URL + '/package/remove-withdraw', { data: encryptData({ id: id }) })
            .then((res) => {
                if (res.status == 200) {
                    getTranscation()
                    getUserData()
                    setShowLoader(false)
                    toast.error('Please try again', {
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                            marginTop: "10px"
                        },
                    })
                }
            })
    }

    const AddTransaction = (id) => {

        const withdraw = {
            id: id,
            date: Date.now(),
            walletaddress: userAddress,
            amount: amount,
            jettonAmount: amount / price,
            type: 'withdraw',
            userid: localStorage.getItem('userid'),
            currentBalance: userData?.myBalance
        }
        axios.post(`${process.env.REACT_APP_API_URL}` + '/package/add-transaction', { data: encryptData(withdraw) })
            .then((res) => {
                if (res.status == 200) {
                    MinusBalanceInUser()
                }
            })
    }

    const MinusBalanceInUser = () => {
        let obj = {
            id: localStorage.getItem('docRef'),
            amount: Number(amount)
        }
        axios.post(`${process.env.REACT_APP_API_URL}` + '/package/minus-deposit', { data: encryptData(obj) })
            .then((res) => {
                if (res.status == 200) {
                    // getTranscation()
                    // getUserData()
                    // setShowLoader(false)
                    // toast.success('Amount withdraw successfully', {
                    //     style: {
                    //         borderRadius: '10px',
                    //         background: '#333',
                    //         color: '#fff',
                    //         marginTop: "10px"
                    //     },
                    // })
                    setTimeout(() => {
                        setIsAble(true)
                    }, 10000);
                }
            })
    }


    const getTranscation = () => {
        // console.log("first")
        axios.post(`${process.env.REACT_APP_API_URL}` + '/package/get-withdraw-transaction', { id: localStorage.getItem('userid') })
            .then((res) => {
                if (res.status == 200) {
                    setTransactionArray(res.data)
                    setShowLoader(false)
                }
            })
    }

    const setRate = (rate) => {
        let amountToAdd = rate * userData?.myBalance / 100
        setAmount(amountToAdd ? amountToAdd : 0)
    }

    return (
        <>
            {showLoader ? <Loader /> : ""}
            <div className="bg-black d-flex justify-content-center">
                <div className="w-100 min-h-100 bg-black text-white h-friend-screen font-bold d-flex flex-column max-w-xl">
                    <div className="px-4 z-10 w-100">
                        <h6 className='mt-3'>{langJson.withdrawWith} @hamsterKombat</h6>
                        <p className="dull-text text-sm line-height-3">{langJson.directWithdraw}</p>
                        <p className="dull-text text-sm line-height-3">{langJson.pleaseBePatient}</p>
                        <br />
                        <div>
                            <div className='d-flex'>
                                <div>
                                    <label htmlFor="text" className='dull-text text-sm line-height-3'>{langJson.withdrawAmount}</label>
                                </div>
                                <div className='ms-auto'>
                                    <label htmlFor="text" className='dull-text text-sm line-height-3'>{langJson.available}: {Number(userData?.myBalance).toFixed(2)} USDT</label>
                                </div>
                            </div>
                            <div className='d-flex align-item-center withdrawal-input-div' id='witInput'>
                                <div>
                                    <img src={usdtlogo} height={24} width={24} alt="no image found" />
                                </div>
                                <div className='ms-3'>
                                    <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} inputMode='numeric' className='withdrawal-input' defaultValue={0.00} />
                                </div>
                                {/*<div className='d-flex'>
                                    <button className='option-add-btn-withdraw' onClick={() => setRate(25)}>25%</button>
                                    <button className='option-add-btn-withdraw' onClick={() => setRate(50)}>50%</button>
                                    <button className='option-add-btn-withdraw' onClick={() => setRate(100)}>100%</button>
                                </div>*/}
                            </div>
                            <div>
                                <label htmlFor="text" className='dull-text text-sm line-height-3'>{langJson.minimumWithdraw}:</label>
                                <img src={usdtlogo} height={15} width={15} className='ms-2' alt="no image found" /> 10
                            </div>
                        </div>
                        <button className='w-100 withdraw-btn text-white text-center position-relative mt-3' id='witBtn' onClick={CheckWithdrawalAmt}><IoArrowUpCircleOutline style={{ width: 24, height: 24 }} /> Withdraw</button>
                        <p className='mt-3'>{langJson.withdrawHistory}</p>

                        <div className="deposit-history-div mt-2">
                            {
                                transactionarray.map((item) => (
                                    <>

                                        <div className="deposit-history-item">
                                            <div className="left-histroy-icon">
                                                <IoArrowUpCircleOutline style={{ width: 30, height: 30, color: "red" }} />
                                            </div>
                                            <div className="history-desc" style={{ width: "30%" }}>
                                                {/* <p className="text-sm">1Gx9FCknxSsLfFDzFdn75Xgqx95sDp38ir</p> */}
                                                <p className='dull-text text-sm'>{moment(item.date).format('DD/MM/YYYY')}</p>
                                            </div>
                                            <div className="history-amount d-flex align-items-center" style={{ width: "20%" }}>
                                                <img src={usdtlogo} width={20} height={20} className='object-fit-cover' alt="" /> <p style={{ fontSize: 18 }}> {Number(item.amount).toFixed(2)}</p>
                                            </div>
                                            <div className="history-amount d-flex align-items-center" style={{ width: "30%" }}>
                                                <img src={tonLogo} width={20} height={20} className='object-fit-cover' alt="" /> <p style={{ fontSize: 18 }}> {Number(item?.jettonAmount).toFixed(2)}</p>
                                            </div>
                                        </div>
                                    </>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}