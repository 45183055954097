// import { useLongPress } from '@uidotdev/usehooks';
// import React, { useState } from 'react';

// const CircleComponent = () => {
//     const [count, setCount] = useState(0);
//     const [touches, setTouches] = useState([]);

//     const handleClick = () => {
//         setCount(count + 5);
//     };

//     const handleTouchStart = (e) => {
//         const touch = e.touches[0]; // Only consider the first touch for simplicity
//         setTouches([...touches, { id: touch.identifier, x: touch.clientX, y: touch.clientY }]);
//         setCount(count + 5);
//     };

//     const handleTouchMove = (e) => {
//         e.preventDefault(); // Prevent scrolling
//         const touch = e.touches[0]; // Only consider the first touch for simplicity
//         const updatedTouches = touches.map((t) =>
//             t.id === touch.identifier ? { ...t, x: touch.clientX, y: touch.clientY } : t
//         );
//         setTouches(updatedTouches);
//     };

//     const handleTouchEnd = (e) => {
//         const touch = e.changedTouches[0];
//         const remainingTouches = touches.filter((t) => t.id !== touch.identifier);
//         setTouches(remainingTouches);
//     };

//     const long = useLongPress(
//         () => {
//             alert("ok")
//         },
//         {
//             onStart: (event) => console.log("Press started"),
//             onFinish: (event) => console.log("Press Finished"),
//             onCancel: (event) => console.log("Press cancelled"),
//             threshold: 1000,
//         }
//     )

//     const handleClick2 = () => {
//         alert("click")
//     }

//     return (
//         <div className="circle-container">
//             <button {...long} onClick={handleClick2}></button>
//             {touches.map((touch) => (
//                 <div key={touch.id} className="touch-effect" style={{ left: touch.x, top: touch.y }}>
//                     +5
//                 </div>
//             ))}
//             <div className="circle" onClick={handleClick} {...long} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
//                 {count}
//             </div>
//         </div>
//     );
// };

// export default CircleComponent;


import React, { useState, useRef } from 'react';

const CircleCompnent = () => {
    const [longPress, setLongPress] = useState(false);
    const touchTimeoutRef = useRef(null);
    const touchStartRef = useRef(0);

    const handleTouchStart = (event) => {
        event.preventDefault(); // Prevent default browser behavior
        event.stopPropagation(); // Stop event propagation

        touchStartRef.current = Date.now(); // Record the time when touch starts

        touchTimeoutRef.current = setTimeout(() => {
            setLongPress(true);
            alert('Long press detected');
        }, 1000); // Adjust the duration for long press as needed (in milliseconds)
    };

    const handleTouchEnd = (event) => {
        clearTimeout(touchTimeoutRef.current);

        const touchDuration = Date.now() - touchStartRef.current;

        if (touchDuration < 500 && !longPress) {
            // If touch duration is less than 500ms and long press didn't occur
            alert('Click detected');
        }

        setLongPress(false);

        // Re-enable text selection after touch end
        document.documentElement.style.userSelect = '';

        // Clear any existing text selection
        if (window.getSelection) {
            if (window.getSelection().empty) {  // Chrome
                window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {  // Firefox
                window.getSelection().removeAllRanges();
            }
        } else if (document.selection) {  // IE
            document.selection.empty();
        }

        event.preventDefault(); // Prevent default browser behavior
        event.stopPropagation(); // Stop event propagation
    };

    const handleTouchMove = () => {
        clearTimeout(touchTimeoutRef.current);
        setLongPress(false);

        // Re-enable text selection if touch moves away
        document.documentElement.style.userSelect = '';
    };

    return (
        <button
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
            style={{ userSelect: 'none ' }}
        >
            Press and hold for log long, tap for log click (Mobile)
        </button>
    );
};

export default CircleCompnent;
