import axios from 'axios'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

export default function ProtectedRoute(props) {
    const navigate = useNavigate()
    useEffect(() => {
        // const interval = setInterval(() => {

        // }, 10000);
        // return clearInterval(interval)
        isTimeFalse()
        console.log("object")
    },)


    const retunrCondtion = () => {
        if (window.location.hostname != 'localhost') {
            if ((window.Telegram.WebApp.platform == 'ios' || window.Telegram.WebApp.platform == 'android')) {
                return true
            } else {
                // return false
                return true
            }
        } else {
            return true
        }
    }

    const isTimeFalse = () => {

        axios.get('https://api.timezonedb.com/v2.1/get-time-zone?key=1V3OSQAQ2QDQ&format=json&by=zone&zone=Asia/Kolkata')
            .then((res) => {
                console.log(res);

                const { formatted } = res.data;
                const realtime = new Date(formatted);

                // Ensure the date parsing is consistent
                if (isNaN(realtime.getTime())) {
                    console.error('Invalid date format:', formatted);
                    return;
                }

                const realtimeInFormat = moment(realtime).format('DD-MM-YYYY');
                const systeDateInFormat = moment().format("DD-MM-YYYY");

                console.log(realtime, realtimeInFormat, systeDateInFormat);

                if (realtimeInFormat !== systeDateInFormat) {
                    navigate('/false-time');
                }
            })
            .catch(error => console.error('Error fetching the real-time data:', error));
    };

    return (
        <>
            {retunrCondtion() ? props.children : <Navigate to={'/join-tg'} />}
        </>
    )
}
