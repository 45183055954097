import React from 'react'
import 'ldrs/dotPulse'
import { dotPulse } from 'ldrs'


export default function BtnLoader() {
    dotPulse.register()
    return (


        // Default values shown
        <l-dot-pulse
            size="43"
            speed="1.3"
            color="black"
        ></l-dot-pulse>
    )
}
