import React from 'react'
import { FaTelegramPlane, FaYoutube, } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { ring } from 'ldrs'

ring.register()
export default function Splash() {
    return (
        <>
            <div className="splash">
                <div className="splash-center d-flex flex-column align-items-center">
                    <l-ring
                        size="50"
                        stroke="5"
                        bg-opacity="0"
                        speed="2"
                        color="#E69D00"
                    ></l-ring>
                    <h5 className="text-white mt-1">Loading</h5>
                </div>
                <div className="bottom-content w-100">
                    <h3 className='text-center'>Hamster Kombat Coin</h3>
                    <h6 className="text-center">is launched</h6>
                    <h1 className='text-center'>on TON</h1>
                    <h6 className="text-center">Stay tuned</h6>
                    <h6 className="text-white text-center">More info in official channels</h6>
                    <div className='icons'>
                        <div>
                            <FaTelegramPlane className='telegram-icon' />
                        </div>
                        <div>
                            <FaYoutube className='telegram-icon mx-3' />
                        </div>
                        <div>
                            <FaXTwitter className='telegram-icon' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
