const hamsterYT = [
    {
        title: "Bitcoin's Biggest falls",
        desc: "From All-Time Highs to Biggest Lows: Bitcoin's worst crashes",
        price: "100000",
        url: "https://www.youtube.com/watch?v=m7AJFs7SWB0"
    },
    {
        title: "BTC might still go to $100k this year?is AI going nuclear now?",
        price: "100000",
        url: "https://www.youtube.com/watch?v=4LbTE1R6dqE"
    },
    {
        title: "Watch a new parterner video",
        price: "100000",
        url: "https://www.youtube.com/watch?v=_z8uqvQNIsU"
    },
]

const dailyRw = [
    {
        day: 1,
        price: 500
    },
    {
        day: 2,
        price: 1000
    },
    {
        day: 3,
        price: 2500
    },
    {
        day: 4,
        price: 5000
    },
    {
        day: 5,
        price: 15000
    },
    {
        day: 6,
        price: 25000
    },
    {
        day: 7,
        price: 100000
    },
    {
        day: 8,
        price: 500000
    },
    {
        day: 9,
        price: 1000000
    },
    {
        day: 10,
        price: 5000000
    },
]

const TasksList = [
    {
        img: "telegram",
        title: "Join our TG channel",
        price: 5000,
        bigBtn: false,
        btnContent: "Join",
        url: "https://t.me/hamster_kombat_game_coin"
    },
    {
        img: "twitter",
        title: "Follow our X account",
        price: 5000,
        bigBtn: false,
        btnContent: "Join",
        url: "https://x.com/HKombat_coin?t=PM4qOLqo8Rd1kbENmXYMNg&s=09"
    },
    {
        img: "mainCh",
        title: "Choose your exchange",
        price: 5000,
        bigBtn: false,
        btnContent: "Choose",
        choose: true,
    },
    {
        img: "invite",
        title: "invite 3 friends",
        price: 25000,
        bigBtn: true,
        btnContent: "Check",
    },
]

export { hamsterYT, dailyRw, TasksList }