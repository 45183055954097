import React, { useContext } from 'react'
import BinanceLogo from '../Images/binance-logo.png'
import bybit from '../Images/bybit-logo.png'
import BingX from '../Images/bingX.png'
import htx from '../Images/htx-removebg-preview.png'
import kucoin from '../Images/Kucoin.png'
import okx from '../Images/okx-logo.png'
import mexc from '../Images/mexc-logo.png'
import crypto from '../Images/crypto-logo.png'
import gate from '../Images/gate.png'
import bitget from '../Images/bitget.png'
import { PiCoinsFill, PiUsersThreeFill } from 'react-icons/pi'
import hamsterCoin from '../Images/hamster-coin.png'
import { useNavigate } from 'react-router-dom'
import mine from '../Images/pickaxe.png'
import { context } from '../Context/UserContext'
import mainCh from '../Images/main-character.png'

export default function BottomNavigation({ page }) {

    const { userData, langJson } = useContext(context)
    const img = userData?.ExchangeName == 'Binance' ? BinanceLogo : userData?.ExchangeName == 'OKX' ? okx : userData?.ExchangeName == 'Crypto.com' ? crypto : userData?.ExchangeName == 'Bybit' ? bybit : userData?.ExchangeName == 'BingX' ? BingX : userData?.ExchangeName == 'HTX' ? htx : userData?.ExchangeName == 'Kucoin' ? kucoin : userData?.ExchangeName == 'Gate.io' ? gate : userData?.ExchangeName == 'MEXC' ? mexc : userData?.ExchangeName == 'Bitget' ? bitget : mainCh

    const navigate = useNavigate()

    return (
        <div className="bottom-navigation">
            <div className={page == 0 ? "bottom-link bottom-activate" : "bottom-link"} onClick={() => navigate('/')}>
                <img src={img} style={{ width: 30 }} alt="exchange" />
                <span className='text-min'>{langJson.Exchange}</span>
            </div>
            <div className={page == 1 ? "bottom-link bottom-activate" : "bottom-link"} onClick={() => navigate('/mine')}>
                <img src={mine} style={{ width: 24 }} className={page == 1 ? 'pickaxe' : "pickaxe-activate"} alt="" />
                <span className='text-min'>{langJson.mine}</span>
            </div>
            <div className={page == 2 ? "bottom-link bottom-activate" : "bottom-link"}>
                <PiUsersThreeFill style={{ fontSize: 24 }} onClick={() => navigate('/friends')} />
                <span className='text-min'>{langJson.friends}</span>
            </div>
            <div className={page == 3 ? "bottom-link bottom-activate" : "bottom-link"} onClick={() => navigate('/earn')}>
                <PiCoinsFill style={{ fontSize: 24 }} />
                <span className='text-min'>{langJson.earn}</span>
            </div>
            <div className={page == 4 ? "bottom-link bottom-activate" : "bottom-link"} onClick={() => navigate('/airdrop')}>
                <img src={hamsterCoin} style={{ width: "30px" }} alt="exchange" />
                <span className='text-min'>{langJson.airdrop}</span>
            </div>
        </div>
    )
}
