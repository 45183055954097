import React from 'react'
import tgQR from '../Images/tg-qr.png'

export default function JoinTg() {

    const handleImgClick = () => {
        window.location.href = 'https://t.me/Hamster_kombat_67_bot/HamsterKombat'
    }
    return (
        <>
            <div className="joinTg">
                <h3>Play on your mobile</h3>
                <img src={tgQR} width={200} onClick={handleImgClick} style={{ borderRadius: 20 }} alt="" />
                <h4 className='mt-3'>@hamster_kombat_67_bot</h4>
                <div className="mt-3">{window.Telegram.WebApp.platform} {window.Telegram.WebApp.version}</div>
            </div>
        </>
    )
}
