import React, { useContext, useEffect, useState } from 'react'
import usdt from '../Images/USDT.png'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { IoArrowDownCircleOutline, IoPricetags } from 'react-icons/io5'
import { makeid, walletRighter } from '../Helper/helper'
import toast from 'react-hot-toast'
import firebaseApp from '../Firebase/firebase'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import Loader from '../Components/Loader'
import { SendTransactionRequest, useTonAddress, useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { toNano, Address, beginCell, address } from '@ton/ton';
// import usdt from '../Images/USDT.png'
import millify from 'millify'
import { context } from '../Context/UserContext'
import axios from 'axios'
import { encryptData } from '../encrypt'
import ton from '../Images/ton-3d.png'
import { driver } from 'driver.js'

export default function Deposit() {
    const [amount, setAmount] = useState(10)
    const [transactionarray, setTransactionArray] = useState([])
    const [showLoader, setShowLoader] = useState(true)
    const navigate = useNavigate()
    const faddress = useTonAddress();
    const [jettonWalletContracts, setJettonWalletContract] = useState(null);
    const fromaddress = faddress ? Address.parse(faddress) : "";
    const [tonConnectUI, setOptions] = useTonConnectUI()
    const { price, langJson } = useContext(context)
    const [jettonAmount, setJettonAmount] = useState(0)
    const [rateInUsd, setRateInUsd] = useState(price * amount)
    const [tonAmount, setTonAmount] = useState(0)

    useEffect(() => {
        if (window.Telegram.WebApp) {
            const tg = window.Telegram.WebApp;

            // Initialize the backButton
            tg.BackButton.show();
            tg.BackButton.onClick(() => {
                // Handle back button click
                navigate('/')
                tg.BackButton.hide();
            });

            // Cleanup on component unmount
            return () => {
                tg.BackButton.offClick();
                tg.BackButton.hide();

            };
        }
    }, [])

    const driverObj = driver({
        showProgress: true,
        steps: [
            {
                element: "#dipInput",
                popover: {
                    title: langJson.dipt1,
                    description: langJson.dip1,
                }
            },
            {
                element: "#dipBtn",
                popover: {
                    title: langJson.dipt2,
                    description: langJson.dip2,
                }
            },
        ]
    })

    useEffect(() => {
        if (!localStorage.getItem('dipGuide')) {
            driverObj.drive()
            localStorage.setItem('dipGuide', 'true')
        }
        getTranscation()
    }, [])
    const fetchJettonWallets = async () => {
        if (faddress) {
            const response = await fetch(`https://toncenter.com/api/v3/jetton/wallets?owner_address=${faddress}&jetton_address=EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs&limit=10&offset=0`);
            const data = await response.json();
            console.log(data);
            if (data.jetton_wallets?.length > 0) {
                setJettonWalletContract(data.jetton_wallets[0]?.address);
                console.log(data)
                let jetton = Number(data.jetton_wallets[0].balance) / (10 ** 9)
                setJettonAmount(jetton)

            }
        }
    };

    const fetchWallets = async () => {
        if (faddress) {
            const response = await fetch(`https://toncenter.com/api/v2/getWalletInformation?address=${faddress}`);
            const data = await response.json();
            console.log(data);
            let jetton = Number(data.result.balance) / (10 ** 9)
            setTonAmount(jetton)
        }

    };

    useEffect(() => {
        fetchWallets()
        fetchJettonWallets();
    }, [faddress]);

    const CheckDepositeAmt = () => {
        if (rateInUsd >= 10) {
            AddTransaction()
        } else {
            toast.error('Minimum deposit amount is 10', {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                    marginTop: "10px"
                },
            })
        }
    }

    const AddTransaction = () => {
        setShowLoader(true)
        let inDollar = amount * price

        const transaction = {
            id: makeid(10),
            date: Date.now(),
            walletaddress: faddress,
            amount: Number(rateInUsd),
            jettomAmount: Number(amount),
            type: 'deposit',
            userid: localStorage.getItem('userid')
        }

        axios.post(`${process.env.REACT_APP_API_URL}` + '/package/add-transaction', { data: encryptData(transaction) })
            .then((res) => {
                if (res.status == 200) {
                    setShowLoader(false)
                    getTranscation()
                    // AddCompletePackage()
                }
            })

        // db.collection("transaction").add(transaction)
        //     .then((docRef) => {
        //         console.log("Document written with ID: ", transaction);
        //         // getData()
        //         AddCompletePackage()
        //     })
        //     .catch((error) => {
        //         console.error("Error adding document: ", error);
        //     });
    }
    const [isAbleToTransaction, setIsAbleToTransaction] = useState(true)
    const destinationAddress = Address.parse('UQBfUBEt9PcIuyVJgEWJfpF5HdnB3VHm1kZXhfSGHD5_9E5X');

    // const sendTokens = () => {
    //     // CheckDepositeAmt()

    //     if (faddress) {
    //         if (isAbleToTransaction) {
    //             // if (amount * price >= 10 && amount <= jettonAmount && tonAmount >= 0.40) {

    //             try {
    //                 let address = Address.parseRaw(jettonWalletContracts);
    //                 console.log('Decode', address);
    //                 const body = beginCell()
    //                     .storeUint(0xf8a7ea5, 32)
    //                     .storeUint(1, 64)
    //                     .storeCoins(toNano(amount))
    //                     .storeAddress(destinationAddress)
    //                     .storeAddress(fromaddress)
    //                     .storeBit(false)
    //                     .storeCoins(toNano(0.001))
    //                     .storeBit(false) // forward_payload in this slice, not separate cell
    //                     .endCell();
    //                 const myTransaction: SendTransactionRequest = {
    //                     validUntil: Math.floor(Date.now() / 1000) + 360,
    //                     messages: [
    //                         {
    //                             address: address.toString(), // Convert Address to string
    //                             amount: toNano(0.07).toString(), // for commission fees, excess will be returned
    //                             payload: body.toBoc().toString("base64") // payload with jetton transfer and comment body
    //                         }
    //                     ]
    //                 };
    //                 tonConnectUI.sendTransaction(myTransaction)
    //                     .then(tx => {
    //                         console.log('Transaction successful:', tx);
    //                         setIsAbleToTransaction(false)
    //                         CheckDepositeAmt()
    //                     })
    //                     .catch(err => {
    //                         toast.error("Transaction failed")
    //                         console.error('Transaction rejected or failed:', err);
    //                     });
    //             } catch (err) {
    //                 toast.error("Transaction failed")
    //                 console.error('An error occurred:', err);
    //             }
    //             // } else {
    //             //     toast.error('Enter Valid Amount')
    //             // }
    //         }
    //     } else {
    //         tonConnectUI.openModal()
    //     }

    // }

    const sendTokens = () => {
        // CheckDepositeAmt()
        let obj = {
            id: makeid(10),
            date: Date.now(),
            walletaddress: faddress,
            amount: Number(rateInUsd),
            jettomAmount: Number(amount),
            type: 'deposit',
            userid: localStorage.getItem('userid'),
            pending: true,
            memo: makeid(10)
        }
        if (faddress) {
            if (isAbleToTransaction) {
                if (rateInUsd >= 10) {
                    setShowLoader(true)
                    axios.post(process.env.REACT_APP_API_URL + '/task/add-pending-transaction', { data: encryptData(obj) })
                        .then((res) => {
                            if (res.status == 200) {
                                setShowLoader(false)
                                try {
                                    const body = beginCell()
                                        .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
                                        .storeStringTail(obj.memo) // write our text comment
                                        .endCell();
                                    const myTransaction = {
                                        validUntil: Math.floor(Date.now() / 1000) + 360,
                                        messages: [
                                            {
                                                // address: "0QBFuEFpHeaDOkbp5LzkNv6Zv3xEgGhLDvIRF3mKLhZMPDoZ", // Convert Address to string
                                                address: "UQDEj-5PAlOXduD4u_ZkUTipDb7z4NyxVb4ymwUPnPRKQVKC", // Convert Address to string
                                                amount: toNano(amount).toString(), // for commission fees, excess will be returned
                                                payload: body.toBoc().toString("base64")
                                            }
                                        ]
                                    };
                                    tonConnectUI.sendTransaction(myTransaction)
                                        .then(tx => {
                                            console.log('Transaction successful:', tx);
                                            setIsAbleToTransaction(false)
                                            getTranscation()
                                            // CheckDepositeAmt()
                                        })
                                        .catch(err => {
                                            toast.error("Transaction failed")
                                            console.error('Transaction rejected or failed:', err);
                                        });
                                } catch (err) {
                                    toast.error("Transaction failed")
                                    console.error('An error occurred:', err);
                                }
                            }
                        })
                    // } else {
                    //     toast.error('Enter Valid Amount')
                    // }
                }
            }
        } else {
            tonConnectUI.openModal()
        }

    }


    const AddCompletePackage = () => {
        const packages = {
            id: makeid(10),
            date: Date.now(),
            investAmount: Number(rateInUsd),
            isPackageCompleted: false,
            totalGet: 0,
            totalReward: Number(rateInUsd) * 3,
            userid: localStorage.getItem('userid'),
            Earning: []
        }

        axios.post(`${process.env.REACT_APP_API_URL}` + '/package/add-package', { data: encryptData(packages) })
            .then((res) => {
                if (res.status == 200) {
                    PlusDepositToUser()
                }
            })
    }

    const PlusDepositToUser = () => {
        let obj = {
            id: localStorage.getItem('docRef'),
            amount: Number(rateInUsd)
        }
        axios.post(`${process.env.REACT_APP_API_URL}` + '/package/add-deposit', { data: encryptData(obj) })
            .then((res) => {
                if (res.status == 200) {
                    getTranscation()
                    toast.success('Amount Added Successfully', {
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                            marginTop: "10px"
                        },
                    })
                    setTimeout(() => {
                        setIsAbleToTransaction(true)
                        fetchJettonWallets()
                        fetchWallets()
                    }, 30000);
                }
            })
    }

    const getTranscation = () => {
        axios.post(`${process.env.REACT_APP_API_URL}` + '/package/get-transaction', { id: localStorage.getItem('userid') })
            .then((res) => {
                if (res.status == 200) {
                    setTransactionArray(res.data)
                    setShowLoader(false)
                }
            })
    }

    const setRateAndAmount = (amt) => {
        setAmount(amt)
        setRateInUsd(amt * price)
    }

    const handleChange = (e) => {
        setAmount(e.target.value)
        setRateInUsd(Number(e.target.value) * price)
    }

    return (
        <>
            {showLoader ? <Loader /> : ""}
            <div className="bg-black d-flex justify-content-center">
                <div className='w-100 bg-black text-white h-screen font-bold d-flex flex-column max-w-xl'>
                    <div className="px-2 z-10 w-100">
                        <p className='mt-3'>{langJson.depositWith} @hamsterKombat</p>
                        <p className="dull-text text-sm line-height-3">{langJson.youCanDeposit}</p>
                        <div className="d-flex justify-content-between mt-3 flex-wrap">
                            <button className="option-add-btn" onClick={() => setRateAndAmount(50)}>50</button>
                            <button className="option-add-btn" onClick={() => setRateAndAmount(100)}>100</button>
                            <button className="option-add-btn" onClick={() => setRateAndAmount(250)}>250</button>
                            <button className="option-add-btn" onClick={() => setRateAndAmount(500)}>500</button>
                            <button className="option-add-btn" onClick={() => setRateAndAmount(1000)}>1000</button>
                            <button className="option-add-btn" onClick={() => setRateAndAmount(2000)}>2000</button>
                            <button className="option-add-btn" onClick={() => setRateAndAmount(4000)}>4000</button>
                            <button className="option-add-btn" onClick={() => setRateAndAmount(8000)}>8000</button>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p className="dull-text text-sm mt-3">{langJson.airt4}</p>
                            <p className="text-sm mt-3">{Number(rateInUsd).toFixed(2)} USD</p>
                        </div>
                        <div className="deposit-input-div position-relative" id='dipInput'>
                            <img src={ton} className='position-absolute top-10' width={24} height={24} alt="" />
                            <input type="number" inputMode='numeric' className='deposit-input' value={amount} onChange={handleChange} />
                        </div>
                        <button id='dipBtn' className='w-100 deposit-btn text-white text-center position-relative mt-3' onClick={sendTokens}><IoArrowDownCircleOutline style={{ width: 24, height: 24 }} /> {langJson.deposit}</button>
                        <p className='mt-3'>{langJson.depositHistory}</p>
                        <div className="deposit-history-div mt-2">

                            {transactionarray.map((item) => (
                                <div className="deposit-history-item rounded">
                                    <div className="left-histroy-icon">
                                        {item.pending ?
                                            <div class="spinner-border" role="status">
                                                <span class="sr-only"></span>
                                            </div>
                                            : <IoArrowDownCircleOutline style={{ width: 30, height: 30, color: "red" }} />}
                                    </div>
                                    <div className="history-desc" style={{ width: "30%" }}>
                                        {/* <p className="text-sm">1Gx9FCknxSsLfFDzFdn75Xgqx95sDp38ir</p> */}
                                        <p className='dull-text text-sm'>{moment(item.date).format('DD/MM/YYYY')}</p>
                                    </div>
                                    <div className="history-amount d-flex align-items-center" style={{ width: "20%" }}>
                                        <img src={usdt} width={20} height={20} className='object-fit-cover' alt="" /> <p style={{ fontSize: 18 }}> {millify(Number(item.amount).toFixed(2))}</p>
                                    </div>
                                    <div className="history-amount d-flex align-items-center" style={{ width: "30%" }}>
                                        <img src={ton} width={20} height={20} className='object-fit-cover' alt="" /> <p style={{ fontSize: 18 }}> {millify(Number(item?.jettomAmount).toFixed(2))}</p>
                                    </div>
                                </div>
                            ))}

                            {/* <div className="deposit-history-item">
                            <div className="left-histroy-icon">
                                <IoArrowDownCircleOutline style={{ width: 30, height: 30, color: "green" }} />
                            </div>
                            <div className="history-desc">
                                <p className="text-sm">{transactionarray[0].walletaddress}</p>
                                <p className='dull-text text-sm'>{transactionarray[0].date}</p>
                            </div>
                            <div className="history-amount">
                                <p>{transactionarray.investAmount}</p>
                            </div>
                        </div>
                        <div className="deposit-history-item">
                            <div className="left-histroy-icon">
                                <IoArrowDownCircleOutline style={{ width: 30, height: 30, color: "green" }} />
                            </div>
                            <div className="history-desc">
                                <p className="text-sm">1Gx9FCknxSsLfFDzFdn75Xgqx95sDp38ir</p>
                                <p className='dull-text text-sm'>26/07/2024</p>
                            </div>
                            <div className="history-amount">
                                <p>10$</p>
                            </div>
                        </div>
                        <div className="deposit-history-item">
                            <div className="left-histroy-icon">
                                <IoArrowDownCircleOutline style={{ width: 30, height: 30, color: "green" }} />
                            </div>
                            <div className="history-desc">
                                <p className="text-sm">1Gx9FCknxSsLfFDzFdn75Xgqx95sDp38ir</p>
                                <p className='dull-text text-sm'>26/07/2024</p>
                            </div>
                            <div className="history-amount">
                                <p>10$</p>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}