import React, { useEffect } from 'react'
import loadingImg from '../Images/loadingImg.png'
import { useNavigate, useParams } from 'react-router-dom'
import firebaseApp from '../Firebase/firebase'
import { makeid } from '../Helper/helper'
export default function Invite() {
    const { id } = useParams()
    const navigate = useNavigate()
    useEffect(() => {
        if (localStorage.getItem('userid')) {
            navigate('/')
        } else {
            addRefralUser()
        }

    }, [])

    const addPointsAndFriendToInviter = (item) => {
        let obj = {
            id: makeid(10),
            friendId: item.id,
        }
        const db = firebaseApp.firestore();
        db.collection('Users').where("id", "==", id).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                var updateCollection = db.collection("Users").doc(doc.ref.id);

                return updateCollection.update({
                    mypoints: Number(doc.data().mypoints) + 25000,
                    myFriends: [...doc.data().myFriends, obj]
                })
                    .then(() => {
                        navigate('/')
                    })
                    .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                    });
            })
        }).catch(err => {
            console.error(err)
        });
    }

    const addRefralUser = () => {
        let obj = {
            username: makeid(12),
            id: makeid(10),
            telegramId: "",
            profitPerHour: 0,
            lvl: 0,
            mypoints: 0,
            fullEnergy: 6,
            boost: [
                {
                    multitap: 2,
                    point: 2
                },
                {
                    energy: 2,
                    power: 1500
                }
            ],
            myMiners: [],//{mindeId:"",level:"",id:"",hourlyIncome:0}
            myFriends: [],//{firendId:"",id:""}
            tonId: "",
            earn: {
                video: [],//{id,isWatch,collectedId}
                Daily: []
            },
            TgTask: false,
            Xtask: false,
            ExchangeTask: false,
            inviteFriend: false,
            waitingList: [],//{id,mineId,time,completionTime}
            achivedCombo: [],
            refId: "",
        }
        const db = firebaseApp.firestore();
        db.collection("Users").add(obj)
            .then((docRef) => {
                console.log("Document written with ID: ", docRef);
                localStorage.setItem("docRef", docRef.id)
                // resolve(docRef.id);
                localStorage.setItem('userid', obj.id)
                localStorage.setItem('userRefId', id)
                addPointsAndFriendToInviter(obj)
            })
            .catch((error) => {
                console.error("Please check form again ", error);
                // reject(error);
            });
    }


    return (
        <img src={loadingImg} style={{ width: "100%", height: "100vh", objectFit: "cover" }} alt="" />
    )
}
