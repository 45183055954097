import React, { useContext, useEffect, useState } from 'react'
import BottomNavigation from '../Components/BottomNavigation'
import dollarCoin from '../Images/dollar-coin.png'
import { dailyRw, hamsterYT, TasksList } from '../Helper/Data'
import ytLogo from '../Images/yt-logo.png'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { RxCross1 } from 'react-icons/rx'
import dailyTask from '../Images/daily-task.png'
import millify from 'millify'
import tgLogo from '../Images/tg-logo.png'
import XLogo from '../Images/tw-logo.png'
import mainCh from '../Images/hamster-exchange.png'
import inviteLogo from '../Images/invite-logo.png'
import firebaseApp from '../Firebase/firebase'
import { context } from '../Context/UserContext'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { makeid } from '../Helper/helper'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import BtnLoader from '../Components/BtnLoader'
import CoinAnimation from '../Components/CoinAnimation'
import Loader from '../Components/Loader'
import axios from 'axios'
import { encryptData } from '../encrypt'
import { driver } from 'driver.js'

export default function Earn() {

    const [showModal, setShowModal] = useState(false)
    const [currentTask, setCurrentTask] = useState({})
    const [taskModal, setTaskModal] = useState(false)
    const [listModal, setListModal] = useState(false)
    const [currentList, setCurrentList] = useState({})
    const [data, setData] = useState([])
    const [btnLoader, setBtnLoader] = useState(false)
    const [coinAnimation, setcoinAnimation] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
    const [watchBtn, setWatchBtn] = useState(false)
    const { userData, setUserData, getUserData, langJson } = useContext(context)
    const navigate = useNavigate()

    useEffect(() => {
        getYTtask()
        if (window.Telegram.WebApp) {
            const tg = window.Telegram.WebApp;

            // Initialize the backButton
            tg.BackButton.show();
            tg.BackButton.onClick(() => {
                // Handle back button click
                navigate('/')
                tg.BackButton.hide();
            });

            // Cleanup on component unmount
            return () => {
                tg.BackButton.offClick();
                tg.BackButton.hide();
            };
        }
    }, [])

    const driverObj = driver({
        showProgress: true,
        steps: [
            {
                element: "#youtubeTask",
                popover: {
                    title: langJson.earnt1,
                    description: langJson.earn1,
                }
            },
            {
                element: "#dailyEarningTask",
                popover: {
                    title: langJson.earnt2,
                    description: langJson.earn2,
                }
            },
            {
                element: "#simpleTask",
                popover: {
                    title: langJson.earnt3,
                    description: langJson.earn3,
                }
            },
        ]
    })


    const showCoinAnimation = () => {
        if (JSON.parse(localStorage.getItem('coinAnimation'))) {
            setcoinAnimation(true)
        }
        setTimeout(() => {
            setcoinAnimation(false)
        }, 900);
    }

    useEffect(() => {
        if (!localStorage.getItem('earnGuide')) {
            driverObj.drive()
            localStorage.setItem('earnGuide', 'true')
        }
        IsDayMissed()
    }, [userData])


    const IsDayMissed = () => {
        if (Object.keys(userData).length > 0) {
            const userDaily = userData?.earn?.Daily
            let today = new Date()
            today.setHours(0, 0, 0, 0)
            let index = userDaily?.length - 1
            if ((userDaily[index]?.date) < (Number(today.getTime()) - (1000 * 60 * 60 * 24)) || returnIfCompleted(userDaily)) {
                let userEarn = userData?.earn
                userEarn.Daily = []
                addEarnToDatabase(userEarn)
            }
        }
    }

    const returnIfCompleted = (userDaily) => {
        if (userDaily.length > 0) {
            let index = userDaily?.length - 1
            let lastDate = userDaily[index]?.date
            console.log(userDaily, lastDate, userDaily.length >= 10 && moment(lastDate).format('DD-MM-YYYY') == moment().format("DD-MM-YYYY"))
            if (userDaily.length >= 10 && moment(lastDate).format('DD-MM-YYYY') == moment().format("DD-MM-YYYY")) {
                return false
            } else if (userDaily.length < 10) {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    }

    const addEarnToDatabase = (userEarn) => {
        axios.post(`${process.env.REACT_APP_API_URL}` + '/task/add-earn', { id: localStorage.getItem('docRef'), userEarn: userEarn })
        // const db = firebaseApp.firestore();
        // db.collection('Users').where("id", "==", localStorage.getItem('userid')).get().then((querySnapshot) => {
        //     querySnapshot.forEach((doc) => {
        //         var updateCollection = db.collection("Users").doc(doc.ref.id);

        //         return updateCollection.update({
        //             earn: userEarn
        //         })
        //             .then(() => {
        //                 getUserData()
        //             })
        //             .catch((error) => {
        //                 // The document probably doesn't exist.
        //                 console.error("Error updating document: ", error);
        //             });
        //     })
        // }).catch(err => {
        //     console.error(err)
        // });
    }


    const handleModalShow = () => {
        setShowModal(true)
    }

    const getYTtask = () => {
        axios.get(`${process.env.REACT_APP_API_URL}` + '/yt/get-YTTask')
            .then((res) => {
                if (res.status == 200) {
                    setData(res.data)
                    setShowLoader(false)
                }
            })
        // let x = []
        // const db = firebaseApp.firestore();
        // db.collection('ytTask').get().then((querySnapshot) => {
        //     querySnapshot.forEach((doc) => {
        //         x.push(doc.data())
        //         setData(x)
        //         setShowLoader(false)
        //     })
        //     if (querySnapshot.size == 0) {
        //         setShowLoader(false)
        //     }
        // }).catch(err => {
        //     console.error(err)
        // });
    }

    const handleModalClose = () => {
        setShowModal(false)

    }

    const showTaskModal = () => {
        setTaskModal(true)

    }
    const closeTaskModal = () => {
        if (!btnLoader) {
            setTaskModal(false)
        }
    }



    const showListModal = () => {
        setListModal(true)

    };
    const closeListModal = () => {
        setListModal(false)
    }

    const handleTask = (item) => {
        setCurrentTask(item)
        handleModalShow()
    }

    const handleList = (item) => {
        setCurrentList(item)
        showListModal()
    }

    const CompleteTask = () => {
        setShowLoader(true)
        if (currentList?.url) {
            if (currentList?.img == 'telegram' && !userData?.TgTask) {
                axios.post(`${process.env.REACT_APP_API_URL}` + '/task/tg-task', { id: localStorage.getItem('docRef') })
                    .then((res) => {
                        if (res.status == 200) {
                            getUserData()
                            setShowLoader(false)
                            if (window.Telegram.WebApp) {
                                const tg = window.Telegram.WebApp
                                tg.ready()
                                tg.openTelegramLink(currentList?.url);
                            }
                        }
                    })
            } else if (currentList?.img == 'twitter' && !userData?.Xtask) {
                axios.post(`${process.env.REACT_APP_API_URL}` + '/task/x-task', { id: localStorage.getItem('docRef') })
                    .then((res) => {
                        if (res.status == 200) {
                            getUserData()
                            setShowLoader(false)
                            if (window.Telegram.WebApp) {
                                const tg = window.Telegram.WebApp
                                tg.ready()
                                tg.openLink(currentList?.url);
                            }
                        }
                    })
            } else {
                setShowLoader(false)
                const tg = window.Telegram.WebApp
                tg.ready()
                if (currentList?.img == 'telegram') {
                    tg.openTelegramLink(currentList?.url);
                } else if (currentList?.img == 'twitter') {
                    tg.openLink(currentList?.url);
                }
            }
        } else if (currentList?.choose) {
            if (!userData?.ExchangeTask) {
                axios.post(`${process.env.REACT_APP_API_URL}` + '/task/Ex-task', { id: localStorage.getItem('docRef') })
                    .then((res) => {
                        if (res.status == 200) {
                            getUserData()
                            setShowLoader(false)
                            navigate('/exchange')
                        }
                    })
            } else {
                navigate('/exchange')
            }
        }
    }

    const ClaimInvite = () => {
        if (userData?.myFriends.length >= 3 && !userData?.inviteFriend) {
            setBtnLoader(true)
            axios.post(`${process.env.REACT_APP_API_URL}` + '/task/claim-invite', { id: localStorage.getItem('docRef') })
                .then((res) => {
                    if (res.status == 200) {
                        getUserData()
                        setBtnLoader(false)
                        closeListModal()
                        showCoinAnimation()
                    }
                })
        }
    }

    const notAvailabelInOBJ = () => {
        const userVideo = userData?.earn.video
        const obj = userVideo.find((obj) => obj.videoId == currentTask?.id)
        if (obj) {
            return false
        } else {
            return true
        }
    }

    const handleYoutubeTask = () => {
        if (notAvailabelInOBJ()) {
            setWatchBtn(true)
            const userVideo = userData?.earn
            let obj = {
                id: makeid(10),
                isWatched: true,
                isCollected: false,
                time: Date.now() + (60 * 60 * 1000),
                videoId: currentTask?.id
            }
            userVideo.video.push(obj)
            addYTTaskToDB(userVideo, userData.mypoints, true)
        } else {
            if (window.Telegram.WebApp) {
                const tg = window.Telegram.WebApp
                tg.ready()
                tg.openLink(currentTask?.url);
            }
        }
    }

    const addYTTaskToDB = (userEarn, points, IsWatch) => {
        let obj = {
            id: localStorage.getItem('docRef'),
            userEarn: userEarn,
            points: points
        }
        axios.post(`${process.env.REACT_APP_API_URL}` + '/task/add-user-earn-point', { data: encryptData(obj) })
            .then((res) => {
                if (res.status == 200) {
                    getUserData()
                    if (IsWatch) {
                        // document.getElementById('ytLink').click()
                        if (window.Telegram.WebApp) {
                            const tg = window.Telegram.WebApp
                            tg.ready()
                            tg.openLink(currentTask?.url);
                        }
                    }
                    handleModalClose()
                    setWatchBtn(false)
                }
            })
    }

    const checkIsWatched = () => {
        const userVideo = userData?.earn.video
        const obj = userVideo.find((obj) => obj.videoId == currentTask?.id)
        if (obj && (obj.time) >= Date.now()) {
            return true

        } else {
            return false
        }
    }

    const checkIsCompleted = () => {
        const userVideo = userData?.earn.video
        const obj = userVideo.find((obj) => obj.videoId == currentTask?.id)
        if (obj && (obj?.time) <= Date.now()) {
            return true
        } else {
            return false
        }
    }

    const checkIsCollected = (item) => {
        let userEarn = userData?.earn
        let obj = userEarn?.video.find((obj) => obj.videoId == item?.id)
        if (obj?.isCollected) {
            return true
        } else {
            return false
        }
    }

    const handleCollectYT = () => {
        if (checkIsCompleted() && !checkIsCollected(currentTask)) {
            const userPoints = Number(userData.mypoints) + currentTask?.price
            let userEarn = userData?.earn
            let obj = userEarn.video.find((obj) => obj.videoId == currentTask?.id)
            obj.isCollected = true
            addYTTaskToDB(userEarn, userPoints)
        }
    }

    // const isTodayClaimed = () => {
    //     if (Object.keys(userData).length > 0) {
    //         let dailyEarn = userData?.earn?.Daily
    //         if (dailyEarn?.length == 0) {
    //             return false
    //         } else {
    //             if (moment(dailyEarn[dailyEarn?.length - 1]?.date) == moment(Date.now()).format("DD-MM-YYYY")) {
    //                 return true
    //             } else {

    //                 return false
    //             }
    //         }
    //     }
    // }

    const isTodayClaimed = () => {
        if (userData?.dailyTask == true) {
            return true
        } else {
            return false
        }
        // Check if userData exists and is an object
        // if (typeof userData !== 'object' || userData === null) {
        //     return false;
        // }

        // // Safely access dailyEarn from userData
        // let dailyEarn = userData?.earn?.Daily;

        // // If dailyEarn is not an array or is empty, return false
        // if (!Array.isArray(dailyEarn) || dailyEarn.length === 0) {
        //     return false;
        // }

        // // Get the last entry's date and convert it to a Date object
        // let lastEntryDate = dailyEarn[dailyEarn.length - 1]?.date;
        // if (!lastEntryDate) {
        //     return false;
        // }

        // // Convert last entry date to a Date object
        // let timestamp = moment(lastEntryDate);
        // const inputDate = new Date(timestamp);
        // const now = new Date();

        // // Calculate today's start and end based on 5:30 AM boundary
        // const startOfToday = new Date(now);
        // const endOfToday = new Date(now);

        // startOfToday.setHours(5, 30, 0, 0); // 5:30 AM
        // endOfToday.setHours(29, 30, 0, 0); // 5:30 AM of the next day

        // // If today is earlier than 5:30 AM, adjust start and end dates
        // if (now < startOfToday) {
        //     startOfToday.setDate(startOfToday.getDate() - 1);
        //     endOfToday.setDate(endOfToday.getDate() - 1);
        // }

        // // Check if the input timestamp is within today's date range
        // return inputDate >= startOfToday && inputDate < endOfToday;
    }



    const claimDailyReward = () => {
        if (!btnLoader) {
            if (!isTodayClaimed()) {
                setBtnLoader(true)
            }
            if (userData?.earn.Daily.length > 0) {
                if (!isTodayClaimed() && !btnLoader) {
                    console.log("call")
                    let userDaily = userData?.earn
                    let obj = {
                        id: makeid(10),
                        day: dailyRw[userData?.earn.Daily.length].day,
                        reward: dailyRw[userData?.earn.Daily.length].price,
                        date: Date.now()
                    }
                    userDaily.Daily.push(obj)
                    userDaily.Daily = sortByKey(userDaily.Daily, 'day')
                    addPointsAndDaily(userDaily, obj.reward)
                }
            } else {
                let userDaily = userData?.earn
                let obj = {
                    id: makeid(10),
                    day: dailyRw[0].day,
                    reward: dailyRw[0].price,
                    date: Date.now()
                }
                userDaily.Daily.push(obj)
                userDaily.Daily = sortByKey(userDaily.Daily, 'day')
                addPointsAndDaily(userDaily, obj.reward)
            }
        }
    }

    const addPointsAndDaily = async (userEarn, points) => {
        let obj = {
            id: localStorage.getItem('docRef'),
            userEarn: userEarn,
            points: points
        }
        axios.post(`${process.env.REACT_APP_API_URL}` + '/task/add-point-daily', { data: encryptData(obj) })
            .then((res) => {
                if (res.status == 200) {
                    if (userData?.myDeposit > 0) {
                        addEarningToPackage()
                    } else {
                        getUserData();
                        closeTaskModal();
                        setBtnLoader(false);
                        showCoinAnimation();
                    }
                }
            })

        // const db = firebaseApp.firestore();
        // const userId = localStorage.getItem('userid');

        // // Fetch the document reference outside the transaction
        // const userQuery = db.collection('Users').where("id", "==", userId).limit(1);
        // const userDocs = await userQuery.get();

        // if (userDocs.empty) {
        //     console.error('User not found');
        //     return;
        // }

        // // Get the document reference
        // const userDoc = userDocs.docs[0];
        // const userDocRef = userDoc.ref;

        // try {
        //     await db.runTransaction(async (transaction) => {
        //         // Read the current document data
        //         const userData = await transaction.get(userDocRef);

        //         // Calculate new points
        //         const newPoints = Number(userData.data().mypoints) + Number(points);

        //         // Update the document with the new data
        //         transaction.update(userDocRef, {
        //             earn: userEarn,
        //             mypoints: newPoints,
        //             dailyTask: true
        //         });


        //         // Conditional logic based on the updated data
        //         if (userData.data().myDeposit > 0) {
        //             console.log("Deposit is made");
        //             PlusDollarInUser(); // Call external function outside of the transaction
        //         } else {
        //             // Wait for the transaction to complete before executing these functions
        //             await getUserData();
        //             closeTaskModal();
        //             setBtnLoader(false);
        //             showCoinAnimation();
        //         }
        //         let user = userData
        //         user.earn = userEarn
        //         user.mypoints = newPoints
        //         user.dailyTask = true
        //         setUserData(user)
        //     });
        // } catch (error) {
        //     console.error("Error updating document: ", error);
        // }
    };

    const addEarningToPackage = () => {
        let obj = {
            id: localStorage.getItem('docRef'),
            type: "Daily"
        }
        axios.post(`${process.env.REACT_APP_API_URL}` + '/task/add-package-earning', { data: encryptData(obj) })
            .then((res) => {
                if (res.status == 200) {
                    getUserData();
                    closeTaskModal();
                    setBtnLoader(false);
                    showCoinAnimation();
                }
            })
    }

    // const PlusDollarInUser = () => {
    //     console.log("Plus Dollar In User Function")
    //     const db = firebaseApp.firestore();
    //     db.collection('Users').where("id", "==", localStorage.getItem('userid')).get().then((querySnapshot) => {
    //         querySnapshot.forEach((doc) => {
    //             let investAmount = Number(doc.data().myDeposit)
    //             let dollarToPlus = investAmount / 100

    //             var updateCollection = db.collection("Users").doc(doc.ref.id);
    //             return updateCollection.update({
    //                 myBalance: Number(doc.data().myBalance) + Number(dollarToPlus)
    //             })
    //                 .then(() => {
    //                     console.log("then of plus dollar in user and dollar TO Plus", dollarToPlus)
    //                     if (doc.data().refId !== "") {
    //                         console.log("inside if userRefId")
    //                         UpdateDollarInReferal(investAmount, doc.data().refId)
    //                     } else {
    //                         console.log("Inside Else userRefId")
    //                         UpdatePackageAndTotalGet("from Else of plus dollar in user")
    //                     }
    //                 })
    //                 .catch((error) => {
    //                     // The document probably doesn't exist.
    //                     console.error("Error updating document: ", error);
    //                 });
    //         })
    //     }).catch(err => {
    //         console.error(err)
    //     });
    // }

    // const UpdateDollarInReferal = (investAmount, id) => {
    //     console.log("Update Dollar In Referal fucnction invest Amount :", investAmount)
    //     const dollarToPlus = (Number(investAmount) * 0.33) / 100
    //     const db = firebaseApp.firestore();
    //     db.collection('Users').where("id", "==", id).get().then((querySnapshot) => {
    //         if (querySnapshot.empty) {
    //             UpdatePackageAndTotalGet("Ref Not Found")
    //         } else {
    //             querySnapshot.forEach((doc) => {
    //                 var updateCollection = db.collection("Users").doc(doc.ref.id);
    //                 let friends = doc.data().myFriends
    //                 let currentUser = friends?.find((obj) => obj.friendId == localStorage.getItem('userid'))
    //                 let index = friends?.find((obj) => obj.friendId == localStorage.getItem('userid'))
    //                 currentUser.friendReward = Number(currentUser.friendReward ? currentUser.friendReward : 0) + Number(dollarToPlus)
    //                 friends[index] = currentUser
    //                 if (doc.data().myDeposit > 0) {
    //                     return updateCollection.update({
    //                         myBalance: Number(doc.data().myBalance) + Number(dollarToPlus),
    //                         myFriends: friends
    //                     })
    //                         .then(() => {
    //                             console.log("inside then of update dollar in referal", dollarToPlus)
    //                             UpdateRefPackage(dollarToPlus, id)
    //                         })
    //                         .catch((error) => {
    //                             // The document probably doesn't exist.
    //                             console.error("Error updating document: ", error);
    //                         });
    //                 } else {
    //                     UpdatePackageAndTotalGet("else")
    //                 }
    //             })
    //         }
    //     }).catch(err => {
    //         console.error(err)
    //     });
    // }

    // const UpdateRefPackage = (dollarToPlus, id) => {
    //     let dollar = dollarToPlus
    //     console.log("inside updateRefPackage", dollarToPlus);
    //     const db = firebaseApp.firestore();
    //     const userRefId = localStorage.getItem('userRefId');
    //     const userId = localStorage.getItem('userid');

    //     db.collection('Package')
    //         .where("userid", "==", id)
    //         .where('isPackageCompleted', '==', false)
    //         .orderBy('investAmount')
    //         .get()
    //         .then((querySnapshot) => {
    //             let isCompleted = false;
    //             let arr = [];
    //             let docRefId = [];

    //             querySnapshot.forEach((doc) => {
    //                 arr.push(doc.data());
    //                 docRefId.push(doc.ref.id);
    //             });

    //             console.log("after querysnapshot", arr, docRefId);

    //             for (let i = 0; i < arr.length; i++) {
    //                 if (!isCompleted) {
    //                     let PendingAmount = Number(arr[i].totalReward) - Number(arr[i].totalGet);
    //                     console.log("Pending Amount", PendingAmount);

    //                     let earning = arr[i].Earning;
    //                     let earningObj = {
    //                         id: makeid(10),
    //                         date: Date.now(),
    //                         amount: PendingAmount > dollar ? dollar : PendingAmount,
    //                         earningType: "Daily Refer",
    //                         earningFrom: userId
    //                     };

    //                     earning.push(earningObj);
    //                     var updateCollection = db.collection("Package").doc(docRefId[i]);

    //                     if (PendingAmount > dollar) {
    //                         console.log("Pending amount is less than dollar to plus");
    //                         isCompleted = true;

    //                         updateCollection.update({
    //                             totalGet: Number(arr[i].totalGet) + dollar,
    //                             Earning: earning
    //                         })
    //                             .then(() => {
    //                                 console.log("total get and earning is updated", earning, Number(arr[i].totalGet) + dollar);
    //                                 UpdatePackageAndTotalGet("From referal function");
    //                             })
    //                             .catch((error) => {
    //                                 console.error("Error updating document: ", error);
    //                             });
    //                     } else {
    //                         console.log("inside else");
    //                         if (i == arr.length - 1) {
    //                             let otherBalance = Number(dollarToPlus) - Number(PendingAmount)
    //                             updateCollection.update({
    //                                 totalGet: arr[i].totalReward,
    //                                 Earning: earning,
    //                                 isPackageCompleted: true
    //                             }).then(() => {
    //                                 MinusBalanceFromUserAccount(otherBalance, id, arr[i].investAmount)
    //                             })
    //                                 .catch((error) => {
    //                                     console.error("Error updating document: ", error);
    //                                 });
    //                             console.log(otherBalance)
    //                         } else {
    //                             updateCollection.update({
    //                                 totalGet: arr[i].totalReward,
    //                                 Earning: earning,
    //                                 isPackageCompleted: true
    //                             })
    //                                 .then(() => {
    //                                     console.log("after complete small package");
    //                                     removeDepositFromUser(arr[i].investAmount, id);
    //                                     dollar = dollar - PendingAmount
    //                                 })
    //                                 .catch((error) => {
    //                                     console.error("Error updating document: ", error);
    //                                 });
    //                         }
    //                     }
    //                 } else {
    //                     return;  // Break the loop after the first update
    //                 }
    //             }
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //         });
    // }

    // const MinusBalanceFromUserAccount = (balance, id, investAmount) => {
    //     const db = firebaseApp.firestore();
    //     db.collection('Users').where("id", "==", id).get().then((querySnapshot) => {
    //         querySnapshot.forEach((doc) => {
    //             var updateCollection = db.collection("Users").doc(doc.ref.id);
    //             let friends = doc.data().myFriends
    //             let index = friends.findIndex((obj) => obj.friendId == localStorage.getItem('userid'))
    //             friends[index].friendReward = Number(friends[index].friendReward) - Number(balance)
    //             return updateCollection.update({
    //                 myBalance: Number(doc.data().myBalance) - Number(balance),
    //                 myFriends: friends
    //             })
    //                 .then(() => {
    //                     removeDepositFromUser(investAmount, id);

    //                     UpdatePackageAndTotalGet("From referal function");
    //                 })
    //                 .catch((error) => {
    //                     // The document probably doesn't exist.
    //                     console.error("Error updating document: ", error);
    //                 });
    //         })
    //     }).catch(err => {
    //         console.error(err)
    //     });
    // }



    // const UpdateRefPackage = (dollarToPlus) => {
    //     console.log("inside updateRefPackage", dollarToPlus)
    //     const db = firebaseApp.firestore();
    //     db.collection('Package').where("userid", "==", localStorage.getItem('userRefId')).where('isPackageCompleted', '==', false).orderBy('investAmount').get().then((querySnapshot) => {
    //         let isCompleted = false
    //         let arr = []
    //         let docRefId = []
    //         querySnapshot.forEach((doc) => {
    //             arr.push(doc.data())
    //             docRefId.push(doc.ref.id)
    //         })
    //         console.log("after querysnapshot", arr, docRefId)
    //         for (let i = 0; i < arr.length; i++) {
    //             if (!isCompleted) {
    //                 let PendingAmount = Number(arr[i].totalReward) - Number(arr[i].totalGet)
    //                 console.log("Pending Amount", PendingAmount)
    //                 if (PendingAmount > dollarToPlus) {
    //                     console.log("Pending amount is less than dollar to plus")
    //                     isCompleted = true
    //                     let earning = arr[i].Earning
    //                     let earningObj = {
    //                         id: makeid(10),
    //                         date: Date.now(),
    //                         amount: dollarToPlus,
    //                         earningType: "Refer",
    //                         earningFrom: localStorage.getItem('userid')
    //                     }
    //                     earning.push(earningObj)
    //                     var updateCollection = db.collection("Package").doc(docRefId[i]);
    //                     return updateCollection.update({
    //                         totalGet: Number(arr[i].totalGet) + dollarToPlus,
    //                         Earning: earning
    //                     })
    //                         .then(() => {
    //                             console.log("total get and earning is updated", earning, Number(arr[i].totalGet) + dollarToPlus)
    //                             UpdatePackageAndTotalGet("From referal function")
    //                         })
    //                         .catch((error) => {
    //                             // The document probably doesn't exist.
    //                             console.error("Error updating document: ", error);
    //                         });
    //                 } else {
    //                     console.log("inside else")
    //                     let earning = arr[i].Earning
    //                     let earningObj = {
    //                         id: makeid(10),
    //                         date: Date.now(),
    //                         amount: PendingAmount,
    //                         earningType: "Refer",
    //                         earningFrom: localStorage.getItem('userid')
    //                     }
    //                     earning.push(earningObj)
    //                     console.log(earning)
    //                     var updateCollection = db.collection("Package").doc(docRefId[i]);
    //                     return updateCollection.update({
    //                         totalGet: arr[i].totalReward,
    //                         Earning: earning,
    //                         isPackageCompleted: true
    //                     })
    //                         .then(() => {
    //                             console.log("after complete small package")
    //                             removeDepositFromUser(arr[i].investAmount, localStorage.getItem('userRefId'))
    //                         })
    //                         .catch((error) => {
    //                             // The document probably doesn't exist.
    //                             console.error("Error updating document: ", error);
    //                         });
    //                 }
    //             }
    //         }

    //     }).catch(err => {
    //         console.error(err)
    //     });
    // }

    // const removeDepositFromUser = (amount, id) => {
    //     console.log("remove deposit from user")
    //     const db = firebaseApp.firestore();
    //     db.collection('Users').where("id", "==", id).get().then((querySnapshot) => {
    //         querySnapshot.forEach((doc) => {
    //             var updateCollection = db.collection("Users").doc(doc.ref.id);

    //             return updateCollection.update({
    //                 myDeposit: Number(doc.data().myDeposit) - Number(amount)
    //             })
    //                 .then(() => {
    //                     console.log("Pending Deposit", Number(doc.data().myDeposit) - Number(amount))
    //                 })
    //                 .catch((error) => {
    //                     // The document probably doesn't exist.
    //                     console.error("Error updating document: ", error);
    //                 });
    //         })
    //     }).catch(err => {
    //         console.error(err)
    //     });
    // }

    // const UpdatePackageAndTotalGet = (log) => {
    //     console.log(log)
    //     let totalDollar = 0
    //     const db = firebaseApp.firestore();
    //     db.collection('Package').where("userid", "==", localStorage.getItem('userid')).where('isPackageCompleted', "==", false).get().then((querySnapshot) => {
    //         querySnapshot.forEach((doc) => {
    //             var updateCollection = db.collection("Package").doc(doc.ref.id);
    //             let PendingAmount = Number(doc.data().totalReward) - Number(doc.data().totalGet)
    //             let dollarToPlus = 0
    //             let investAmount = doc.data().investAmount
    //             dollarToPlus = investAmount / 100
    //             totalDollar = totalDollar + Number(dollarToPlus)
    //             let earning = doc.data().Earning
    //             console.log("earning,pendingAmount,dollarToPlus,investAmount", earning, PendingAmount, dollarToPlus, investAmount)
    //             if (PendingAmount > dollarToPlus) {
    //                 console.log("pending amount is greater then dollar to plus")
    //                 let obj = {
    //                     id: makeid(10),
    //                     date: Date.now(),
    //                     amount: dollarToPlus,
    //                     earningType: "Daily task",
    //                 }
    //                 earning.push(obj)
    //                 return updateCollection.update({
    //                     totalGet: Number(doc.data().totalGet) + Number(dollarToPlus),
    //                     Earning: earning
    //                 })
    //                     .then(() => {
    //                         console.log(earning, Number(doc.data().totalGet) + Number(dollarToPlus))
    //                         closeTaskModal()
    //                         getUserData()
    //                         setBtnLoader(false)
    //                         showCoinAnimation()
    //                     })
    //                     .catch((error) => {
    //                         // The document probably doesn't exist.
    //                         console.error("Error updating document: ", error);
    //                     });
    //             } else {
    //                 let obj = {
    //                     id: makeid(10),
    //                     date: Date.now(),
    //                     amount: Number(doc.data().totalReward),
    //                     earningType: "task",
    //                 }
    //                 earning.push(obj)
    //                 return updateCollection.update({
    //                     totalGet: Number(doc.data().totalReward),
    //                     Earning: earning,
    //                     isPackageCompleted: true
    //                 })
    //                     .then(() => {
    //                         console.log("small package is completed")
    //                         removeDepositFromUser(doc.data().investAmount, localStorage.getItem('userid'))
    //                         closeTaskModal()
    //                         getUserData()
    //                         setBtnLoader(false)
    //                         showCoinAnimation()
    //                     })
    //                     .catch((error) => {
    //                         // The document probably doesn't exist.
    //                         console.error("Error updating document: ", error);
    //                     });
    //             }
    //         })
    //     }).catch(err => {
    //         console.error(err)
    //     });
    // }

    // const UpdateInPackage = () => {
    //     let totalDollar = 0
    //     let size = 0
    //     const db = firebaseApp.firestore();
    //     db.collection('Package').where("userid", "==", localStorage.getItem('userid')).get().then((querySnapshot) => {
    //         querySnapshot.forEach((doc) => {
    //             var updateCollection = db.collection("Package").doc(doc.ref.id);
    //             let dollarToPlus = 0
    //             let investAmount = doc.data().investAmount
    //             dollarToPlus = investAmount / 100
    //             totalDollar = totalDollar + Number(dollarToPlus)
    //             let earning = doc.data().Earning
    //             size++
    //             let obj = {
    //                 id: makeid(10),
    //                 date: Date.now(),
    //                 amount: dollarToPlus,
    //                 earningType: "task",
    //             }
    //             earning.push(obj)
    //             return updateCollection.update({
    //                 // totalGet: Number(doc.data().totalGet) + Number(dollarToPlus),
    //                 totalGet: 0
    //                 // Earning: earning
    //             })
    //                 .then(() => {
    //                     console.log(querySnapshot.size, size)
    //                     // if (querySnapshot.size == size) {
    //                     //     PlusBalanceInUser(totalDollar, localStorage.getItem('userid'))
    //                     //     if (localStorage.getItem('userRefId')) {
    //                     //         addDollarToReferalAccount(investAmount)
    //                     //     } else {
    //                     //         PlusBalanceInUser(dollarToPlus, localStorage.getItem('userid'))
    //                     //         closeTaskModal()
    //                     //         setBtnLoader(false)
    //                     //         showCoinAnimation()
    //                     //     }
    //                     // }
    //                 })
    //                 .catch((error) => {
    //                     // The document probably doesn't exist.
    //                     console.error("Error updating document: ", error);
    //                 });
    //         })
    //     }).catch(err => {
    //         console.error(err)
    //     });
    // }

    // const PlusBalanceInUser = (dollar, id) => {
    //     const db = firebaseApp.firestore();
    //     db.collection('Users').where("id", "==", id).get().then((querySnapshot) => {
    //         querySnapshot.forEach((doc) => {
    //             var updateCollection = db.collection("Users").doc(doc.ref.id);

    //             return updateCollection.update({
    //                 myBalance: Number(doc.data().myBalance) + Number(dollar)
    //             })
    //                 .then(() => {
    //                 })
    //                 .catch((error) => {
    //                     // The document probably doesn't exist.
    //                     console.error("Error updating document: ", error);
    //                 });
    //         })
    //     }).catch(err => {
    //         console.error(err)
    //     });
    // }

    // const addDollarToReferalAccount = (investAmount) => {
    //     const db = firebaseApp.firestore();
    //     db.collection('Package').where("userid", "==", localStorage.getItem('userRefId')).get().then((querySnapshot) => {
    //         querySnapshot.forEach((doc) => {
    //             var updateCollection = db.collection("Package").doc(doc.ref.id);
    //             let dollarToPlus = 0
    //             dollarToPlus = (Number(investAmount) * 0.33) / 100
    //             let earning = doc.data().Earning
    //             let obj = {
    //                 id: makeid(10),
    //                 date: Date.now(),
    //                 amount: dollarToPlus,
    //                 earningType: "Referal",
    //                 refFrom: localStorage.getItem('userid')
    //             }
    //             earning.push(obj)
    //             return updateCollection.update({
    //                 totalGet: Number(doc.data().totalGet) + Number(dollarToPlus),
    //                 Earning: earning
    //             })
    //                 .then(() => {
    //                     closeTaskModal()
    //                     setBtnLoader(false)
    //                     showCoinAnimation()
    //                     PlusBalanceInUser(dollarToPlus, localStorage.getItem('userRefId'))
    //                 })
    //                 .catch((error) => {
    //                     // The document probably doesn't exist.
    //                     console.error("Error updating document: ", error);
    //                 });
    //         })
    //     }).catch(err => {
    //         console.error(err)
    //     });
    // }

    const checkIsClaimed = (item) => {
        const userDaily = userData?.earn.Daily
        if (userDaily[userDaily?.length - 1]?.day >= item.day) {
            return true
        } else {
            return false
        }
    }

    function sortByKey(array, key) {
        return array.sort((a, b) => {
            if (a[key] < b[key]) {
                return -1;
            } else if (a[key] > b[key]) {
                return 1;
            } else {
                return 0;
            }
        });
    }


    return (
        <>
            {showLoader ? <Loader /> : ""}
            {coinAnimation ? <CoinAnimation /> : ""}
            <div className="d-flex justify-content-center bg-black">
                <div className={'w-100 bg-black text-white font-bold d-flex h-screen flex-column max-w-xl pb-100'}>
                    <div className="Big-coin">
                        <div className="outer-earn-coin">
                            <div className="inner-earn-coin">
                                <img src={dollarCoin} className='earn-dollar-coin' alt="" />
                            </div>
                        </div>
                    </div>
                    <h3 className='text-center text-4xl'>{langJson.earnMoreCoin}</h3>
                    <div className="task-pannel mt-4 px-4" id='youtubeTask'>
                        <h6>{langJson.hamsterYoutube}</h6>
                        {data.map((item) => {
                            return (
                                <>
                                    <div className="yt-task mb-3 position-relative" onClick={() => handleTask(item)}>
                                        <div className="yt-logo">
                                            <img src={ytLogo} className="img-fluid" alt="" />
                                        </div>
                                        <div className="yt-task-desc">
                                            <p className="yt-title">{item.title}</p>
                                            <h6 className='mt-1 mb-0'><img src={dollarCoin} alt="dollar-coin" className='me-1' width={22} /> +{Number(item.price).toLocaleString()}</h6>
                                        </div>
                                        <div className="right-arrow">
                                            {checkIsCollected(item) ? <IoIosCheckmarkCircle style={{ fontSize: 30, color: "green" }} /> : <MdOutlineKeyboardArrowRight style={{ fontSize: 30, color: "#8d8d8d" }} />}
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                    <div className="task-pannel mt-4 px-4">
                        <h6>{langJson.dailyTasks}</h6>
                        <div className="daily-task-pannel" id='dailyEarningTask' onClick={showTaskModal}>
                            <img src={dailyTask} width={50} alt="" />
                            <div className="yt-task-desc h-100">
                                <p className="yt-title">{langJson.dailyTasks}</p>
                                <h6 className='mt-1 mb-0'><img src={dollarCoin} alt="dollar-coin" className='me-1' width={22} /> +{(66500).toLocaleString()}</h6>
                            </div>
                            <div className="right-arrow">
                                {userData?.dailyTask ? <IoIosCheckmarkCircle style={{ fontSize: 30, color: "green" }} /> : <MdOutlineKeyboardArrowRight style={{ fontSize: 30, color: "#8d8d8d" }} />}
                            </div>
                        </div>
                    </div>
                    <div className="task-pannel mt-4 px-4" id='simpleTask'>
                        <h6>{langJson.tasksList}</h6>
                        {TasksList.map((item) => {
                            return (
                                <>
                                    <div className="yt-task mb-3 position-relative" onClick={() => handleList(item)}>
                                        <div className="yt-logo">
                                            <img src={item.img == 'telegram' ? tgLogo : item.img == 'twitter' ? XLogo : item.img == 'mainCh' ? mainCh : inviteLogo} width={50} alt="" />
                                        </div>
                                        <div className="yt-task-desc">
                                            <p className="yt-title">{item.title}</p>
                                            <h6 className='mt-1 mb-0'><img src={dollarCoin} alt="dollar-coin" className='me-1' width={22} /> +{Number(item.price).toLocaleString()}</h6>
                                        </div>
                                        <div className="right-arrow">
                                            {(item.img == 'telegram' && userData?.TgTask) || (item.img == 'twitter' && userData?.Xtask) || (item.img == 'mainCh' && userData?.ExchangeTask) || (item.img == 'invite' && userData?.inviteFriend) ? <IoIosCheckmarkCircle style={{ fontSize: 30, color: "green" }} /> : <MdOutlineKeyboardArrowRight style={{ fontSize: 30, color: "#8d8d8d" }} />}
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
                <BottomNavigation page={3} />
            </div>
            {taskModal ? <div className="modal-overlay" onClick={closeTaskModal}></div> : null
            }
            {
                taskModal ?
                    <div className='task-modal'>
                        <div className="modal-border">
                            <div className="modal-inner-section">
                                <div className="d-flex align-items-center text-center justify-content-center w-100 text-white gap-3 h-100 flex-column position-relative">
                                    <div className="img-blue-shadow">
                                        <img src={dailyTask} alt="daily-task" width={80} />
                                    </div>
                                    <h1 className='text-4xl'>{langJson.dailyReward}</h1>
                                    <p className='text-sm w-60'>{langJson.accureCoinsForLogin}</p>
                                    <div className="row px-4 w-100">
                                        {dailyRw.map((item) => (
                                            <>
                                                <div className="col-3 mt-2">
                                                    <div className={checkIsClaimed(item) ? "bg-success rw-box" : "rw-box"}>
                                                        <h6 className="text-sm mb-2">{langJson.day} {item.day}</h6>
                                                        <img src={dollarCoin} width={22} alt="" />
                                                        <p className='text-sm mt-1'>{millify(item.price)}</p>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                    <button className={userData?.dailyTask || btnLoader ? "dull-check-btn font-weight-600" : "check-btn font-weight-600"} onClick={claimDailyReward}>{btnLoader ? <BtnLoader /> : isTodayClaimed() ? langJson.comeBackTomorrow : langJson.check}</button>
                                    <div className="cross-button" onClick={closeTaskModal}>
                                        <RxCross1 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {showModal ? <div className="modal-overlay" onClick={handleModalClose}></div> : null}
            {
                showModal ? <div className="task-modal">
                    <div className="modal-border">
                        <div className="modal-inner-section">
                            <div className="d-flex align-items-center justify-content-center w-100 text-white gap-3 h-100 flex-column position-relative">
                                <img src={ytLogo} width={100} alt="" />
                                <h1 className='text-center px-4'>{currentTask?.title}</h1>
                                <p className="text-sm w-60 text-center">
                                    {currentTask?.description}
                                </p>
                                <a className='task-btn text-decoration-none' onClick={handleYoutubeTask} >{watchBtn ? <BtnLoader /> : "Watch video"}</a>
                                <a className='task-btn text-decoration-none d-none' id='ytLink' href={currentTask?.url} >{watchBtn ? <BtnLoader /> : "Watch video"}</a>
                                {checkIsWatched() ?
                                    <p className="text-sm w-60 text-center">{langJson.wait1hour}</p>
                                    : ""}
                                <h3><img src={dollarCoin} alt="dollar-coin" width={30} /> +{Number(currentTask?.price).toLocaleString()}</h3>
                                <button className={checkIsCompleted() && !btnLoader ? "check-btn" : "dull-check-btn"} onClick={handleCollectYT}>{btnLoader ? <BtnLoader /> : langJson.check}</button>
                                <div className="cross-button" onClick={handleModalClose}>
                                    <RxCross1 />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null
            }
            {listModal ? <div className="modal-overlay" onClick={closeListModal}></div> : null}
            {
                listModal ?
                    <div className="task-modal">
                        <div className="modal-border">
                            <div className="modal-inner-section">
                                <div className="d-flex align-items-center justify-content-center w-100 text-white gap-3 h-100 flex-column position-relative">
                                    <img src={currentList?.img == 'telegram' ? tgLogo : currentList?.img == 'twitter' ? XLogo : currentList?.img == 'mainCh' ? mainCh : inviteLogo} width={100} alt="" />
                                    <h1 className='text-center px-4'>{currentList?.title}</h1>
                                    {currentList?.bigBtn == false ? <a className='task-btn text-decoration-none' onClick={CompleteTask}>{currentList?.btnContent}</a> : ""}
                                    <a href={currentList?.url} id='task' className="d-none"></a>
                                    <h3><img src={dollarCoin} alt="dollar-coin" width={30} /> +{currentList?.price}</h3>
                                    {!userData?.inviteFriend && currentList?.bigBtn ? <button className={btnLoader ? "dull-check-btn" : "check-btn"} onClick={ClaimInvite}>{btnLoader ? <BtnLoader /> : currentList?.btnContent}</button> : ""}
                                    <div className="cross-button" onClick={closeListModal}>
                                        <RxCross1 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    )
}
