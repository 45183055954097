import React, { useContext, useEffect, useState } from 'react'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import BottomNavigation from '../Components/BottomNavigation'
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { RxCross1 } from 'react-icons/rx'
import boostprofit from '../Images/boost-profit.png'
import dollarCoin from '../Images/dollar-coin.png'
import firebaseApp from '../Firebase/firebase'
import { context } from '../Context/UserContext'
import Loader from '../Components/Loader'
import axios from 'axios'




export default function Settings() {

    const navigate = useNavigate()
    const [DeleteModal, setDeleteModal] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [vibration, setVibration] = useState(localStorage.getItem('vibration') ? JSON.parse(localStorage.getItem("vibration")) : true)
    const [coinAnimation, setCoinAnimation] = useState(localStorage.getItem('coinAnimation') ? JSON.parse(localStorage.getItem('coinAnimation')) : true)
    const { userName, langJson } = useContext(context)

    useEffect(() => {
        if (window.Telegram.WebApp) {
            const tg = window.Telegram.WebApp;

            // Initialize the backButton
            tg.BackButton.show();
            tg.BackButton.onClick(() => {
                // Handle back button click
                navigate('/')
                tg.BackButton.hide();
            });

            // Cleanup on component unmount
            return () => {
                tg.BackButton.offClick();
                tg.BackButton.hide();
            };
        }
    }, [])


    const showDeleteModal = () => {
        setDeleteModal(true)
    }

    const closeDeleteModal = () => {
        setDeleteModal(false)
    }

    const DeleteAccount = () => {
        setShowLoader(true)
        axios.post(`${process.env.REACT_APP_API_URL}` + '/user/delete-user', { id: localStorage.getItem('userid') })
            .then((res) => {
                if (res.status == 200) {
                    localStorage.clear()
                    setShowLoader(false)
                    const tg = window.Telegram.WebApp;
                    tg.ready()
                    tg.close()
                }
            })
    }



    const handleVibrationChange = () => {
        setVibration(!vibration)
        localStorage.setItem('vibration', !vibration)
    }

    const handleCoinAnimationChange = () => {
        setCoinAnimation(!coinAnimation)
        localStorage.setItem('coinAnimation', !coinAnimation)
    }

    return (
        <>
            {showLoader ? <Loader /> : ""}
            <div className="bg-black d-flex justify-content-center">
                <div className="w-100 bg-black text-white h-screen font-bold">
                    <h1 className='text-center mt-4 airdrop-title'>{langJson.Settings}</h1>

                    {/* <div className='mx-3 mt-3 '>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div>
                                <p>Select Language</p>
                                <p className='sub-heading'>English</p>
                            </div>
                            <div className='ms-auto me-3'>
                                <MdOutlineKeyboardArrowRight className='setting-arrow' />
                            </div>
                        </div>
                    </div> */}
                    <div className='mx-3 mt-3' onClick={() => navigate('/exchange')}>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div>
                                <p>{langJson.chooseExchange}</p>
                                <p className='sub-heading'>hamster</p>
                            </div>
                            <div className='ms-auto me-3'>
                                <MdOutlineKeyboardArrowRight className='setting-arrow' />
                            </div>
                        </div>
                    </div>
                    <div className='mx-3 mt-1' onClick={() => navigate('/language')}>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div>
                                <p>{langJson.chooseLanguage}</p>
                                <p className='sub-heading'>{localStorage.getItem('lang')}</p>
                            </div>
                            <div className='ms-auto me-3'>
                                <MdOutlineKeyboardArrowRight className='setting-arrow' />
                            </div>
                        </div>
                    </div>
                    <div className='mx-3 mt-1' onClick={showDeleteModal}>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div>
                                <p>{langJson.deleteAcc}</p>
                            </div>
                            <div className='ms-auto me-3'>
                                <MdOutlineKeyboardArrowRight className='setting-arrow' />
                            </div>
                        </div>
                    </div>
                    <div className='mx-3 mt-3 '>
                        <div className='w-100 ps-3 text-white d-flex text-start'>
                            <div>
                                <h6>{langJson.HapticFeedback}</h6>
                            </div>
                            <div className='ms-auto me-2'>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="custom-switch"
                                    checked={vibration}
                                    onChange={handleVibrationChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mx-3 mt-2'>
                        <div className='w-100 ps-3 text-white d-flex text-start'>
                            <div>
                                <h6>{langJson.coinsAnimation}</h6>
                            </div>
                            <div className='ms-auto me-2'>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="custom-switch"
                                    checked={coinAnimation}
                                    onChange={handleCoinAnimationChange}
                                />
                            </div>
                        </div>
                    </div>

                </div>

                <BottomNavigation page={5} />

            </div >


            {DeleteModal ? <div className="modal-overlay" onClick={closeDeleteModal}></div> : null}
            {
                DeleteModal ?
                    <div className="task-modal">
                        <div className="modal-border">
                            <div className="modal-inner-section">
                                <div className="d-flex align-items-center justify-content-center w-100 text-white gap-3 h-100 flex-column position-relative">

                                    <h5 className='mx-2 mt-3 text-center delete-title'>{langJson.areYouSureDelete}</h5>
                                    <p className='mx-3 text-center delete-alert'> {langJson.allYourDataDelete} </p>


                                    <button className="delete-btn" onClick={DeleteAccount}>{langJson.deleteAcc}</button>
                                    <button className="cancel-btn" onClick={closeDeleteModal}>{langJson.cancel} </button>
                                    <div className="cross-button" onClick={closeDeleteModal}>
                                        <RxCross1 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    )
}
