import React, { useContext, useEffect } from 'react'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import binancelogo from '../Images/binance-logo.png'
import bybit from '../Images/bybit-logo.png'
import BingX from '../Images/bingX.png'
import htx from '../Images/htx-removebg-preview.png'
import kucoin from '../Images/Kucoin.png'
import okx from '../Images/okx-logo.png'
import mexc from '../Images/mexc-logo.png'
import crypto from '../Images/crypto-logo.png'
import gate from '../Images/gate.png'
import bitget from '../Images/bitget.png'
import firebaseApp from '../Firebase/firebase'
import { FaCheck } from 'react-icons/fa'
import { context } from '../Context/UserContext'
import { IoMdCheckmark } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'


export default function Language() {

    const { userData, setUserData, langJson, setLang } = useContext(context)
    const Navigate = useNavigate()
    useEffect(() => {
        if (window.Telegram.WebApp) {
            const tg = window.Telegram.WebApp;

            // Initialize the backButton
            tg.BackButton.show();
            tg.BackButton.onClick(() => {
                // Handle back button click
                Navigate('/')
                tg.BackButton.hide();
            });

            // Cleanup on component unmount
            return () => {
                tg.BackButton.offClick();
                tg.BackButton.hide();
            };
        }
    }, [])

    const setLanguage = (lan) => {
        setLang(lan)
        localStorage.setItem('lang', lan)
        Navigate('/')

    }

    return (
        <>
            <div className="bg-black d-flex justify-content-center">
                <div className="w-100 bg-black text-white h-screen font-bold">
                    <h1 className='text-center mt-4 airdrop-title'>{langJson.chooseLanguage}</h1>
                    <div className='mx-3 mt-3' onClick={() => setLanguage("en")}>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div className='ms-2'>
                                <p>English</p>
                            </div>
                            <div className='ms-auto me-3'>
                                {localStorage.getItem('lang') !== "en" ? <MdOutlineKeyboardArrowRight className='setting-arrow' /> : <IoMdCheckmark className='setting-arrow' />}
                            </div>
                        </div>
                    </div>
                    <div className='mx-3 mt-3' onClick={() => setLanguage("hi")}>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div className='ms-2'>
                                <p>हिन्दी</p>
                            </div>
                            <div className='ms-auto me-3'>
                                {localStorage.getItem('lang') !== "hi" ? <MdOutlineKeyboardArrowRight className='setting-arrow' /> : <IoMdCheckmark className='setting-arrow' />}
                            </div>
                        </div>
                    </div>
                    <div className='mx-3 mt-3' onClick={() => setLanguage("gu")}>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div className='ms-2'>
                                <p>ગુજરાતી</p>
                            </div>
                            <div className='ms-auto me-3'>
                                {localStorage.getItem('lang') !== "gu" ? <MdOutlineKeyboardArrowRight className='setting-arrow' /> : <IoMdCheckmark className='setting-arrow' />}
                            </div>
                        </div>
                    </div>
                    <div className='mx-3 mt-3' onClick={() => setLanguage("ru")}>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div className='ms-2'>
                                <p>русский</p>
                            </div>
                            <div className='ms-auto me-3'>
                                {localStorage.getItem('lang') !== "ru" ? <MdOutlineKeyboardArrowRight className='setting-arrow' /> : <IoMdCheckmark className='setting-arrow' />}
                            </div>
                        </div>
                    </div>
                    <div className='mx-3 mt-3' onClick={() => setLanguage("ur")}>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div className='ms-2'>
                                <p>اردو</p>
                            </div>
                            <div className='ms-auto me-3'>
                                {localStorage.getItem('lang') !== "ur" ? <MdOutlineKeyboardArrowRight className='setting-arrow' /> : <IoMdCheckmark className='setting-arrow' />}
                            </div>
                        </div>
                    </div>
                    <div className='mx-3 mt-3' onClick={() => setLanguage("ch")}>
                        <div className='w-100 setting ps-3 text-white d-flex text-start'>
                            <div className='ms-2'>
                                <p>中国人</p>
                            </div>
                            <div className='ms-auto me-3'>
                                {localStorage.getItem('lang') !== "ch" ? <MdOutlineKeyboardArrowRight className='setting-arrow' /> : <IoMdCheckmark className='setting-arrow' />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
