import React, { useContext, useEffect, useState } from 'react'
import BottomNavigation from '../Components/BottomNavigation'
import dollarCoin from '../Images/dollar-coin.png'
import { context } from '../Context/UserContext'
import energy from '../Images/energy.png'
import turbo from '../Images/Boost.png'
import power from '../Images/power.png'
import multitap from '../Images/multitap.png'
import millify from 'millify'
import multitapImg from '../Images/multitap.png'
import { useNavigate } from 'react-router-dom'
import { RxCross1 } from 'react-icons/rx'
import BtnLoader from '../Components/BtnLoader'
import firebaseApp from '../Firebase/firebase'
import { energyCapacity, upgradeLimit } from '../Helper/Units'
import moment from 'moment'
import axios from 'axios'
import { encryptData } from '../encrypt'

export default function Boost() {
    const { userData, setUserData, getUserData, langJson } = useContext(context)

    const [MultitapModal, setMultitapModal] = useState(false)
    const [refilModal, setRefilModal] = useState(false)
    const [powerModal, setPowerModal] = useState(false)
    const [btnLoader, setBtnLoader] = useState(false)
    const [index, setIndex] = useState(0)
    const [powerIndex, setPowerIndex] = useState(0)
    const navigate = useNavigate()

    const upgradeMultitap = [
        2000,
        4000,
        8000,
        16000,
        32000,
        64000,
        128000,
        256000,
        512000,
        1024000,
        2048000,
        4096000,
        8192000,
        16384000,
        32768000,
        65536000,
        131072000,
        262144000,
        524288000,
        1048576000,
        2097152000,
        4194304000,
        8388608000,
        16777216000,
        33554432000,
        67108864000,
        134217728000,
        268435456000,
        536870912000
    ]

    useEffect(() => {
        if (window.Telegram.WebApp) {
            const tg = window.Telegram.WebApp;

            // Initialize the backButton
            tg.BackButton.show();
            tg.BackButton.onClick(() => {
                // Handle back button click
                navigate('/')
                tg.BackButton.hide();
            });

            // Cleanup on component unmount
            return () => {
                tg.BackButton.offClick();
                tg.BackButton.hide();
            };
        }
    }, [])


    const showPowerModal = () => {
        if (upgradeLimit.length - 1 >= powerIndex) {
            setPowerModal(true)
        }
    }

    const showRefilModal = () => {
        if (localStorage.getItem('freeEnergyTime')) {
            if (Number(localStorage.getItem('freeEnergyTime')) + 60 * 60 * 1000 < Date.now()) {
                setRefilModal(true)
            }
        } else {
            setRefilModal(true)
        }
    }

    const closeRefilModal = () => {
        setRefilModal(false)
    }

    const closePowerModal = () => {
        setPowerModal(false)
    }

    const showMultitap = () => {
        if (upgradeMultitap.length - 1 >= index) {
            setMultitapModal(true)
        }
    }

    const closeMultitapModal = () => {
        setMultitapModal(false)
    }

    useEffect(() => {
        if (Object.keys(userData).length > 0) {
            setIndex(userData?.boost[0]?.multitap - 2)
            setPowerIndex(userData?.boost[1]?.energy - 1)
        }
    }, [userData])

    const UpdateMultiTap = () => {
        if (userData?.mypoints > upgradeMultitap[index]) {
            setBtnLoader(true)
            let multitap = userData?.boost
            multitap[0].point++
            multitap[0].multitap++
            let points = userData?.mypoints
            points = Number(points) - Number(upgradeLimit[index])
            let obj = {
                id: localStorage.getItem('docRef'),
                multitap: multitap,
                points: points
            }
            axios.post(`${process.env.REACT_APP_API_URL}` + '/user/update-boost', { data: encryptData(obj) })
                .then((res) => {
                    if (res.status == 200) {
                        getUserData()
                        navigate('/')
                    }
                })
        }
    }

    const UpdateEnergyLimit = () => {
        if (userData?.mypoints > upgradeLimit[userData?.boost[1].energy - 1]) {
            setBtnLoader(true)
            let multitap = userData?.boost
            multitap[1].energy++
            multitap[1].power = energyCapacity[userData?.boost[1].energy]
            let power = userData?.power
            power.capacity = energyCapacity[userData?.boost[1].energy]
            power.remaining = energyCapacity[userData?.boost[1].energy]
            let points = userData?.mypoints
            points = Number(points) - Number(upgradeLimit[powerIndex])
            let obj = {
                id: localStorage.getItem('docRef'),
                multitap: multitap,
                points: points,
                power: power
            }
            axios.post(`${process.env.REACT_APP_API_URL}` + '/user/update-energy', { data: encryptData(obj) })
                .then((res) => {
                    if (res.status == 200) {
                        getUserData()
                        navigate('/')
                    }
                })

        }
    }

    const UpdateFreeEnergy = () => {
        if (userData?.fullEnergy > 0 && (localStorage.getItem('freeEnergyTime') && Number(localStorage.getItem('freeEnergyTime')) + 60 * 60 * 1000) < Date.now()) {
            setBtnLoader(true)
            axios.post(`${process.env.REACT_APP_API_URL}` + '/user/use-full-energy', { id: localStorage.getItem('docRef') })
                .then((res) => {
                    if (res.status == 200) {
                        getUserData()
                        localStorage.setItem('freeEnergyTime', Date.now())
                        navigate('/')
                    }
                })

        }
    }


    return (
        <>
            <div className='bg-black d-flex justify-content-center'>
                <div className='w-100 bg-black text-white h-screen font-bold d-flex flex-column max-w-xl'>
                    <div className="px-4 z-10 w-100">
                        <p className="text-center dull-text mt-3 text-xl">{langJson.yourBalance}</p>
                        <div className="boost-balance d-flex justify-content-center gap-1 mt-3">
                            <img src={dollarCoin} style={{ width: 30, height: 30 }} alt="" />
                            <h3 className='mb-0'>{Number(userData.mypoints).toLocaleString()}</h3>
                        </div>
                        <div className="mt-5">
                            <h6 className='mb-3'>{langJson.freeDailyBooster}</h6>
                            <div className="boost-card mb-2" onClick={showRefilModal}>
                                <img src={energy} alt="" />
                                <div className='line-height-2'>
                                    <span>{langJson.fullEnergy}</span>
                                    <p className="dull-text text-sm">{userData?.fullEnergy} / 6 {langJson.available}</p>
                                </div>
                                {localStorage.getItem('freeEnergyTime') && (Number(localStorage.getItem('freeEnergyTime')) + 60 * 60 * 1000) > Date.now() ? <div className="time-left">
                                    <p className='text-sm dull-text'>{moment(Number(localStorage.getItem('freeEnergyTime')) + 60 * 60 * 1000).fromNow()}</p>
                                </div> : ""}
                            </div>
                            <div className="boost-card mb-2">
                                <img src={turbo} alt="" />
                                <div className='line-height-2'>
                                    <span>{langJson.Turbo}</span>
                                    <p className="text-sm">{langJson.commingSoon}</p>
                                </div>
                                <div className="boost-overlay"></div>
                            </div>
                        </div>
                        <div className="mt-5" >
                            <h6 className='mb-3'>{langJson.Boosters}</h6>
                            <div className="boost-card mb-2" onClick={showMultitap}>
                                <img src={multitap} alt="" />
                                <div>
                                    <span>{langJson.multiTap}</span>
                                    <p className="text-sm"><img src={dollarCoin} width={20} alt="" /> {upgradeMultitap.length - 1 >= index ? millify(upgradeMultitap[index]) : "Max level"} <span className="dull-text">{Object.keys(userData).length > 0 && userData?.boost[0]?.multitap} lvl</span></p>
                                </div>
                            </div>
                            <div className="boost-card mb-2" onClick={showPowerModal}>
                                <img src={power} alt="" />
                                <div>
                                    <span>{langJson.energyLimit}</span>
                                    <p className="text-sm"><img src={dollarCoin} width={20} alt="" /> {upgradeLimit.length - 1 >= powerIndex ? millify(upgradeLimit[powerIndex]) : "Max level"} <span className="dull-text">{Object.keys(userData).length > 0 && userData?.boost[1]?.energy} lvl</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BottomNavigation />
            </div>
            {MultitapModal ? <div className="modal-overlay" onClick={closeMultitapModal}></div> : null}
            {
                MultitapModal ?
                    <div className="task-modal">
                        <div className="modal-border">
                            <div className="modal-inner-section">
                                <div className="d-flex align-items-center justify-content-center w-100 text-white gap-3 h-100 flex-column position-relative">
                                    <img src={multitapImg} width={100} alt="" />
                                    <h1>{langJson.multiTap}</h1>
                                    <span className="text-sm text-center w-60">{langJson.increaseTheAmountOfCoins} </span>
                                    <p>+1 coin for tap for level {Object.keys(userData).length > 0 && userData?.boost[0]?.multitap}</p>
                                    <h3><img src={dollarCoin} width={25} alt="" /> {upgradeMultitap[index]} <span className="dull-text">• {Object.keys(userData).length > 0 && userData?.boost[0]?.multitap} lvl</span> </h3>
                                    <button className={btnLoader ? "dull-check-btn" : "check-btn"} onClick={UpdateMultiTap}>{btnLoader ? <BtnLoader /> : langJson.goAhead}</button>
                                    <div className="cross-button" onClick={closeMultitapModal}>
                                        <RxCross1 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {powerModal ? <div className="modal-overlay" onClick={closePowerModal}></div> : null}
            {
                powerModal ?
                    <div className="task-modal">
                        <div className="modal-border">
                            <div className="modal-inner-section">
                                <div className="d-flex align-items-center justify-content-center w-100 text-white gap-3 h-100 flex-column position-relative">
                                    <img src={power} width={100} alt="" />
                                    <h1>{langJson.energyLimit}</h1>
                                    <span className="text-sm text-center w-60">{langJson.increaseAmountOfEnergy}</span>
                                    <p>+500 energy points for level {Object.keys(userData).length > 0 && userData?.boost[0]?.multitap}</p>
                                    <h3><img src={dollarCoin} width={25} alt="" /> {upgradeLimit[powerIndex]} <span className="dull-text">• {Object.keys(userData).length > 0 && userData?.boost[1]?.energy} lvl</span> </h3>
                                    <button className={btnLoader ? "dull-check-btn" : "check-btn"} onClick={UpdateEnergyLimit}>{btnLoader ? <BtnLoader /> : langJson.goAhead}</button>
                                    <div className="cross-button" onClick={closePowerModal}>
                                        <RxCross1 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {refilModal ? <div className="modal-overlay" onClick={closeRefilModal}></div> : null}
            {
                refilModal ?
                    <div className="task-modal">
                        <div className="modal-border">
                            <div className="modal-inner-section">
                                <div className="d-flex align-items-center justify-content-center w-100 text-white gap-3 h-100 flex-column position-relative">
                                    <img src={energy} width={100} alt="" />
                                    <h1>{langJson.fullEnergy}</h1>
                                    <span className="text-sm text-center w-60">{langJson.rechargeYourEnergy} </span>
                                    <h3><img src={dollarCoin} width={25} alt="" /> {langJson.free} </h3>
                                    <button className={btnLoader ? "dull-check-btn" : "check-btn"} onClick={UpdateFreeEnergy}>{btnLoader ? <BtnLoader /> : langJson.goAhead}</button>
                                    <div className="cross-button" onClick={closeRefilModal}>
                                        <RxCross1 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                    : null
            }
        </>
    )
}
