import CryptoJS from 'crypto-js';

// Your 32-byte (256-bit) key
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

// Convert hex key to WordArray format expected by CryptoJS
const key = CryptoJS.enc.Hex.parse(SECRET_KEY);

// Encrypt data
export function encryptData(data) {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: CryptoJS.enc.Hex.parse('00000000000000000000000000000000') // Use a secure IV in production
    }).toString();
    return ciphertext;
}


let obj = {
    id: '66efc8c14e565ff2db44dbce',
    points: 30500,

}

console.log(encryptData(obj))